:root{
    --drawer-size: 550px;
}

.portal :global {
    width: var(--drawer-size);
    height: 100%;
    position: fixed;
    top: 0;
    right: 0;
    overflow-x: hidden;
    padding: 20px 0 20px 20px;
    background-color: white;
    box-shadow: -10px 0px 20px 0px rgba(0, 0, 0, 0.2);
    z-index: 10;
}

/* override nacre header */
.portal :global(.wz-header) {
    position: relative;
    margin-bottom: 0;
    box-shadow: 3px 2px 4px 0 rgba(0, 0, 0, 0.1);
    margin-left: 0!important;
}

.portal :global(.wz-header .wz-topheader .wz-titleheader) {
    margin-right: 40px;
}

.portal :global(.wz-header-block.wz-header-block__fixed) {
    height: 0!important;
}
/* override nacre header */

@media only screen and (max-width: 600px) {
    .portal :global {
        width: 100%;
    }
}

.portal :global .depth-1 {
    width: 500px;
}

.portal :global .depth-2 {
    width: 450px;
}

div.portal-in :global {
    animation: sidebar-slide 0.3s ease-in-out;
}

div.portal-out :global {
    /* Forwards ensures we stay at the "end state" */
    animation: sidebar-slide-out 0.3s ease-in-out forwards;
}

.close {
    position: absolute;
    right: 12px;
    top: 30px;
    z-index: 10;
}
