/* Attendee Form  */

.informations-container {
    display: flex;
    justify-content: space-between;
}

.informations-input {
    width: 45%;
}

.footer-button-container {
    display: flex;
    justify-content: left;
    margin-left: -20px;
    position: sticky;
    bottom: -20px;
    margin-bottom: -20px !important;
    padding: 15px 20px 15px 18px;
    box-shadow: 6px 0 6px 0 rgb(0 0 0 / 20%);
    background-color: white;
}

@media only screen and (max-width: 600px) {
    /* Footer button on mobile screen to disable on button and make the others full width */
    .footer-button-container {
        display: block;
    }

    .footer-button-group {
        display: flex !important;
        flex-direction: column;
    }

    .footer-button-group :global(.wz-framebutton.wz-button) {
        margin-right: 0 !important;
    }
    .add-and_continue-button {
        display: None !important;
    }

    .footer-button-group :global(.wz-button:not(:last-of-type)) {
        margin-bottom: 15px;
    }
}

.button-add-event {
    margin-bottom: 15px;
}
.title {
    font-size: 20px;
    margin-right: 12px;
    margin-bottom: 15px;
}

.toggle-block-container :global(.wz-toggle-container__title-container) {
    width: auto;
}

:global(.wz-field-block__left_inline) {
    justify-content: flex-start;
}
.cell-input {
    border-radius: 0 !important;
    border-color: grey !important;
}

.cell-email {
    width: 35%;
}

.table-container-block {
    overflow-x: auto;
}

.table-container-block::-webkit-scrollbar {
    height: 5px;
}

.table-container {
    width: 100%;
}

.table-cell-index {
    padding: 5px;
}

.attendee-counter {
    margin: 10px 0px 5px 0px;
}

.table-add-attendee {
    border-spacing: 0px;
    border-collapse: collapse;
}

.table-cell {
    padding: 0px;
    min-width: 175px;
}

/*drawer background*/
.drawer {
    background-color: #f5f5f5;
}

/* Attendee creation drawer's modal responsive break */
@media only screen and (max-width: 800px) {
    .drawer {
        width: 100% !important;
    }
}
