/**
 * @see https://www.xfive.co/blog/itcss-scalable-maintainable-css-architecture/
 * Styles from Nacre
 */
// Template
// Animate the menu
$leftMenuTransition: .7s !default;

$transitionColor: .25s;
$transitionBorder: .25s;
$transitionDefault: .25s;

$toggleContentTransition: .25s !default;
$toggleContentAttributesTransition: .5s !default;

/**
 * Main colors for each Back Office of Weezevent
 * These colors will be used to customize UIs and be available
 * for each BO independently
 */

// Ticket colors
$primaryTicketColor: #0032fa;
$lighterTicketColor: #146def;
$darkerTicketColor: #003cd3;
$mediumTicketColor: #0033b2;
$backgroundTicketColor: #e5eafe;
$alphaTicketColor: #D9E1F3;

// Access colors
$primaryAccessColor: #2dd2be;
$lighterAccessColor: #32d8bc;
$darkerAccessColor: #29b7a2;
$mediumAccessColor: #2ec6ac;
$backgroundAccessColor: #eafdf6;
$alphaAccessColor: #e4efe8;

// Pay colors
$primaryPayColor: #ffcc33;
$lighterPayColor: #ffd366;
$darkerPayColor: #cc9e29;
$mediumPayColor: #e5b12e;
$backgroundPayColor: #fef4e8;
$alphaPayColor: #f7f3eb;

// Target colors
$primaryTargetColor: #fa3e4e;
$lighterTargetColor: #f75772;
$darkerTargetColor: #cc3648;
$mediumTargetColor: #e53c51;
$backgroundTargetColor: #fbebeb;
$alphaTargetColor: #f7ebed;

/**
 * @see https://www.xfive.co/blog/itcss-scalable-maintainable-css-architecture/
 * Styles from Nacre
 */

// Generic
$white: #fff;
$black: #000;
$lightGrey: #f5f5f5;
$darkGrey: #aaa;
$borderGreyColor: #f0f5f9;

// Customs
$mediumGrey: #404041;
$darkerLightGrey: #ced0da;
$inputDisabledGrey: #878c9b;

// Template
// Colors
$backgroundColor: $lightGrey !default;
$fontMenuColor: $white !default;
$fontColor: #0f0f14 !default;

$primaryColor: #0032fa !default;
$darkerColor: #003cd3 !default;
$mediumColor: #0033b2 !default;
$lighterColor: #F5F7FF !default;
$alphaColor: #D9E1F3 !default;
$gradientColor: #5198ff !default;

$compatColors: (
	"primary": $primaryColor,
	"darker": $darkerColor,
	"medium": $mediumColor,
	"alpha": $alphaColor,
	"lighter": $lighterColor,
	"gradient": $gradientColor,
);
:root {
	@each $name, $color in $compatColors {
		--#{$name}-color: #{red($color), green($color), blue($color)};
	}
}

$ticketColors: (
	"primary": #0032fa,
	"darker": #003cd3,
	"medium": #0033b2,
	"alpha": #D9E1F3,
	"lighter": #F5F7FF,
	"gradient": #5198ff,
);
.weezticket {
	@each $name, $color in $ticketColors {
		--#{$name}-color: #{red($color), green($color), blue($color)};
	}
}

$accessColors: (
	"primary": #2dd2be,
	"darker": #29b7a2,
	"medium": #2ec6ac,
	"alpha": #e4efe8,
	"lighter": #F4FDFC,
	"gradient": #51FFD9,
);
.weezaccess {
	@each $name, $color in $accessColors {
		--#{$name}-color: #{red($color), green($color), blue($color)};
	}
}

$payColors: (
	"primary": #FFCC33,
	"darker": #E8B212,
	"medium": #FFE083,
	"alpha": #FFF8E4,
	"lighter": #FFFEF5,
	"gradient": #FFE551,
);
.weezpay {
	@each $name, $color in $payColors {
		--#{$name}-color: #{red($color), green($color), blue($color)};
	}
}

$targetColors: (
	"primary": #fa3e4e,
	"darker": #cc3648,
	"medium": #e53c51,
	"alpha": #f7ebed,
	"lighter": #FFF5F6,
	"gradient": #FF5151,
);
.weeztarget {
	@each $name, $color in $targetColors {
		--#{$name}-color: #{red($color), green($color), blue($color)};
	}
}

$greenStatusColor: #28CA8B;
$greenDarkerStatusColor: #1fa26f;
$orangeStatusColor: #F18F20;
$orangeDarkerStatusColor: #d37d1c;
$greyStatusColor: #c8cddc;
$redStatusColor: #eb1427;
$redDarkerStatusColor: #dd1224;

// From lighter to darker
$greyOneColor: #dce1f0;
$greyTwoColor: #c8cddc;
// The 2 fonts above are used the most
$greyThreeColor: #a5aab9;
$greyFourColor: #878c9b;
$greyFiveColor: #555a69;
$greySixColor: #1e2332;

// Additional grey
$textDescription: #878C9B;

// Grey for table-cell
$tableGrey: #F0F5F9;
$tableFontColor: $fontColor;

// RGBA
$blackAlpha02: rgba(0,0,0,.2);
$greyAlpha015: rgba(34,36,38,.15);

// Icon in the top right
$iconTopColor: $white !default;
$iconTopHoverColor: $white !default;
$iconTopBorderColor: rgba(15,15,20,0.3) !default;
$iconTopBorderHoverColor: #0f0f14 !default;
$iconActiveTopBackgroundColor: $primaryColor !default;
$iconActiveTopFontColor: $white !default;
$iconActiveHoverTopBackgroundColor: $primaryColor !default;
$iconActiveHoverTopFontColor: $white !default;

// Menu on the left panel
$backgroundMenuColor: $white !default;
$menuChevronColor: $darkGrey !default;

// Menu burger
$burgerColor: $white !default;
$burgerActiveColor: $white !default;

// Stats
$statisticsColor: $primaryColor !default;

// Buttons
$buttonDefaultColor: #ededed;
$buttonHoverColor: #d4d4d4;

// Inputs
$inputErrorColor: #f4080a !default;

// Status
$greenStatusTextColor: #39C433;
$greenStatusBackgroundColor: #CDF0CC;
$orangeStatusTextColor: #FAA43E;
$orangeStatusBackgroundColor: #FEE8CF;
$redStatusTextColor: #F0480A;
$redStatusBackgroundColor: #FCC1C2;
$purpleStatusTextColor: #9933FF;
$purpleStatusBackgroundColor: #E5CCFF;
$blueStatusTextColor: #298EAC;
$blueStatusBackgroundColor: #C9E3EA;
$greyStatusTextColor: #1E2332;
$greyStatusBackgroundColor: #C7C8CC;

//Session
$sessionDisabledColor: rgba(30, 35, 50, 0.15);


$disabledOpacity: .45;

/**
 * @see https://www.xfive.co/blog/itcss-scalable-maintainable-css-architecture/
 * Styles from Nacre
 */

// Sizes of the topMenu
$topMenuMini: 40px;
$topMenuMedium: 50px;
$topMenuMaxi: 60px;

// To positionate the logo when we're on differents sizes
// X
$logoPositionXMini: -78px;
$logoPositionXMedium: -78px;
$logoPositionXMaxi: -78px;
// Y
$logoPositionYMini: -35px;
$logoPositionYMedium: -30px;
$logoPositionYMaxi: -25px;

// Sizes of the leftmenu
$leftMenuMini: 150px;
$leftMenuMedium: 200px;
$leftMenuMaxi: 260px;
$leftMenuMaxiWithOptionalMenu: 310px;

// Padding for main wz-content
$paddingWZContent: 16px;
$paddingWZContentDesktop: 20px;

// Size for media queries
$widthMobile: 600px;
$widthTablet: 960px;
$widthDesktop: 1280px;

// Fonts sizes
$bigFontSize: 20px;
$largeFontSize: 16px;
$mediumFontSize: 14px;
$normalFontSize: 12px;
$standardFontSize: 11px;
$smallFontSize: 10px;
$miniFontSize: 8px;

// Borders width
$borderRadiusCommonWidth: 5px;
$borderRadiusSelectorsWidth: 5px;
$borderRadiusBurgerWidth: 5px;

// Inputs border-radius sizes
$borderRadiusButtonWidth: 5px;
$borderRadiusCheckboxes: 2px;
$borderRadiusInput: 5px;
$borderRadiusStatus: 2px;



@mixin transform-element($params...) {
  -webkit-transform: $params;
  -moz-transform: $params;
  -ms-transform: $params;
  -o-transform: $params;
  transform: $params;
}

@mixin transition-element($params...) {
  -webkit-transition: $params;
  -moz-transition: $params;
  -ms-transition: $params;
  -o-transition: $params;
  transition: $params;
}

@mixin transition-property($params...) {
  -webkit-transition-property: $params;
  -moz-transition-property: $params;
  -ms-transition-property: $params;
  -o-transition-property: $params;
  transition-property: $params;
}

@mixin transition-duration($params...) {
  -webkit-transition-duration: $params;
  -moz-transition-duration: $params;
  -ms-transition-duration: $params;
  -o-transition-duration: $params;
  transition-duration: $params;
}

@mixin transition-timing-function($params...) {
  -webkit-transition-timing-function: $params;
  -moz-transition-timing-function: $params;
  -o-transition-timing-function: $params;
  transition-timing-function: $params;
}

@mixin line-clamp($line...) {
	-webkit-line-clamp: $line;
	overflow: hidden;
	display: -webkit-box;
	-webkit-box-orient: vertical;
}

@mixin scrollbar($width: 3px) {
	&::-webkit-scrollbar {
		width: $width;
        height: 6px;
	}

	&::-webkit-scrollbar-thumb {
		background-color: $darkerLightGrey;
	}

	&::-webkit-scrollbar-thumb:hover {
		background-color: $inputDisabledGrey;
	}

	&::-webkit-scrollbar-track {
		background-color: $white;
	}
}


@mixin border-radius($params...) {
  border-radius: $params;
  -moz-border-radius: $params;
  -webkit-border-radius: $params;
}


// Add the appearance of the browser
@mixin appearance($params...) {
  appearance: $params;
  -webkit-appearance: $params;
  -moz-appearance: $params;
  -ms-appearance: $params;
}

// Be careful with these styles because all the
// browsers no allowed to hide arrows
@mixin hideNumberArrows() {
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    /* Chrome, Safari, Edge, Opera */
    -webkit-appearance: none;
    // firefox
    -moz-appearance: textfield;
    margin: 0;
  }
}

// File to gather mixin to manage colors

@mixin background-opacity($color, $opacity: 0.5) {
	background-color: rgba($color, $opacity);
}


// Manage the placeholder of input
@mixin placeholder {
  &::-webkit-input-placeholder {
    @content;
  }

  &::-moz-placeholder {
    @content;
  }

  &::-ms-input-placeholder {
    @content;
  }

  &::placeholder {
    @content;
  }
}

// For readOnly
@mixin readOnly {
  &:-webkit-read-only {
    @content;
  }

  &:-moz-read-only {
    @content;
  }

  &:read-only {
    @content;
  }
}


// Function to generate boxShadow
@mixin box-shadow($params...) {
  -moz-box-shadow: $params;
  -webkit-box-shadow: $params;
  -o-box-shadow: $params;
  box-shadow: $params;
}


@mixin box-sizing($params...) {
  -o-box-sizing: $params;
  -moz-box-sizing: $params;
  -webkit-box-sizing: $params;
  -ms-box-sizing: $params;
  box-sizing: $params;
}


// Can toggle a content
@mixin toggle-content() {
  &:not(.wz-togglecontent) {
    @include transition-element(all);
    height: 0;
    font-size: 0;
    margin: 0;
    opacity: 0;
    padding: 0;
  }

  &.wz-togglecontent {
    @include transition-element(
      font-size $toggleContentTransition,
      margin $toggleContentTransition,
      padding $toggleContentTransition,
      opacity $toggleContentAttributesTransition $toggleContentTransition
    );
  }
}


/**
 * @see https://www.xfive.co/blog/itcss-scalable-maintainable-css-architecture/
 */
button {
  border: inherit;
}



// Container of the input
.wz-ui-input {
	position: relative;
	display: inline-flex;
	width: 100%;

	// Disabled the input
	&__disabled {
		opacity: .5;

		// Avoid to "active" border color on hover status
		.wz-input {
			&.wz-input-text,
			&.wz-input-url,
			&.wz-input-tel,
			&.wz-input-checkbox,
			&.wz-input-color,
			&.wz-input-radio,
			&.wz-input-textarea,
			&.wz-input-number,
			&.wz-input-email,
			&.wz-input-password {
				&:hover {
					cursor: not-allowed;
					border-color: $darkerLightGrey;
				}
			}
		}
	}

	.wz-input:focus:required:invalid {
		&.wz-input-text,
		&.wz-input-url,
		&.wz-input-tel,
		&.wz-input-checkbox,
		&.wz-input-radio,
		&.wz-input-color,
		&.wz-input-textarea,
		&.wz-input-number,
		&.wz-input-email,
		&.wz-input-password {
			border-color: $inputErrorColor;

			// Colored input on hover
			&:hover {
				border-color: $inputErrorColor;
			}
		}
	}

	// Show errors with errors property or required
	&__errors {
		.wz-input {
			&.wz-input-text,
			&.wz-input-url,
			&.wz-input-tel,
			&.wz-input-checkbox,
			&.wz-input-radio,
			&.wz-input-color,
			&.wz-input-textarea,
			&.wz-input-number,
			&.wz-input-email,
			&.wz-input-password,
			&.wz-input-flag {
				border-color: $inputErrorColor;

				// Colored input on hover
				&:hover {
					border-color: $inputErrorColor;
				}
			}
		}

		// On error we set the warning icon
		.wz-input-icon {
			i,
			i:hover {
				color: $inputErrorColor;
			}
		}
	}

	&.wz-ui-input-icon:not(.wz-ui-input-left) .wz-input {
		padding-right: 2.8em;
	}

	.wz-input-html{
		margin-right: 20px
	}

	// Colored the border when user flies over the input (even the icons)
	&:not(.wz-ui-input__disabled):not(.wz-ui-input__errors).wz-ui-input-icon:hover .wz-input {
		border-color: rgb(var(--primary-color));
	}

	// Stick the current icon (from right) to the left side
	&.wz-ui-input-left .wz-input-icon {
		left: 0;
	}

	// To stick the leftIcon to the left side of the input
	&.wz-ui-input-icon-left {
		div.wz-input-icon:first-child {
			left: 0;
		}

		// Add padding-left to avoid to stick cursor with icon
		.wz-input {
			padding-left: 2.8em;
		}
	}

	&.wz-ui-input-label {

		.wz-label-input {
			position: absolute;
			right: 0;
			top: 0;
			width: 40px;
			height: 40px;
			color: $greyFourColor;
			text-align: center;
			padding: 15px 5px;
			border: 0 solid transparent;
			line-height: 12px;
			border-radius: 5px;
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;

			&.wz-input-left-label {
				left: 0;
				border-top-left-radius: 0;
				border-bottom-left-radius: 0;

			}
		}

		&.wz-ui-input-label-left .wz-input-icon__left {
			left: 40px;
		}
		&.wz-ui-input-label-icon-left {
			.wz-input {
				padding-left: 80px;
			}
		}
		&.wz-ui-input-label-right .wz-input-icon__right {
			right: 40px;
		}
		&.wz-ui-input-label-icon-right {
			.wz-input {
				padding-right: 80px;
			}
		}

	}
	// For the icons inside the input
	.wz-input-icon {
		align-items: center;
		color: $darkerLightGrey;
		display: flex;
		height: calc(100% - 4px);
		justify-content: center;
		margin: 0 .1em;
		max-width: 2.4em;
		position: absolute;
		right: 0;
		top: 2px;
		width: 100%;

		// Reset semantic styles
		// Add custom styles for icons
		i {
			@include border-radius(100%);
			@include transition-element(all $transitionBorder ease);
			margin: inherit;
			padding: 12px;
			position: relative;

			// Resize the warning icons (they're too much bigger inside the input)
			&.wz-font-message-error-inverted {
				font-size: 1.5em;
				padding: 15px;
			}
			&.wz-without-error-icon {
				background: transparent;
				color: black;
				width: 1.3em;
				height: 1.3em;
				margin: 0 0.5em;
				padding: 13px;
			}

			&:before {
				left: 0px;
				padding-top: 4px;
				position: absolute;
				right: 0;
				top: 0px;
			}

			&.flag {
				padding: 6px 0;
				margin-left: 15px;
			}
		}
	}

	// Input must not be on error to activate these styles
	&:not(.wz-ui-input__errors) {
		// When activeIcons is activated, all icons are colored
		&.wz-ui-input-icons__active .wz-input-icon {
			color: rgb(var(--primary-color));
		}

		.wz-input-icon {
			// Styles for cliked icon
			&__click {
				cursor: pointer;
				i.wz-font {
					font-size: 1.3em;
				}

				i.wz-font.wz-font-circular {
					width: 1.3em;
					height: 1.3em;
					margin: 0 0.5em;
					padding: 13px;
				}

				i:hover {
					background-color: rgb(var(--alpha-color));
					color: rgb(var(--primary-color));
				}
			}

			// Active the icon
			&__active {
				color: rgb(var(--primary-color));
			}
		}

		.wz-input-password + .wz-input-icon {
			i.wz-font {
				font-size: 1.1em;
			}
		}

		// Let the user focus the input only if no error exist
		.wz-input-text,
		.wz-input-url,
		.wz-input-tel,
		.wz-input-checkbox,
		.wz-input-radio,
		.wz-input-color,
		.wz-input-number,
		.wz-input-email,
		.wz-input-password {
			// focus on the keyboard
			&:focus {
				@include box-shadow(0 2px 5px -2px rgb(var(--primary-color)));
				border-color: rgb(var(--primary-color));
			}
		}
	}

	//override search input, icon clear
	&.wz-search-input, &.wz-item__search {
		.wz-input-icon {
			&__click {
				cursor: pointer;
				i.wz-font {
					font-size: 0.8em;
				}

				i.wz-font.wz-font-circular {
					padding: 10px;
				}
			}
		}
	}

	// For the counter we would like that the icons are colored in black
	&.wz-ui-input-number .wz-input-icon {
		color: $black;

		// Change size for minus and plus icon
		i::before {
			font-size: 10px;
			padding-top: 6.5px;
		}
	}

	&.wz-ui-input-length {
		margin-bottom: 30px;
		.wz-input-length {
			color: $greyFourColor;
			position: absolute;
			font-size: $normalFontSize;
			top: 50px;
			right: 5px;

			&__errors {
				color: $inputErrorColor;
			}
		}
	}
}

// Input HTML
.wz-input {
	@include transition-element(all $transitionBorder ease);
	outline: inherit;
	&.wz-input-perf {
		transition: none;
	}

	@include readOnly {
		cursor: not-allowed;
	}

	&.wz-input-checkbox,
	&.wz-input-radio {
		cursor: pointer;
	}

	// Common styles for inputs
	&.wz-input-text,
	&.wz-input-url,
	&.wz-input-tel,
	&.wz-input-checkbox,
	&.wz-input-radio,
	&.wz-input-color,
	&.wz-input-textarea,
	&.wz-input-number,
	&.wz-input-email,
	&.wz-input-password {
		border-color: $darkerLightGrey;
		border-style: solid;
		border-width: 1px;

		// Hover
		&:hover {
			border-color: rgb(var(--primary-color));
		}
	}

	/** INPUT TEXT **/
	&.wz-input-text,
	&.wz-input-url,
	&.wz-input-tel,
	&.wz-input-color,
	&.wz-input-number,
	&.wz-input-email,
	&.wz-input-password {
		@include border-radius($borderRadiusInput);
		@include box-sizing(border-box);
		@include placeholder {
			color: $darkerLightGrey;
		}
		flex: 1 0 auto;
		font-size: $mediumFontSize;
		padding: 12px;
		width: 100%;
	}

	&.wz-input-color {
		height: 24px;
		max-width: 24px;
		border-radius: 5px;
		border: 1px solid $greyAlpha015;
		cursor: pointer;
	}

	// Common styles for checkbox and radio
	&.wz-input-checkbox,
	&.wz-input-radio {
		@include appearance(none);
		background-color: $white;
		height: 17px;
		width: 17px;

		// If input is checked
		&:checked {
			border-color: rgb(var(--primary-color));
		}

		// Create the content inside inputs
		&::after {
			content: "";
			display: none;
			position: relative;
		}
	}

	// Styles for checkboxes
	&.wz-input-checkbox {
		@include border-radius($borderRadiusCheckboxes);

		&:checked {
			background-color: rgb(var(--primary-color));
		}

		// Display check icon when the checkbox is checked
		// It's only text, not image, that's the power of css
		&:checked::after {
			@include border-radius(20px);
			@include transform-element(rotate(45deg));
			border-color: $white;
			border-style: solid;
			border-width: 0 2px 2px 0;
			display: block;
			height: 10px;
			left: 5px;
			top: 1px;
			width: 5px;
		}

		// manage indeterminate status for checkbox
		&:indeterminate {
			background-color: rgb(var(--primary-color));
			border-color: rgb(var(--primary-color));

			// Create solid line
			&::after {
				background-color: $white;
				border-radius: 10px;
				content: "";
				display: block;
				height: 2px;
				left: 3px;
				position: relative;
				top: 7px;
				width: 8px;
			}
		}
	}

	&.wz-input-number {
		text-align: center;

		&.wz-input-number__hide_arrows {
			@include hideNumberArrows();
		}
	}

	// Styles for input radio
	&.wz-input-radio {
		@include border-radius(20px);

		// Create a circle inside the input radio
		&:checked::after {
			@include border-radius(5px);
			background-color: rgb(var(--primary-color));
			display: block;
			height: 11px;
			left: 2px;
			top: 2px;
			width: 11px;
		}
	}

	// For textarea
	&.wz-input-textarea {
		@include placeholder {
			color: $darkerLightGrey;
		}
		@include appearance(none);
		@include border-radius($borderRadiusInput);
		cursor: text;
		font-size: $mediumFontSize;
		min-height: 100px;
		padding: 11px 15px;
		width: 100%;

		&:disabled {
			border-color: $darkerLightGrey;
			cursor: not-allowed;
		}

		// Don't focus when the textarea has error
		&:not(.wz-input-textarea__errors):focus {
			@include box-shadow(0 2px 5px -2px rgb(var(--primary-color)));
			border-color: rgb(var(--primary-color));
		}

		&:focus:required:invalid,
		&.wz-input-textarea__errors {
			border-color: $inputErrorColor;

			// Colored input on hover
			&:hover {
				border-color: $inputErrorColor;
			}
		}
	}
}

/*BEGIN TEXTAREA*/
.wz-textarea-length {
	color: $greyFourColor;
	display: flex;
	justify-content: flex-end;
	font-size: $normalFontSize;
	margin: 5px 0.5em 0 1em;

	&__errors {
		color: $inputErrorColor;
	}
}
/*END TEXTAREA*/

/* TOGGLE PART */
.wz-input-switch {
	display: inline-block;
	height: 20px;
	min-width: 60px;
	position: relative;

	&.wz-input-switch__mini {
		height: 16px;
		min-width: 30px;
		width: 30px;

		// For checked input
		input:checked + .wz-input-switch-slider:before {
			@include transform-element(translateX(115%));
		}

		.wz-input-switch-slider {
			padding: 1px;

			// The slider bullet
			&:before {
				height: 12px;
				width: 12px;
			}
		}
		.wz-input-switch-check {
			position: absolute;
			top: 2px;
			left: 3px;
			color: #FFF;
			font-size: 10px;
		}
	}

	// Manage the disabled system
	&.wz-input-switch__disabled {
		.wz-input-switch-slider:before {
			@include box-shadow(none);
		}

		.wz-input-switch-slider,
		input:checked + .wz-input-switch-slider {
			background-color: $lightGrey;
			border-color: $darkerLightGrey;
			color: $darkerLightGrey;
		}
	}

	// Affect only the input type=checkbox inside toggle input
	> input {
		height: 0;
		opacity: 0;
		width: 0;
	}

	// For checked input
	input:checked + .wz-input-switch-slider {
		background-color: #39C433;
		border-color: #39C433;

		&:before {
			@include transform-element(translateX(120%));
		}
	}

	// When focus on input
	input:focus + .wz-input-switch-slider {
		@include box-shadow(0 2px 5px -2px #39C433);
	}

	// The slider container
	.wz-input-switch-slider {
		@include border-radius(100px);
		@include transition-element(.3s);
		background-color: $black;
		border-color: $black;
		border-style: solid;
		border-width: 1px;
		bottom: 0;
		cursor: pointer;
		left: 0;
		padding: 2px 3.333333333333333%; // 2px/60px
		position: absolute;
		right: 0;
		top: 0;

		// The slider bullet
		&:before {
			@include transition-timing-function(linear);
			@include transition-element(.3s);
			@include box-shadow(0 2px 5px -2px rgba($black, .5));
			background-color: $white;
			border-radius: 100px;
			content: attr(slidercontent);
			font-size: $smallFontSize;
			height: calc(100% - 4px); // calculate height
			min-width: 41.66666666666667%; // 25px/60px
			padding: 2px 6px 4px;
			position: absolute;
			text-align: center;
			text-transform: uppercase;
		}
	}
}

// Color Input Picker
.wz-input-color__picker {
	position: relative;

	input {
		padding: 0 0 1px 5px !important; //override form input style
	}

	.twitter-picker {
		position: absolute!important; //override inline css from react-color
		z-index: 3;
	}
}

/* CurrencyInput */
.wz-currency-input input {
	text-align: center;
}
/* Chrome, Safari, Edge, Opera */
.wz-currency-input input::-webkit-outer-spin-button,
.wz-currency-input input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}
/* Firefox */
.wz-currency-input input[type=number] {
	-moz-appearance: textfield;
}
.wz-ui-input input[type=number] {
	-moz-appearance: textfield;
}
//Hide renderIcon in this case, for the moment ,impossible to disable with a props
.wz-currency-input .wz-input-icon {
	display: none;
}
/* End CurrencyInput */

.wz-input-with-right-component {
	display: flex;
	width: 100%;

	.wz-input-right-component {
		flex-shrink: 0;
		margin-left: 15px;

		&>div {
			height: 100%;
		}
	}
}

// Label
.wz-input-label {
	color: $fontColor;
	display: block;
	font-size: $mediumFontSize;
	margin-bottom: 5px;

	&__required:after {
		color: $inputErrorColor;
		content: "*";
		margin-left: .2em;
	}

	.wz-input-label__optional {
		color: $greyFourColor;
		padding-left: 5px;
		font-size: 10px;
	}
}

// Sublabel
.wz-input-sublabel {
	color: $greyFourColor;
	display: block;
	font-size: $normalFontSize;
	margin-bottom: 5px;
}

/* For InputBlockErrors */
.wz-inputs-errors {
	color: $inputErrorColor;
	font-size: $mediumFontSize;
	list-style: none;
	margin: 5px 0 0;
	padding: 0;
}
.wz-time-input {
	max-width: 180px;
	position: relative;

	.wz-ui-input:not(.wz-ui-input__disabled):hover .wz-input-icon, &.wz-time-input__active .wz-input-icon,
    &.wz-time-input__active .wz-input-text {
		color: rgb(var(--primary-color));
	}
	.wz-ui-input .wz-input{
		cursor: pointer;
	}
	.wz-commondropdown-list {
		overflow: auto;
		max-width: 180px;
		max-height: 250px;
	}

	.wz-font.wz-font-cross::before {
		top: 1px
	}
}

.wz-time-input__selected {
    background-color: rgb(var(--alpha-color));
    border-radius: 5px;
    .wz-item {
        color: rgb(var(--primary-color)) !important
    }
}

.wz-popin .wz-time-input__list {
    max-height: 136px;
}



/* select */
.wz-popin.wz-popin__select {
	z-index: 6;
}

.wz-select-input {
	position: relative;

	.wz-input-icon {
		& > i {
			cursor: pointer;
		}
		.wz-font.wz-font-cross {
			font-size: 0.8em;
			width: 20px;
			height: 20px;
		}
	}
	.wz-input {
		@include transition-element(none);
	}

	.wz-input-value {
		//35px is width rightIcon props input
		width: calc(100% - 35px);
		padding-left: 13px;
		position: absolute;
		top: 0;
		display: flex;
		height: 100%;
		align-items: center;
		user-select: none;
		z-index: 1;
		cursor: pointer;
	}

	&.wz-select__ellipsis .wz-input-value {
		padding-top: 13px;
		display: block;
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
	}

	&.wz-select-input__disabled {
		.wz-input-value {
			color: $darkerLightGrey;
			cursor: not-allowed;
		}
		.wz-input-icon, .wz-input-icon > i {
			cursor: not-allowed;
		}
	}

	&.wz-select-input__active {

		.wz-input-icon > i {
			color: rgb(var(--primary-color));
		}

		.wz-input {
			border-color: rgb(var(--primary-color));
		}

		.wz-commondropdown-list {
			border-color: rgb(var(--primary-color));

			&:not(.wz-commondropdown-list__top){
				border-top: 0;
				border-top-left-radius: 0;
				border-top-right-radius: 0;
			}

			&.wz-commondropdown-list__top{
				border-bottom: 0;
				border-bottom-left-radius: 0;
				border-bottom-right-radius: 0;
			}
		}
	}

	&:not(.wz-select-input__disabled) .wz-input-value:hover + .wz-ui-input.wz-ui-input-icon.wz-ui-input-icon-right .wz-input-text{
		border-color: rgb(var(--primary-color));
	}

	&:not(.wz-select-input__disabled) .wz-ui-input:hover .wz-input-icon,
	&.wz-time-input__active .wz-input-icon
	{
		color: rgb(var(--primary-color));
	}
	.wz-ui-input .wz-input{
		cursor: pointer;
	}
	.wz-commondropdown-list {
		overflow: auto;
		max-height: 250px;
		width: 100%;
		@include box-shadow(none);

		&:not(.wz-commondropdown-list__top){
			padding-top: 0;
			margin-top: -10px;
		}

		&.wz-commondropdown-list__top{
			padding-bottom: 0;
			margin-top: 10px;
		}

		&.wz-commondropdown-list__medium {
			width: 100%;
		}
	}
}

/* select */


div.wz-dropdownButton-input{
	margin-right: 50px;

	&:hover{
		background: rgba(var(--primary-color), 0.13);
		color: rgb(var(--primary-color));
	}
}

div.wz-dropdownButton-input-disabled{
	margin-right: 50px;
}

div.wz-dropdownButton{
	max-width: None;
	display: inline-block;

	.wz-commondropdown-list{
		overflow: auto;
		max-height: 250px;
		max-width: 100%;
		&.wz-commondropdown-list__medium{
			width: 100%;
		}
	}

	span.wz-icon-button-icon{
		position:initial
	}
}

.wz-dropdown-input {
	max-width: 180px;
	position: relative;

	.wz-ui-input {
		border-radius: 5px;
	}

	.wz-ui-input:hover, &.wz-dropdown-input__active  .wz-ui-input {
		background: rgba(var(--primary-color), 0.13);
		.wz-input, .wz-input-icon {
			color: rgb(var(--primary-color));
		}
	}
	.wz-ui-input .wz-input{
		cursor: pointer;
		border: none;
		background: rgba(0, 0, 0, 0);
	}
	.wz-commondropdown-list {
		overflow: auto;
		max-width: 180px;
		max-height: 250px;
	}

	.wz-framebutton {
		span.wz-dropdown-arrow:after {
			top: 4px;
		}
	}

	span.wz-dropdown-arrow:after,
	div.wz-ui-input.react-tel-input .selected-flag .arrow:after {
		content: "";
		width: 10px;
		height: 10px;
		position: absolute;
		border-bottom: 2px solid;
		border-right: 2px solid;
		top: 2px;
		right: 29px;
		cursor: pointer;
		transform: translate(50%, 80%) rotate(45deg);
	}

	span.wz-dropdown-arrow-disabled:after {
		opacity: 0.2;
	}
}
/*
 * FIELDBLOCK COMPONENT
 * Manage the block for the field.
 * Contains all label/sublabel/input/errors system. All in one !
 */
.wz-field-block {
	margin: .5em 0 1em;

	//START wz-field-block-checkbox
	&.wz-field-block-checkbox {
		.wz-ui-input input{
			position: absolute;
		}
		&.wz-field-block-checkbox__inline {
			display: inline-block;

			&:not(:last-child) {
				margin-right: 15px;
			}

			.wz-input-label {
				padding: 2px 0 0 0;
			}
		}

		&.wz-field-block-checkbox__bordered {
			width: fit-content;

			.wz-ui-input {
				input {
					top: 9px;
					left: 8px;
				}
				input:checked + label {
					border-color: rgb(var(--primary-color));
					@include box-shadow(0 2px 5px -2px rgb(var(--primary-color)));
				}
				.wz-input-radio {
					z-index: 2;
				}
				.wz-input-label {
					border: 1px solid $blackAlpha02;
					@include transition-element(.3s);
					@include border-radius($borderRadiusInput);
					margin-left: 0;
					padding: 10px 10px 10px 30px;
					background-color: #FFF;

					&:hover {
						border-color: rgb(var(--primary-color));
						@include box-shadow(0 2px 5px -2px rgb(var(--primary-color)));
					}
				}
			}
		}

		.wz-input-label {
			cursor: pointer;
			display: block;
			padding-top: 2px;
			margin-left: 25px;
			position: relative;
			width: 100%;
		}

	}
	// END wz-field-block-checkbox
	&__right_inline {
		display: flex;
		flex-direction: row-reverse;
		justify-content: flex-end;
		align-items: center;

		label, .wz-toggle-sublabel {
			margin-left: 5px;
			margin-bottom: 0;
		}
	}

	&__left_inline {
		display: flex;
		align-items: center;

		label, .wz-toggle-sublabel  {
			margin-left: 5px;
			margin-bottom: 0;
		}
	}

	// Manage the eye of the passwordIcon in black when the user focus the input
	// or when he passes his cursor on the field
	&.wz-field-block-passwordicon {
		// When the field is focus, we set the color of the icon
		.wz-input:focus + .wz-input-icon i {
			color: $black;
		}

		// On Hover
		.wz-ui-input:hover .wz-input-icon i {
			color: $black;

			&:hover {
				color: rgb(var(--primary-color));
			}
		}
	}
}

.wz-session {
	display: flex;
	align-items: center;
	border-radius: 5px;
	width: 100px;
	height: 66px;
	padding: 15px 10px 10px;
	background-color: rgb(var(--lighter-color));
	color: rgb(var(--primary-color));

	&__onclick {
		cursor: pointer;
		&:hover {
			border: 1px solid rgb(var(--primary-color));
		}
	}

	&__disabled {
		background-color: $sessionDisabledColor;
		color: $greyFourColor;
	}

	&__unavailable-disabled {
		opacity: 0.8;
		background-color: $lightGrey;
		color: $greyFourColor;
	}

	&__unavailable-enabled {
		background-color: #FFFFFF;
		opacity: 0.8;
		border: 1px solid $darkerLightGrey;
		color: $greyFourColor;
	}

	&__selected {
		border: 1px solid rgb(var(--primary-color));
	}

    &__right {
        flex-grow: 1;
        text-align: center;
    }
	&__title, &__subtitle{
		display: block;
		line-height: 1.3;
	}

	&__title {
		font-weight: 500;
		font-size: $largeFontSize;
	}
	&__subtitle {
		font-size: $normalFontSize;
	}


}

.wz-input-icon .wz-dropdown-pipe__center,
.wz-input-icon .wz-dropdown-pipe__right,
.wz-input-icon .wz-dropdown-pipe__left{
	color: #000000;
}

.wz-dropdown-input:hover, .wz-dropdown-input.wz-dropdown-input__active {
    border-radius: 5px;
    background-color: #d4dcef;
	.wz-dropdown-pipe__right, .wz-dropdown-pipe__left::before, .wz-dropdown-pipe__center {
		color: rgb(var(--primary-color));
	}
}

// Chips component start

.wz-chip {
	padding: 2px 6px;
	border-radius: 16px;
	background-color: #ededed;
	display: flex;
	width: fit-content;
	align-items: center;
	.wz-chip-text {
		padding-top: 2px;
	}
	.wz-chip-text-delete {
		padding-top: 0;
	}
	.wz-chip-text-delete ~ .wz-chip-cross {
		i.wz-font {
			vertical-align: unset;
		}
	}
	.wz-chip-cross {
		margin-left: 5px;
		border-radius: 50%;
		width: 18px;
		text-align: center;
		padding-top: 1px;
		&:hover {
			background-color: #d4d4d4;
			cursor: pointer;
		}
		i {
			font-size: 10px !important;
			margin: 0 !important;
		}
	}
	&.wz-chip-primary {
		color: rgb(var(--primary-color));
		background-color: rgb(var(--lighter-color));
	}
	&.wz-chip-error {
		border: solid 1px #f4080a;
		color: #f4080a;
		background-color: #fff;
		.wz-chip-cross {
			background-color: #fff;
			&:hover {
				background-color: #f8e5e7;
			}
		}
	}

}
.wz-chip-bullet {
	padding: 2px 6px;
	box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.14);
	font-size: 12px;
	.wz-chip-text-ongoing, .wz-chip-text-future {
		color: #878c9b;
	}
	.wz-chip-bullet-point {
		border-radius: 50%;
		margin-right: 6px;
		width: 12px;
		height: 12px;
		&.wz-chip-bullet-point-finished {
			background-color: rgba(30, 35, 50, 0.15);
		}
		&.wz-chip-bullet-point-ongoing, &.wz-chip-bullet-point-valid {
			background-color: #39c433;
		}
		&.wz-chip-bullet-point-warning {
			background-color: #FAA43E;
		}
		&.wz-chip-bullet-point-error {
			background-color: $inputErrorColor;
		}
		&.wz-chip-bullet-point-future {
			background-color: #9933ff;
		}
	}
}

.wz-chip-icon-container-with-text {
	.wz-chip-icon {
		position: relative;
		top: 2px;
		margin-right: 6px;
		i.wz-font {
			width: 12px;
			height: 12px;
			vertical-align: unset;
		}
	}
}
.wz-chip-icon-container {
	padding: 2px 3px;
	box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15);
	&.wz-chip-icon-container-primary {
		color: rgb(var(--primary-color));
		background-color: rgb(var(--lighter-color));
	}
	.wz-chip-icon {
		position: relative;
		top: -1px;
		width: 12px;
		height: 12px;
		i {
			font-size: 12px !important;
		}
	}
}

// Chips component end

// InputMulti start

.wz-input-multi {
	border: solid 1px #ced0da;
	margin-bottom: 20px;
	border-radius: 5px;
	padding: 6px 8px 6px 8px;
	display: inline-block;
	flex-wrap: wrap;
	width: 100%;
	height: fit-content;
	min-height: 38px;
	display: flex;
	align-items: center;
	background-color: #fff;
	.wz-input-add-email {
		border: none;
		outline: none;
		flex-grow: 1;
		&::placeholder {
			color: #CED0DA;
		}
	}
	.wz-chip {
		margin: 5px;
	}
	.wz-hidden-input {
		display: none;
	}

	.error {
		border: solid 1px #f4080a;
		color: #f4080a;
		background-color: #fff;
	}
}

// InputMulti end

// InputGroup start

.wz-input-group {
	width: 150px;
	display: flex;
	box-sizing: border-box;
	background-color: #FFFFFF;
	border-color: #ced0da;

	&__counter-container {
		width: 100%;
		.wz-ui-input .wz-input-icon {
			width: 0;
		}
		.wz-input {
			border-radius: 5px 0 0 5px;
		}
	}

	&__input-container {
		width: 100%;
		.wz-ui-input .wz-input-icon {
			width: 0;
		}
		.wz-input {
			border-radius: 5px 0 0 5px;
		}
	}

	&__select-wrapper {
		position: absolute;
		right: 0;
		user-select: none;
		height: 100%;
		padding: 4px;
	}

	&__select-container {
		display: flex;
		.wz-input {
			border-radius: 0 5px 5px 0 ;
		}
		.wz-popin.wz-popin__select .wz-commondropdown-list {
			border-color: rgb(var(--primary-color));
		}
	}

	&__select-separator {
		height: 100%;
		content: "";
		display: block;
		height: 100%;
		width: 1px;
		background-color: #ced0da;
	}

	.wz-advanced-search-type-select__trigger-button {
		&:hover {
			background-color: transparent;
		}
	}

	.wz-commondropdown-list.wz-commondropdown-list__maxi {
		width: 150px;
	}

	&__errors {
		.wz-input {
			border-color: $inputErrorColor;
		}
		.wz-input-units__select-separator {
			background-color: $inputErrorColor;
		}
	}

	&__reverse {
		flex-direction: row-reverse;
		.wz-input-group__counter-container {
			.wz-input {
				border-radius: 0 5px 5px 0;
			}
		}

		.wz-input-group__input-container {
			.wz-input {
				border-radius: 0 5px 5px 0;
			}
		}
		.wz-input-group__select-container {
			.wz-input {
				border-radius: 5px 0 0 5px;
			}
		}
	}
}

// InputGroup end

/* Override custom tel input CSS */
div.wz-ui-input.react-tel-input input.form-control {
    box-sizing: border-box;
    flex: 1 0 auto;
    font-size: 14px;
    padding: 12px;
    padding-left: 65px;
    width: 100%;
    height: auto;
    line-height: initial;
}

div.wz-ui-input.react-tel-input div.flag-dropdown {
    border-right: 0;
    background: white;
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
    transition: all 0.4s ease;
}

div.wz-ui-input.react-tel-input .selected-flag {
    padding: 0 50px 0 8px;
}

div.wz-ui-input.react-tel-input .selected-flag .flag {
    margin-top: -7px;
    margin-left: 9px;
    transform-origin: center;
    transform: scale(1.2);
}

div.wz-ui-input.react-tel-input .selected-flag .arrow {
    border: 0;
    top: unset;
}

div.wz-ui-input.react-tel-input .selected-flag .arrow:after {
    content: "";
    width: 8px;
    height: 8px;
    position: absolute;
    border-bottom: 1px solid $darkerLightGrey;
    border-right: 1px solid $darkerLightGrey;
    top: -5px;
    cursor: pointer;
    transform: translate(50%, 80%) rotate(45deg);
}

.wz-ui-input.wz-ui-input__disabled .wz-input-text,
.wz-ui-input.wz-ui-input__disabled .wz-input-text ~ div.flag-dropdown {
    background-color: $lightGrey;
}

/* Overload border from input */
.wz-ui-input:not(.wz-ui-input__errors):not(.wz-ui-input__disabled) .wz-input-text:focus ~ div.flag-dropdown,
.wz-ui-input:not(.wz-ui-input__errors):not(.wz-ui-input__disabled) .wz-input.wz-input-text:hover ~ div.flag-dropdown {
    /* box-shadow: 0 2px 5px -2px rgb(var(--primary-color)); */
    border-color: rgb(var(--primary-color));
}

/**
 * @see https://www.xfive.co/blog/itcss-scalable-maintainable-css-architecture/
 * Styles from Nacre
 */
.header-container {
	height: fit-content;

	.return-url {
		display: inline-block;
		color: $darkGrey;
		margin-left: -3px;
		font-size: 12px;
		line-height: 15px;
		cursor: pointer;

		&:hover {
			.text {
				padding-bottom: 1px;
				border-bottom: 1px solid $darkGrey;
			}
		}
	}

	.header {
		height: auto;
		margin: 25px 0;
		display: block;

		&.header-with-return-url {
			margin-top: 10px;
		}

		.header-title {
			display: flex;
			justify-content: space-between;

			.header-right {
				font-size: 14px;
				color: $black;
				line-height: 1em;
				text-align: right;

				a {
					@include border-radius($borderRadiusCommonWidth);
					color: $fontMenuColor;
					background-color: rgb(var(--primary-color));
					border: 1px solid rgb(var(--primary-color));
					padding: .78571429em 1.5em;
					margin-top: -5px;
					display: block;
					text-align: center;

					&:hover {
						background-color: transparent;
						color: rgb(var(--primary-color));
					}
				}
			}
		}

		p {
			color: $darkGrey;
		}
	}
}

@media all and (max-width: $widthMobile) {
	.header-container .header {

		&,
		.ui.hidden.divider {
			margin-bottom: 0;
		}

		.header-title {
			display: block;
		}
	}
}

.wz-right-toolsheader-dropdown {
	display: flex;
    margin-left: 10px;

	&> div:first-child {
		@include transition-element(background $transitionColor ease);
		background-color: transparent;

		&> .wz-commondropdown__selectors, &> .wz-commondropdown__selectors::before, &> .wz-commondropdown__selectors::after{
			background-color: rgb(var(--primary-color));
		}

		&:hover {
			background-color: rgb(var(--alpha-color));
		}

	}

	&.wz-commondropdownframe {
		//height of CTA
		height: 37px;
		width: 37px;

		.wz-commondropdown {
			height: 37px;
			width: 37px;

			.wz-commondropdown__selectors{
				margin-top: 16px;
				margin-left: 16px;
			}
		}
	}
}




.wz-right-toolsheader-components{
    display: flex;

	.wz-button:not(.wz-button-primary) {
		display: none;
	}
}

.wz-toolsheader-dropdown-mobile-only{
    // WeezHeader : hide right components dropdown on right side if no items except mobile item
    display: inline-block;
}

// Start WZ-HEADER
.wz-header {
	background-color: $white;
	margin-bottom: 15px;
	padding: 12px 15px;
	position: relative;

	// wz-header__fixed
	// fixed the header
	&__fixed {
		&:not(.wz-header__no-shadow){
			@include box-shadow(10px 0 6px 0 $blackAlpha02);
		}
		position: fixed;
		width: 100%;
		z-index: 7;

		&.wz-header__on-top {
			margin-right: inherit;
		}
	}

	// Override only if the component is placed on the top
	// of the page otherwise, we let the same behavior
	&__on-top {
		margin-left: -$paddingWZContent;
		margin-right: -$paddingWZContent;
	}

	.wz-button {
		&:not(:last-of-type) {
			margin-right: 15px;
		}
	}

	.wz-topheader {
		align-items: center;
		display: flex;

		.wz-backlinkheader {
			@include transition-element(color $transitionColor ease);
			color: $greyOneColor;
			cursor: pointer;
			display: flex;
			font-size: 15px;
			margin-right: 5px;
			// Align chevron with subtitle
			margin-left: -8px;


			&:hover {
				color: rgb(var(--primary-color));
				background-color: rgb(var(--alpha-color));
				border-radius: 50px;
			}

			i.wz-font.wz-font-chevron-left {
				padding: 3px 5px;
				&::before {
					position: relative;
					top: 1px;
					left: 2px;
				}
			}

			.wz-tooltip {
				height: 15px;
				margin: 0;
			}
		}

		.wz-titleheader {
			color: $fontColor;
			flex: 1;
			font-size: $bigFontSize;
			margin-right: 12px;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}

		.wz-toolsheader {
			align-items: center;
			display: flex;
		}

		.wz-ctatopheader,
		.wz-actionbuttonheader {
			display: block;
			margin-left: 1em;
		}

		// Styles with wz-dropdownHeader
		// display background-color for the dropdown
		// that indicates that we cannot click on it
		.wz-commondropdown:hover.wz-dropdownheader.wz-commondropdown__disabled {
			background-color: inherit;
		}

		.wz-dropdownheader {
			color: rgb(var(--primary-color));

			// On mobile, don't show the spinner inside the dropdown
			&.wz-commondropdown__disabled > .active.loader {
				display: none;
			}

			// Don't display in mobile the Button and CTA/Dropdown
			.wz-dropdownheader-desktop {
				display: none;
			}

			/*Manage the disabled for the menu with little points*/
			&.wz-commondropdown__disabled {
				.wz-dropdownheader__selectors,
				.wz-dropdownheader__selectors::before,
				.wz-dropdownheader__selectors::after,
				&:hover .wz-dropdownheader__selectors,
				&:hover .wz-dropdownheader__selectors::before,
				&:hover .wz-dropdownheader__selectors::after {
					background-color: $greyFourColor;
				}
			}

			.wz-dropdownheader-points {
				height: 25px;
				padding-top: 10px;
				position: relative;
				width: 25px;
			}

			// Menu
			&__selectors::before {
				margin-left: -7px;
			}

			&__selectors::after {
				margin-left: 7px;
			}

			&__selectors::before,
			&__selectors::after {
				content: "";
				position: absolute;
			}

			&__selectors,
			&__selectors::before,
			&__selectors::after {
				@include border-radius($borderRadiusSelectorsWidth);
				background-color: $black;
				height: 4px;
				width: 4px;
			}

			&:hover .wz-dropdownheader__selectors,
			&:hover .wz-dropdownheader__selectors::before,
			&:hover .wz-dropdownheader__selectors::after,
			&.wz-commondropdown__active .wz-dropdownheader__selectors,
			&.wz-commondropdown__active .wz-dropdownheader__selectors::before,
			&.wz-commondropdown__active .wz-dropdownheader__selectors::after {
				background-color: rgb(var(--darker-color));
			}

			&__selectors {
				margin-left: 10px;
				position: absolute;
			}
		}
	}

	.wz-containerheader {
		color: $greyFourColor;
		font-size: 12px;

		// Buttons are the CTA at the bottom
		.wz-framebutton {
			margin-top: .6em;
		}
	}

	.wz-block-dropdownheader {
		position: relative;
	}

	.wz-buttonbottomheader {
		display: none;
	}
}

// This block of styles will show a ghost div that let us
// to show contents above the header
.wz-header-block {
	display: none;
	margin-bottom: 15px;

	&.wz-header-block__fixed {
		display: block;
	}
}

@media screen and (min-width: $widthTablet) {
	/*
	 * Generate the size of the header when the sidebar exists.
	 * In fact, we have to do this because we set marginLeft of the wz-content
	 * by the size od the sidebar. Block in fixed will take 100% so we decrease
	 * the size of the header
	 * */
	@each $currentNameSidebar, $currentWidth in
		(mini, $leftMenuMini),
		(medium, $leftMenuMedium),
		(maxi, $leftMenuMaxi),
		(maxi-w-optional-menu, $leftMenuMaxiWithOptionalMenu) {
			.wz-sidebar-#{$currentNameSidebar} .wz-header__fixed {
				// -16px means the padding of the header
				width: calc(100% - #{$currentWidth} - 16px);
			}
	}

	.wz-header {
		margin-left: 0;

		// For the CTABottomHeader
		.wz-ctabottomheader {
			margin-right: 2em;
		}
	}
}


@media screen and (min-width: $widthTablet) {
	.wz-toolsheader-dropdown-mobile-only{
		// WeezHeader : hide right components dropdown on right side if no items except mobile item
		display: None;
	}

	// For the ButtonBottomHeader
	.wz-header {
		&__on-top {
			margin-right: -$paddingWZContentDesktop;
		}

		.wz-buttonbottomheader {
			display: inline-block;
		}

		.wz-dropdownheader__mobile-only {
			display: none;
		}
		.wz-right-toolsheader-components {
			.wz-button {
				display: flex;
				padding: 12px 16px 10px;
			}
		}
	}
}

@media screen and (min-width: $widthDesktop) {
	.wz-header {

		// Start topHeader
		.wz-topheader {
			// Hide the dropdown if no items are available
			// the component can be accept ActionButtonHeader or CTATopHeader
			// without items
			// This, wiil hide the dropdown of the CTAs when resize
			.wz-toolsheader__no-items .wz-commondropdown-list {
				display: none;
			}

			.wz-titleheader {
				margin-right: 20px;
			}

			// Gather the dropdownHeader and the dropdownList
			.wz-block-dropdownheader .wz-commondropdown-list {
				top: 65px;
			}

			// Styles for dropdownHeader
			// Creation of new styles to show triangle with label
			// for the dropdown
			.wz-dropdownheader {
				@include border-radius($borderRadiusButtonWidth);
				@include transition-element(background $transitionColor ease);
				background-color: $white;
				border-width: 1px;
				border-style: solid;
				border-color: rgb(var(--primary-color));
				color: rgb(var(--primary-color));
				display: inline-block;
				font-weight: bold;
				font-size: $normalFontSize;
				height: inherit;
				margin-left: 1em;
				padding: 12px 16px;
				width: inherit;

				&:hover {
					background-color: $lightGrey;
				}

				&__disabled {
					background-color: $greyThreeColor;

					&:hover {
						background-color: $mediumGrey;
					}
				}

				&.wz-commondropdown__disabled {
					border-color: $greyFourColor;

					&:hover {
						cursor: not-allowed;
					}

					& > .active.loader {
						display: block;
					}

					.wz-dropdownheader-label {
						color: $greyFourColor;
					}

					.wz-dropdownheader-triangle {
						border-bottom-color: $greyFourColor;

						&:hover {
							border-bottom-color: $greyFourColor;
						}
					}
				}

				// Set darkerColor when the dropdown is opened
				&.wz-dropdownheader__open,
				&.wz-dropdownheader__open:hover {
					border-color: rgb(var(--darker-color));
					color: rgb(var(--darker-color));

					.wz-dropdownheader-triangle {
						border-bottom-color: rgb(var(--darker-color));
					}
				}

				// Hide the menu if nothing to render into it
				&.wz-toolsheader-dropdown {
					display: none;
				}

				// We hide the pattern from mobile and show it for desktop
				.wz-dropdownheader-points {
					display: none;
				}

				.wz-dropdownheader-desktop {
					display: block;
				}

				.wz-dropdownheader-label {
					display: inline-block;
					margin-right: 8px;
				}

				// Creation of the triangle selector
				.wz-dropdownheader-triangle {
					@include transition-element(all $transitionColor ease);
					border-bottom: 6px solid rgb(var(--primary-color));
					border-left: 4px solid transparent;
					border-right: 4px solid transparent;
					display: inline-block;
					transform: rotate(180deg);
				}

				// Hover the triangle
				&:hover:not(.wz-commondropdown__disabled) .wz-dropdownheader-triangle {
					border-bottom-color: rgb(var(--darker-color));
				}
			}

			// Down the dropdown
			.wz-commondropdown-list {
				top: 55px;
			}

			// Listing of links
			.wz-dropdownheader-list {
				right: 16px;
				top: 45px;
			}
		}
		// End TopHeader
	}
}
// End WZ-HEADER

/**
 * @see https://www.xfive.co/blog/itcss-scalable-maintainable-css-architecture/
 * Styles from Nacre
 */

// TODO: remove the styles for the menu when new one is validated
// Fixed the double scroll and fixed the main content
// body.wz-menu .wz-content {
// 	position: fixed;
// 	width: 100%;
// }

/*
 * Display the styles for the top menu
 *
 * $currentNameHeight: means one of the props (min/medium/maxi)
 * $currentHeight: height of the default size
 * $posX: it's the position to align horizontaly the background image
 * $posY: it's the position to align verticaly the background image
 */
@each $currentNameHeight, $currentHeight, $posX, $posY in
	(mini, $topMenuMini, $logoPositionXMini, $logoPositionYMini),
	(medium, $topMenuMedium, $logoPositionXMedium, $logoPositionYMedium),
	(maxi, $topMenuMaxi, $logoPositionXMaxi, $logoPositionYMaxi) {
	.wz-height-#{$currentNameHeight} {
		.wz-topmenu,
		.wz-logo {
			height: $currentHeight;
		}

		.wz-logo {
			background-position: $posX $posY;
		}

		.wz-container {
			top: $currentHeight;
		}

		.wz-leftmenu {
			max-height: calc(100% - #{$currentHeight});
		}
	}
}

/**
 * The main content of the page
 * This class contains the wz-leftmenu and the wz-content
 */
.wz-container {
	position: relative;

	// The content pushed if the leftmenu exists
	.wz-content {
		margin-left: 0px;
		padding: 0 $paddingWZContent 2em;
		transition: margin 1s;
		padding-bottom: 80px;
	}
}

///// PART FOR THE TOP MENU \\\\\
/**
 * The topMenu is included inside the wz-height-(mini/medium/maxi)
 * You have:
 * .wz-topmenu > .wz-burger + .open > .wz-burgerctnt
 * .wz-universe > .wz-logo
 * .wz-module > .wz-module-information > wz-organization > .wz-organization-title > .wz-organization-name
 */

// Styles fot the topMenu
.wz-topmenu {
	background-color: rgb(var(--primary-color));
	display: flex;
	left: 0;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 10;
}

// Universe is the section with the logo
.wz-universe {
	flex: auto;

	.wz-logo {
		background-repeat: no-repeat;
		background-size: 225px;
		width: 113px;
	}
}

/**
 * WZ account gather the data on the right of the topMenu
 * Organization (title and name)
 * Icon for the dropdown that contains links
 * Links (logout, account, ...)
 */
.wz-module {
	color: $white;
	display: flex;
	margin-right: 1em;

	.wz-tooltip {
		margin: 0;
		align-self: center;
	}

	.wz-dropdowncommonitem:not(.wz-dropdowncommonitem__disabled).wz-universe-item:hover {
		background-color: $lightGrey;

		a, p {
			color: $black;
		}
	}

	.wz-universe-item .wz-item {
		img {
			height: 2em;
			max-width: 100%;
		}

		p {
			color: $black;
			margin-left: 3.2em;
		}
	}

	// Item is the icon and organization
	.item,
	.item.wz-module-information {
		align-items: center;
		display: flex;
		flex: 0 0 auto;
	}

	// For user icon
	.wz-module-information {
		color: $fontMenuColor;

		// When the dropdown is opened, change the colors for the icon
		&.visible .wz-module-icon {
			background-color: $iconActiveTopBackgroundColor;
			color: $iconActiveTopFontColor;

			&:hover {
				background-color: $iconActiveHoverTopBackgroundColor;
				color: $iconActiveHoverTopFontColor;
			}
		}

		// Toggle the text with icon (switchIconToText's prop)
		&__switchtext .text {
			display: none;
			font-weight: bold;
			margin-right: 1em;
			text-transform: uppercase;
		}

		.wz-module-icon {
			@include border-radius(18px);
			@include transition-element(border $transitionBorder ease);
			border-color: $iconTopBorderColor;
			border-style: solid;
			border-width: 1px;
			color: $iconTopColor;
			display: block;
			height: 32px;
			margin-right: 0.6em;
			padding-top: 8px;
			width: 32px;

			&:hover {
				color: $iconTopHoverColor;
				border-color: $iconTopBorderHoverColor;
			}
		}

		// Inherit the color from semantic forl a (by default in blue)
		.menu .item a {
			color: inherit;
		}

		/* Manage the item on the menu for universes (target-access-ticket-pay) */
		.item.wz-universe-item {
			img {
				margin: 0 .5em 0 0;
				width: 20px;
			}

			p {
				display: inline-block;
				padding-top: 0.16em;
			}
		}
	}

	// Custom css for the Organization
	.wz-organization {
		display: none;
		margin-top: 5px;
		margin-right: 10px;

		.wz-organization-title {
			display: inline-block;
			color: $fontMenuColor;
			font-size: $mediumFontSize;
			font-weight: 500;
			margin-bottom: 0;
			max-width: 150px;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			padding-right: 10px;
		}

		.wz-organization-name {
			font-weight: bold;
			display: inline-block;
			color: $fontMenuColor;
			font-size: $mediumFontSize;
			font-weight: normal;
			// override because of semantic-ui-react
			margin: 0 !important;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}
}

/**
 * The wrapper for the burger menu
 * This wrapper is always visible
 * (to make the logo centered whenever)
 */
.wz-burger {
	cursor: pointer;
	height: 100%;
	padding-top: 20px;
	width: 50px;

	// If the menu is opened
	&.open {
		transition: all 2s;

		.wz-burgerctnt {
			height: 0;

			&,
			&::before,
			&::after {
				background-color: $burgerActiveColor;
			}

			&::before {
				margin: 0;
				transform: rotate(130deg);
			}

			&::after {
				margin: 0;
				transform: rotate(-130deg);
			}
		}
	}

	/**
	 * The burger is only visible if
	 * the menu was instanciated by the context
	 */
	.wz-burgerctnt {
		margin: 10px;
		top: 50%;
		transform: translateY(-10px);

		&,
		&::before,
		&::after {
			@include border-radius($borderRadiusBurgerWidth);
			background-color: $burgerColor;
			height: 2px;
			position: absolute;
			transition: all .3s;
			width: 25px;
		}

		&::before {
			margin-top: -8px;
		}

		&::after {
			margin-top: 8px;
		}

		&::before,
		&::after {
			content: " ";
			display: block;
		}
	}
}

.wz-leftmenu {
	height: 100%;
	position: fixed;
	transform: translateX(-100%);
	transition: transform $leftMenuTransition;
	width: 100%;
	z-index: 8;
	user-select: none;
	background-color: transparent;

	.wz-leftmenu-content {
		background-color: rgb(var(--lighter-color));
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-between; // push back-orga to the bottom of the menu even without a menu
		&:not(.wz-leftmenu-optional) {
			border-right: 1px solid #dce1f0;
		}
	}

	&.open {
		transform: translateX(0%);
		@include transition-element(background $transitionColor ease);
		background-color: $blackAlpha02;
	}

}
///// END OF PART FOR THE TOP MENU \\\\\

@media screen and (min-width: $widthTablet) {
	// We set the position of the content
	body.wz-tmenu .wz-content {
		position: inherit;
	}

	// Remove the burger on the tablet
	.wz-burger {
		display: none;
	}

	.wz-universe {
		// Display the full logo at the left
		.wz-logo {
			background-position: center;
			background-size: 180px;
			margin: 0;
			width: 150px;
		}

		// Remove the margin caused by applications dropdown
		&__applications {
			margin-left: inherit;
		}
	}

	// Displaying the text with organization data
	.wz-module {
		.wz-organization {
			display: block;
		}

		.wz-module-information__switchtext {
			.text {
				display: block;
			}

			.wz-module-icon {
				display: none;
			}
		}
	}

	// Display the width for the left menu
	@each $currentNameSidebar, $currentWidth in
		(mini, $leftMenuMini),
		(medium, $leftMenuMedium),
		(maxi, $leftMenuMaxi),
		(maxi-w-optional-menu, $leftMenuMaxiWithOptionalMenu) {

		// Set the width when the menu was opened on mobile
		body.wz-menu .wz-sidebar-#{$currentNameSidebar} .wz-content {
			width: calc(100% - #{$currentWidth});
		}

		.wz-sidebar-#{$currentNameSidebar} {
			.wz-leftmenu,
			.wz-leftmenu.open {
				width: $currentWidth;
			}

			.wz-content {
				margin-left: $currentWidth;
			}
		}
	}

	.wz-leftmenu,
	.wz-leftmenu.open {
		transform: translateX(0%);
		width: 0px;
		z-index: 5;
	}

	.wz-container .wz-content {
		z-index: 1;
	}
}

@media screen and (min-width: $widthTablet) {
	.wz-container .wz-content {
		padding: 0 $paddingWZContentDesktop 2.5em;
		padding-bottom: 80px;
	}

	.wz-module .wz-organization .wz-organization-title {
		max-width: 400px;
	}
}

// START OF MENU \\
.wz-menu {
	flex-grow: 1;
	overflow: auto;

	&::-webkit-scrollbar {
		width: 2px;
		height: 2px;
	}
}

.wz-menu-frame {
	padding: 18px 0 0 15px;
}

// For the content of the menu
.wz-menu-content {
	margin-bottom: 18px;
}

// For the item inside the menu
.wz-nav-item {
	cursor: pointer;
	list-style: none;
	margin-bottom: 4px;
	display: flex;
	width: 100%;

	// Pour le hover des items du menu
	&:hover > a,
	&:hover > button {
		background-color: rgb(var(--alpha-color));
		color: rgb(var(--primary-color));
	}

	// Styles for the items
	> a,
	> button {
		@include transition-element(background $transitionColor ease, color $transitionColor);
		color: $black;
		cursor: pointer;
		display: block;
		padding: 10px;
		width: 100%;

		&.active {
			color: rgb(var(--primary-color));
			font-weight: 500;

			.wz-nav-text {
				// Ugly but necessary to overide font-weight: normal setup for h4.wz-nav-text
				font-weight: 500 !important;
			}
		}
	}

	.wz-nav-item-container {
		display: flex;
		justify-content: space-between;

		.wz-nav-item-content {
			i.wz-font {
				margin-right: 10px;
			}
		}
	}

	> button {
		width: 100%;
		appearance: none;
		text-align: inherit;
		background-color: transparent;

		&:focus {
			outline: inherit;
		}
	}
}

// The text of the item
.wz-nav-text {
	font-size: $mediumFontSize;
	font-weight: 500;
	padding: 0;
	margin: 0;
}

.wz-nav-item {
	// Set the transition for the chevron
	i.wz-font.wz-font-chevron-bottom {
		@include transition-element(transform $transitionColor ease);
	}

	// Animates the chevron
	&.show i.wz-font.wz-font-chevron-bottom {
		@include transform-element(rotate(180deg));
	}

	&.active button{
		color: rgb(var(--primary-color));
	}
}

// The category and subcategory for the items of the menus
.wz-nav-items {
	margin: 0;
	padding: 0;

	// Hide the first div that gather all submenus
	> div {
		display: none;

		// Display the submenus
		&.wz-nav-items__show {
			display: block;
		}

		.wz-nav-item .wz-nav-text {
			font-weight: normal;
			padding-left: 35px;
		}
	}
}

@media screen and (min-width: 480px) {
	.wz-leftmenu .wz-leftmenu-content {
		max-width: 260px;
	}
}

// END OF MENU \\

/**
 * @see https://www.xfive.co/blog/itcss-scalable-maintainable-css-architecture/
 * Styles from Nacre
 */

.block {
	@include border-radius($borderRadiusCommonWidth);
	background-color: $white;
	border: 0;
	margin-bottom: 15px;
	padding: 12px;

	&.blocktile{
		min-height: 112px;

		.blocktile-statistic {
			font-size: 2em;
			font-weight: 800;
			padding-top: 5px;
			margin-top: 15px;
			margin-bottom: 5px;
			text-align: center;
			color: $statisticsColor;
		}

		.blocktile-loader {
			top: auto;
			left: auto;
			right: 15px;
		}

		.blocktile-title {
			font-size: 1.3em;
			text-align: center;
		}

		.blocktile-subtitle,
		.blocktableinfo-value {
			font-size: 0.8em;
			font-weight: 700;
			text-align: center;
			color: $black;

			> a {
				color: $darkGrey;

				&:hover {
					color: $mediumGrey;
				}
			}
		}
	}
}

/**
 * @see https://www.xfive.co/blog/itcss-scalable-maintainable-css-architecture/
 * Styles from Nacre
 */
.react-toast-notifications__container {
	display: flex;
	flex-direction: column;
}
/* Animations to fade the snackbar in and out */
@-webkit-keyframes fadein {
	from {right: 0; opacity: 0;}
	to {right: 30px; opacity: 1;}
}

@keyframes fadein {
	from {right: 0; opacity: 0;}
	to {right: 30px; opacity: 1;}
}

@-webkit-keyframes fadeout {
	from {right: 30px; opacity: 1;}
	to {right: 0; opacity: 0;}
}

@keyframes fadeout {
	from {right: 30px; opacity: 1;}
	to {right: 0; opacity: 0;}
}
.wz-toast {
	padding: 10px;
	border-radius: 5px;
	box-shadow: 0 3px 5px -3px rgba(0, 0, 0, 0.5);
	margin-bottom: 5px;
	background-color: #1e2332;
	color: #FFF;
	width: 300px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	-webkit-animation: fadein 0.5s;
	animation: fadein 0.5s;

	&.wz-toast__dismiss {
		-webkit-animation: fadeout 0.4s;
		animation: fadeout 0.4s;
	}

	.wz-font.wz-font-click:hover {
		@include background-opacity($white, .3);
		color: $white;
	}
	.wz-toast__content {
		display: inline-flex;
	}
	.wz-toast__actions {
		margin-left: 15px;
		align-items: center;
		display: inline-flex;

		.wz-toast__cta {
			cursor: pointer;
			font-weight: 500;
			text-transform: uppercase;
			padding: 6px 5px 4px;
			border-radius: 5px;

			&:hover {
				@include background-opacity($white, .3);
			}
		}
	}
}
.wz-toast__error {
	background-color: #ff5531;
}
.wz-toast__success {
	background-color: #36b37e;
}
i.wz-font.wz-font-margin.wz-toast__icon {
	margin-right: 15px;
}
.wz-toast__title {
	font-weight: 500;

	&.wz-toast__title-regular {
		font-weight: normal;
	}
}
.wz-toast__more {
	margin-top: 5px;
}
.wz-toast__loader-container {
	margin-right: 15px;
	width: 30px;
	height: 30px;
	border-radius: 50%;

	.wz-toast__loader-ring {
		display: inline-block;
		position: relative;
		width: 30px;
		height: 30px;
	}
	.wz-toast__loader-ring div {
		box-sizing: border-box;
		display: block;
		position: absolute;
		width: 30px;
		height: 30px;
		border-width: 3px;
		border-style: solid;
		border-radius: 50%;
		animation: loader-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
		border-color: #fff transparent transparent transparent;
	}
	.wz-toast__loader-ring div:nth-child(1) {
		animation-delay: -0.45s;
	}
	.wz-toast__loader-ring div:nth-child(2) {
		animation-delay: -0.3s;
	}
	.wz-toast__loader-ring div:nth-child(3) {
		animation-delay: -0.15s;
	}
	@keyframes loader-ring {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}
}
.wz-toast__loader {
	background-color: white;
}

i.wz-font.wz-toast__close {
	margin-left: 10px;
}

@media screen and (min-width: $widthMobile) {
	.wz-toast {
	  width: 350px;
	}
}

.wz-message {
	width: 100%;
	background-color: $white;
	border-radius: 5px;
	padding: 15px;
	display: flex;
	flex-direction: row;
	margin-bottom: 10px;

	.wz-message-icon-container {
		flex-grow: 0;

		i.wz-font {
			font-size: 15px;
			margin-top: -2px;
			vertical-align: unset;
		}
	}

	.wz-message-links {
		display: inline-flex;
		flex-direction: column;
		.wz-message-link {
			margin-top: 8px;
			color: $black;
			text-decoration: underline;
			font-weight: 500;
			cursor: pointer;
			user-select: none;
		}
	}

	.wz-message-see-more_container {
		margin-top: 8px;
		.wz-optional-section__label {
			margin-bottom: 0;
			&:hover {
				color: $black;
			}
		}

		&.wz-optional-section_open {
			margin-bottom: 0;
			.wz-optional-section__label {
				margin-bottom: 8px;
			}
		}
	}

	.wz-message-close-container {
		flex-grow: 0;
		margin: 5px;

		i.wz-font {
			font-size: 12px;
			vertical-align: middle;
			color: $black;
			margin: 0;
			position: relative;
			bottom: 8px;
			left: 10px;
		}
	}

	&.wz-message__danger {
		background-color: $backgroundTargetColor;
		border: 1px solid $inputErrorColor;
	}

	&.wz-message__default {
		background-color: $lighterColor;
		border: 1px solid $primaryTicketColor;
	}

	&.wz-message__warning {
		background-color: $backgroundPayColor;
		border: 1px solid $primaryPayColor;
	}

	&.wz-message__success {
		background-color: $backgroundAccessColor;
		border: 1px solid $primaryAccessColor;
	}

	.wz-message-content {
		flex-grow: 15;
		padding-left: 5px;

		.wz-message-title {
			font-weight: 500;
		}

		.wz-message-message {
			white-space: pre-wrap;
			padding-top: 5px;
		}

		.wz-message-actions {
			margin-top: 5px;

			.wz-framebutton.wz-button:not(:last-child) {
				margin-right: 10px;
			}
		}
	}

	&.wz-message__mini {
		.wz-message-title {
			/* 14px */
			font-size: 1rem;
		}

		.wz-message-content {
			/* 12px */
			font-size: .85rem;
		}
	}
}

.wz-comment {
	.wz-comment__quantity {
		font-size: 14px;
		background-color: rgba(var(--primary-color), .2);
		border-radius: 5px;
		padding: 3px 5px;
		float: right;
		color: rgb(var(--primary-color));
		//font-weight: normal;
	}
	i.wz-font.wz-font {
		vertical-align: unset;
		&.wz-font-message {
			color: rgba(var(--primary-color), .4);
		}
	}
	.wz-comment__empty {
		text-align: center;
		margin-bottom: 20px;

		> div {
			font-weight: 500;
			font-size: 1.15em;
		}
	}
	.wz-contentcard  .wz-headercard-separator {
		margin: 15px -15px 12px;
	}
	.wz-comment__show-more {
		cursor: pointer;

		&:hover {
			text-decoration: underline;
		}

		.wz-comment__items-count {
			margin-left: 5px;

			i.wz-font {
				position: relative;
				top: 2px;
				margin-left: 5px;
			}
		}
	}
	.wz-comment__line {
		.wz-comment__content {
			overflow: hidden;
		}
		.wz-comment__line-header {
			width: 100%;
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 10px;
			.wz-comment__line-header-content {
				> i.wz-font-message {
					font-size: 1.1em;
				}
				.wz-comment__line-header-user {
					font-weight: 500;
					position: relative;
					top: -2px;
					padding-left: 5px;
					padding-right: 5px;
				}
				.wz-comment__line-header-date {
					position: relative;
					top: -2px;
					color: $greyFourColor;
					font-size: .8em;
					.wz-tooltip {
						margin-left: 5px
					}
				}
			}
			.wz-popin__container {
				margin-top: -5px;
				&:hover {
					text-decoration: underline;
				}
			}
		}
		.wz-comment__show-more-text {
			.wz-comment__next {
				margin-left: 5px;
				cursor: pointer;
				color: rgb(var(--primary-color));
				&:hover {
					text-decoration: underline;
				}
			}
		}
	}
}


.wz-anchor {
	width: calc(100% + #{$paddingWZContent});
	background-color: $white;
	display: inline-flex;
	padding: 0 5px;
	position: sticky;
	top: 100px;
	z-index: 4;
	margin-bottom: 15px;

	&.wz-anchor-menu {
		overflow-x: auto;
		-ms-overflow-style: none;
		scrollbar-width: none;
		&::-webkit-scrollbar {
			display: none;
		}
	}

	&.wz-anchor__fixed {
		@include box-shadow(10px 0 6px 0 $blackAlpha02);
	}

	ul {
		display: inline-flex;
		list-style-type:none;
		padding:0;
		margin:0;

		li.wz-anchor-item {
			list-style:none;
			background-image:none;
			background-repeat:none;
			background-position:0;
			padding: 10px 5px;
			align-content: center;
			display: flex;

			a.wz-anchor-item__link {
				padding: 5px 5px;
				border-radius: 5px;
				line-height: 1.3em;
				white-space: nowrap;
			}

			&.wz-anchor-active {
				border-bottom: 1px solid $primaryColor;
				a.wz-anchor-item__link {
					color: $primaryColor;
				}
			}

			&:hover {
				a.wz-anchor-item__link {
					background-color: $alphaColor;
					color: $primaryColor;
				}
			}

			a {
				color: $black;
			}
		}
	}
}

@media screen and (min-width: $widthTablet) {
	.wz-anchor {
		width: calc(100% + #{$paddingWZContentDesktop});

	}
}

.column-header {
	&--sortable {
		cursor: pointer;
	}
}

.sorting-icon {
	transition: ease-out transform 0.2s;
	margin-left: 10px;

	&--reversed {
		transform: rotate(180deg);
	}
}

.wz-table td, .wz-table th {
	&.column-align-left {
		text-align: left;
	}

	&.column-align-right {
		text-align: right;
	}

	&.column-align-center {
		text-align: center;
	}
}

.wz-accordion {
	border-radius: 5px;
	border: 1px solid #ced0da;
	margin-bottom: 10px;
	overflow: hidden;
	background-color: #FFF;

	.wz-accordion__title {
		background-color: rgb(var(--lighter-color));
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 11px 15px;
		font-weight: 500;
		cursor: pointer;
		user-select: none;

		i.wz-font.wz-font-chevron-bottom {
			&:before {
				position: relative;
				top: 1px;
			}
		}
		.wz-accordion__open-icon {
			-webkit-transform: rotate(180deg);
			transform:rotate(180deg);
			transition: transform 250ms ease-in-out;
		}
	}
	&.wz-accordion__disabled .wz-accordion__title{
		cursor: default;
	}
	.wz-accordion__children {
		padding: 12px 15px;
		display: none;
		opacity: 0;
		transition: all 250ms linear;

		&.wz-accordion__open {
			display: block;
			opacity: 1;
		}
		.wz-section {
			margin: 0;
		}
	}
}

.wz-split-container {
	display: block;

	&.wz-split-container__only-desktop .wz-split-container__additional {
		display: none;
	}

	.wz-split-container__content {
		&.wz-split-container__content-wo-additional {
			max-width: 100%;
		}
		.wz-card {
			margin-bottom: 15px;
		}
	}
}

@media screen and (min-width: 800px) {
	.wz-split-container {
		display: flex;

		&.wz-split-container__fixed {
			.wz-split-container__content {
				padding-right: 350px;
			}
			.wz-split-container__additional {
				position: fixed;
				right: 0;
				min-width: 350px;
				max-width: 350px;
				padding-right: $paddingWZContentDesktop;

				.wz-contentcard {
					@include scrollbar();
					max-height: 60vh;
					overflow: auto;
				}
			}
			&.wz-split-container__maxi {
				.wz-split-container__additional {
					min-width: 425px;
					max-width: 425px;
				}

				.wz-split-container__content{
					padding-right: 425px;
				}

			}
		}

		&.wz-split-container__only-desktop {
			.wz-split-container__additional {
				display: block;
			}
		}
		.wz-split-container__content {
			flex: 1 1 750px;
		}
		.wz-split-container__additional {
			margin-left: 20px;
			width: 350px;
		}
		&.wz-split-container__maxi {
			.wz-split-container__content {
				flex: 1 1 675px;
			}
			.wz-split-container__additional {
				width: 425px;
			}
		}
	}
}

.wz-tooltip {
	display: inline-flex;
	user-select: none;
	&:not(.wz-tooltip-not-visible) {
		cursor: pointer;
	}
	margin: 0 10px;
	-webkit-tap-highlight-color: transparent;
}

.wz-tooltip-without-margin {
	display: inline-flex;
	user-select: none;
	&:not(.wz-tooltip-not-visible) {
		cursor: pointer;
	}
	margin: 0;
	-webkit-tap-highlight-color: transparent;
}

.wz-tooltip .wz-popin__arrow::before, .wz-tooltip-without-margin .wz-popin__arrow::before {
	background-color: $greySixColor;
	animation: fadeIn ease-in-out 0.5s;
}

.wz-tooltip .wz-popin {
	z-index: 6;
}

.wz-tooltip__content {
	@include border-radius($borderRadiusCommonWidth);
	width: max-content;
	max-width: 250px;
	color: $white;
	z-index: 300;
	padding: 8px 10px;
	font-size: 12px;
	cursor: default;
	line-height: 1.5;
	background-color: $greySixColor;
	box-shadow: 0 3px 5px -3px rgba(0, 0, 0, 0.5);
	animation: fadeIn ease-in-out 0.5s;

	&.wz-tooltip__mini {
		font-size: 11px;
		padding: 3px 5px;
	}

	.wz-tooltip__more {
		display: block;
		margin-top: 2px;

		a {
			color: $white;
			text-decoration: underline;
			font-weight: bold;
		}
	}
}

/* TooltipHelp */
.wz-tooltip-help {
	margin: 0 7px;
	font-weight: normal;
}

.wz-tooltip-help-icon {
	background: url('./icons/help.svg');
	display: inline-block;
	width: 13px;
	height: 13px;
	position: relative;
	top: 2px;
}

@keyframes fadeIn {
	0% { opacity: 0; }
	100% { opacity: 1; }
}


.wz-card {
  @include transition-element(border $transitionBorder ease);
  background-color: $white;
  border-color: $borderGreyColor;
  border-style: solid;
  border-width: 1px;
  margin-bottom: 15px;
  position: relative;

  &__active,
  &__active_by_dropdown {
    border-color: rgb(var(--primary-color));
    border-style: solid;
    border-width: 1px;

    .wz-headercard.wz-headercard__link {
      color: rgb(var(--medium-color));
    }
  }

  // Override wz-headertablecard to have the correct transition
  &.wz-headertablecard,
  &.wz-card__active.wz-headertablecard,
  &.wz-card__active_by_dropdown.wz-headertablecard {
    @include transition-element(border $transitionBorder ease);
  }

  // Start HeaderCard
  .wz-headercard {
    color: $greySixColor;
    font-size: $mediumFontSize;
    padding: 15px;

    &__link {
      @include transition-element(color $transitionColor ease);
      cursor: pointer;

      &:hover .wz-titlecard {
        color: rgb(var(--medium-color));
      }
    }

    .wz-titlecard {
      @include transition-element(color $transitionColor ease);
      font-size: 16px;
      font-weight: 500;

      i {
        margin-left: 10px;
      }

      &__link:hover {
        color: rgb(var(--medium-color));
        cursor: pointer;
      }
    }
  }

  .wz-headercard-separator {
    background-color: $greyOneColor;
    height: 1px;
    margin: 0;
    opacity: .18;
    z-index: 1;
  }
  // END HeaderCard

	// START OnboardingCard
	&.wz-onboardingcard {
    border-radius: 5px;
    border: 1px solid #CED0DA;

		.wz-titlecard {
			font-size: 14px;
			font-weight: 500;
		}
		.wz-subtitlecard {
      margin: 0 5px 15px 5px;
		}
    .wz-headercard {
      padding: 0;
    }
    .wz-onboardingcard-content {
      padding: 10px 15px 0 15px;
    }
    .wz-onboardingcard-header {
      padding: 8px 15px;
      background-color: $lighterColor;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }
    .wz-onboardingcard-title {
      display: flex;
      align-items: center;
      i {
        margin-right: 8px;
        margin-left: 5px;
      }
    }
    .wz-onboardingcard-sections {
      padding: 15px 15px 0;
    }
	}
	// END OnboardingCard

  // START SectionCard
	.wz-sectioncard {
    background-color: #FAFAFA;
    margin-bottom: 6px;
    border-radius: 5px;
    &:last-child {
      margin-bottom: 0;
    }
		.wz-sectioncard-title-container {
			display: flex;
			margin-bottom: 2px;
			flex-direction: row;
			justify-content: space-between;

			i.icon {
				margin: 0;
				font-size: 15px;
				color: $textDescription;

				&.check {
					color: rgb(var(--primary-color));
				}
			}

      .wz-sectioncard-subcontent{
        display: inherit;
        margin-left: 10px;
      }
		}
		.wz-sectioncard-content {
			color: $textDescription;
			font-size: 0.8em;
		}

    .wz-sectioncard_chevron {
      visibility: hidden;
      position: relative;
      left : 5px;
    }

    &_clickable{
      display: block;
      cursor: pointer;
      color : inherit;
      .wz-sectioncard_chevron {
        visibility: visible;
      }
      &:hover{
        color: rgb(var(--primary-color));
      }
    }
	}
  // END SectionCard

  // Start BulletCard
  .wz-bulletcard {
    float: left;
    margin-right: 10px;
    padding-top: 1px;

    &.wz-bulletcard__active:hover {
      cursor: pointer;
    }

    &.green {
      &.wz-bulletcard__active:hover .wz-bulletcard-circle {
        background-color: $greenDarkerStatusColor;
      }

      .wz-bulletcard-circle {
        background-color: $greenStatusColor;
      }
    }

    &.orange {
      &.wz-bulletcard__active:hover .wz-bulletcard-circle {
        background-color: $orangeDarkerStatusColor;
      }

      .wz-bulletcard-circle {
        background-color: $orangeStatusColor;
      }
    }

    &.red {
      &.wz-bulletcard__active:hover .wz-bulletcard-circle {
        background-color: $redDarkerStatusColor;
      }

      .wz-bulletcard-circle {
        background-color: $redStatusColor;
      }
    }

    &.wz-bulletcard__active:hover .wz-bulletcard-circle {
      background-color: $darkGrey;
    }

    .wz-bulletcard-circle {
      @include transition-element(background $transitionColor ease);
      @include border-radius(15px);
      background-color: $greyStatusColor;
      height: 15px;
      width: 15px;
    }
  }
  // END BulletCard

  // Start SubTitleCard
  .wz-subtitlecard {
    color: $greyFourColor;
    font-size: $normalFontSize;
    margin-top: 2px;
  }
  // END SubTitleCard

  // Start DropdownCard
  .wz-dropdowncard {
    //position: absolute;
    right: 5px;
    bottom: 19px;
    margin-left: auto;
  }
  // END DropdownCard

  // START ToggleCard
  .wz-toggle-card {
    &:last-child {
      margin-bottom: 0;
    }
    display: block;
    box-sizing: border-box;
    background: #FFF;
    box-shadow: 0px 1px 2px 0 rgb(34 36 38 / 15%);
    margin-bottom: 15px;
    padding: 15px;
    border-radius: 5px;
    border: 1px solid #ced0da;

    .wz-section {
      *:first-child {
        margin-top: 0;
      }
      *:last-child {
        margin-bottom: 0;
      }
      .wz-subsection {
        margin: 0;
      }
      .wz-section {
        margin: 0;
      }
      margin: 15px 0 0;
    }

    .wz-toggle-container {
      &__title-container {
        width: 100%;
        justify-content: space-between;
        font-size: 14px;
        margin-right: 0px;
      }

      &__extra {
        margin: 15px 0 0 0;
        .wz-section {
          margin: 0;
        }
        .wz-optional-section_open {
          margin-bottom: 0;
        }
      }

      &__label {
        margin-bottom: 0px;

        .wz-input-switch {
          margin-left: 20px;
        }
        @media screen and (max-width: 400px) {
          .wz-input-switch {
            margin-left: 0;
          }
        }
      }
    }

    .wz-toggle-card-title__content {
      margin-top: 15px;
    }

    .wz-toggle-card-title {
      display: flex;
      justify-content: space-between;

      &__container {
        display: block;
        margin-right: 0px;
      }

      &__description {
        color: grey;
        display: flex;
        margin: 10px 10px 0px 0px;
      }

      &__logo-container {
        display: flex;
        justify-content: flex-end;
        right: 0;

        img {
          display: block;
          object-fit: contain;
          max-width: 80px;
          max-height: 40px;
          margin-left: 10px;
        }
      }
    }
  }

  // END ToggleCard

  // Start ContentCard
  .wz-contentcard {
    padding: 15px;
    position: relative;
  }
  // END ContentCard

  // Start LoaderCard
  .wz-loadercard {
    position: absolute;
    right: 28px;
    top: 15px;
    z-index: 5;
  }
  // END LoaderCard

  // Start NumberCard
  .wz-numbercard {
    color: rgb(var(--medium-color));
    font-size: $bigFontSize;
  }
  // END NumberCard

  // Start tagCard
  .wz-tagcard {
    margin-bottom: 10px;

    &-data {
      font-size: $mediumFontSize;
      font-weight: 600;
      max-width: 90px;
      width: 30%;
    }

    &-tag {
      float: right;
      font-size: $normalFontSize;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: calc(100% - 115px);
    }
  }
  // END tagCard

  // Start PanelCard
  .wz-panelcard {
    margin-bottom: 10px;

    &-cta {
      color: $greyThreeColor;
      cursor: pointer;
      font-size: $normalFontSize;

      &:hover {
        color: $greyFourColor;
      }
    }

    &-content {
      margin-top: 20px;
      position: relative;
    }
  }
  // END PanelCard

  // Start LabelsCardContainer
  .wz-labelscardcontainer .wz-labelcard,
  .wz-labelscardcontainer .wz-variationcard {
    @include transition-element(background $transitionBorder ease);
    font-size: $normalFontSize;
    margin-right: 20px;
    margin-top: 10px;
    position: relative;

    i {
      margin-right: 8px;
    }

    &:last-child {
      margin-right: 0;
    }

    &:not(.wz-labelcard__disabled).wz-labelcard__link {
      cursor: pointer;

      &:hover {
        background-color: rgb(var(--darker-color));
      }
    }

    &.wz-labelcard__link.wz-labelcard__inverted {
      @include transition-element(border $transitionBorder ease);
      @include transition-element(color $transitionBorder ease);

      &:hover {
        background-color: $white;
        border-color: rgb(var(--darker-color));
        color: rgb(var(--darker-color));
      }
    }

    &.wz-labelcard__disabled {
      background-color: $greyTwoColor;
      border-color: $greyTwoColor;
    }

    &__loading {
      @include border-radius(8px);
      background-color: $blackAlpha02;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;

      .ui.loader:before {
        border-color: $white;
      }
    }
  }
  // END LabelsCardContainer

  // Start LabelCard
  .wz-labelcard {
    @include border-radius(8px);
    background-color: rgb(var(--primary-color));
    border-color: rgb(var(--primary-color));
    border-style: solid;
    border-width: 1px;
    color: $white;
    cursor: default;
    display: inline-block;
    padding: 8px 12px;

    &.wz-labelcard__inverted {
      background-color: $white;
      color: rgb(var(--primary-color));
      margin-right: 8px;
      padding: 4px 8px;
    }
  }
  // END LabelCard

  // Start VariationCard
  .wz-variationcard {
    display: inline-block;
    font-size: $mediumFontSize;
    padding: 8px 12px;

    &.wz-variationcard__minus {
      color: $redStatusColor;
    }

    &.wz-variationcard__equal {
      color: $orangeStatusColor;
    }

    &.wz-variationcard__max {
      color: $greenStatusColor;
    }

    &-sign {
      margin-right: 3px;
    }
  }
  // END VariationCard
}

// START HeaderTableCard
.wz-headertablecard {
  @include transition-element(color $transitionBorder ease);
  color: $greyFourColor;

  &.wz-headertablecard__valid {
    color: $greyFiveColor;
  }

  .wz-titlecard {
    font-size: $normalFontSize;
  }

  // For the icon of un/validate
  .wz-commonvalidateicon {
    float: left;
    margin-right: 6px;
  }

  // Dropdown
  .wz-dropdowncard {
    top: 16px;
  }

  .wz-dropdowncard-list {
    margin: 8px 0;
  }
}

@media screen and (min-width: $widthTablet) {
  .wz-card.wz-headertablecard .wz-dropdowncard-list {
    right: 12px;
  }
}
// END HeaderTableCard

// START BackMenu
.wz-back-menu-container {
	padding: 10px 6px;

  .wz-popin__container .wz-back-menu {
      position: relative;
      font-size: 12px;
      display: flex;
      cursor: pointer;
      padding: 6px 8px;
      @include transition-element(background $transitionColor ease);
      @include border-radius(5px);

      .wz-back-menu__cta {
        /* fill parent to allow click anywhere in the hover-able part */
        width: 100%;
        height: 100%;

        .wz-back-text {
          /* fix font color if cta is a `a` */
          color: $fontColor;
        }

        > i {
          font-size: .6em;
        }
      }

      &:hover , &.open {
          background-color: rgb(var(--alpha-color));

          .wz-back-menu__cta {
              .wz-back-text {
                  color: rgb(var(--primary-color));
              }
              > i {
                  background-color: rgb(var(--primary-color));
              }
          }
      }
      .wz-back-menu__cta {
          display: flex;

          .wz-back-text {
              display: flex;
              flex-direction: column;
              padding-left: 5px;

              .wz-back-text__children:not(:first-child) {
                  padding-top: 4px;
              }
          }
      }
  }

  .wz-popin .wz-back-dropdown__title {
    margin-bottom: 5px;
    display: block;
  }
  .wz-popin .wz-dropdowncommonitem {
    .wz-item {
      display: flex;
      padding: 8px 5px;

      i {
        margin-right: 8px;
      }
    }
  }
}
// END BackMenu

//START Switch Menu
.wz-switch-menu__container {
  display: inline;
  .wz-commondropdown-list.wz-commondropdown-list__medium {
    width: 100%;
    min-width: 230px;
  }
  .wz-switch-menu__items {
    max-height: 150px;
    overflow: auto;

    .wz-item {
      font-size: 12px;
    }

    &::-webkit-scrollbar {
      width: 2px;
      height: 2px;
    }
  }
  .wz-switch-menu__header {
    font-weight: 500;
    font-size: 12px;

    &.wz-dropdowncommonitem.wz-dropdowncommonitem-title h1 {
      font-weight: 500;
      font-size: 12px;
    }
  }
  .wz-switch-menu__footer {
    font-size: 12px;

    &.wz-dropdowncommonitem:not(.wz-dropdowncommonitem__disabled):hover {
      color: rgb(var(--primary-color));
      background-color: transparent;
    }

    .wz-item:hover {
      background-color: transparent;
    }
  }
}

.wz-switch-menu {
	position: relative;
	margin-top: 10px;

	&:hover:not(.wz-select-menu__active) > div {
		.wz-input {
			border-color: rgb(var(--primary-color));
		}
	}

	&.wz-select-menu__active {
		> .wz-ui-input .wz-input {
			@include box-shadow(0 2px 5px -2px rgb(var(--primary-color)));
			border-color: rgb(var(--primary-color));
		}
	}

	.wz-ui-input, .wz-input-value {
		cursor: pointer;
		user-select: none;
	}
	input.wz-input {
		cursor: pointer;
	}

	.wz-input-value {
		margin: 13px 30px 13px 12px;
		position: absolute;
		top: 0;
		flex: 1;
		align-items: center;
		font-size: 12px;
		@include line-clamp(1);
	}

	&.wz-switch-menu__context {
		&:hover > div {
			.wz-input {
				@include transition-element(background $transitionBorder ease);
				@include background-opacity(var(--medium-color), 0.13);
				box-shadow: none;
			}
		}
		.wz-ui-input {

			&:hover {
				.wz-input {
					@include transition-element(background $transitionBorder ease);
					@include background-opacity(var(--medium-color), 0.13);
					box-shadow: none;
				}
			}
			.wz-input {
				border: 0;
				background-color: transparent;

				&:focus {
					@include box-shadow(none)
				}
			}
		}
		&.wz-select-menu__active {
			.wz-input {
				@include transition-element(background $transitionBorder ease);
				@include background-opacity(var(--medium-color), 0.13);
			}
		}
		.wz-input-icon {
			color: $black;
		}
		.wz-input-value {
			font-size: $mediumFontSize;
			font-weight: 500;
		}
	}
}
// END Switch Menu
// START ContextMenu
.wz-context-menu {
  background-color: rgb(var(--lighter-color));
  margin-bottom: inherit;

  .wz-context-menu__title {
    font-size: 18px;
    @include line-clamp(2);
    margin-bottom: 12px;
  }

  .wz-context-menu__status-container {
    margin-bottom: 12px;
    display: flex;
    .wz-status {
      padding: 3px 5px 1px;
    }
  }

  .wz-headercard{
    padding: 11px 15px 14px;
  }

  .wz-context-menu__container-subtitle {
    display: block;
    .wz-subtitle {
      margin-bottom: 4px;
      font-weight: normal;
      &:last-child {
        margin-bottom: 0;
      }
    }
    margin-bottom: 5px;
  }

  .wz-context-menu__catchphrase {
      background-color: rgba(var(--primary-color), 5%);
      margin: -12px -16px 10px;
      padding: 10px 15px;
      color: #000;
      font-weight: normal;
      font-size: 14px;
      letter-spacing: .7px;
      user-select: none;
  }

  .wz-commondropdown {
      background-color: #FFF;
  }

  .wz-icon-button-icon {
	position: absolute;
	right: 10px;
    top: 9px;
    display: inline-block;
    margin-left: 10px;
  }

  .normal-font-size {
    font-size: $normalFontSize;
  }

  // For the select
  // We override style of semantic until we create our own select/option
  .ui.selection.dropdown {
    font-size: $normalFontSize;
    line-height: 13px;
    margin-top: 14px;
    max-height: 30px;
    min-height: inherit;
    min-width: inherit;
    padding: 9px 10px;
    width: 100%;
  }

  .wz-link-with-actions {
	margin-bottom: 5px;
	margin-left: -5px;

    .wz-popin__container {
      display: inline-grid;
    }

	span.external-icon > i{
	  font-size: 10px;
	}

	span.wz-copytoclipboard-link > i {
	  font-size: 10px;
	  padding: 10px;
	}
  }

  & .wz-context-menu-info {
    margin-bottom: 10px;
  }

  & .wz-context-menu-content {
    margin-top: 10px;
  }

  &.wz-context-menu__with-dropdown {
    &:not(.wz-copytoclipboard-link) {
      &.wz-tooltip:not(.wz-link-tooltip) {
        display: block;
        margin: 0 0 1em 0;
      }
    }

	.wz-titlecard {
	  display: flex;

	  .wz-framebutton {
		width: 100%;
		margin-right: 50px;
	  }

	  .wz-commondropdown-list {
	    right: -10px;
        width: 227px
	  }

		.wz-commondropdownframe {
			position: absolute;
			top: auto;
			right: 10px;

			.wz-commondropdown {
				right: 25px;
				border: solid 1px $blackAlpha02;
				height: 39px;
				width: 39px;

				.wz-commondropdown__selectors {
					margin-top: 16px;
					margin-left: 16px;
				}

				.wz-commondropdown__selectors, .wz-commondropdown__selectors::before, .wz-commondropdown__selectors::after {
					background-color: $black;
				}

				&:hover, &.wz-commondropdown__active {
					.wz-commondropdown__selectors, .wz-commondropdown__selectors::before, .wz-commondropdown__selectors::after {
						background-color: rgb(var(--primary-color));
					}
				}
			}
		}
	}
  }
}
// END ContextMenu

//FilterSetController Start
// FilterSetView
.wz-filter-set-view__container {
  display: flex;
  margin-bottom: 15px;
  flex-wrap: wrap;
  flex-direction: row;
}

.wz-filter-set-view {
  width: 60%;
}

.wz-filter-set-actives {
  width: 100%;
}

.wz-filter-set__total_filters {
  flex: 1 0 auto;
  text-align: right;
}

// Disable dropdown container
.wz-filter-set-view__container {
  .wz-filter-set-view{
    .wz-searchfilterscard-container{
      .wz-popin__container{
        .wz-label__disabled{
          cursor: not-allowed;
        }
      }
    }
  }
}

.wz-filter-set-actives {
  // Separate all the active labels
  .wz-popin__container {
    .wz-label {
      cursor: pointer;
      margin-right: 10px;
    }

    .wz-label.wz-label__disabled{
      cursor: not-allowed;
    }

    &:last-child .wz-label {
      margin-right: inherit;
    }

    .wz-pellet {
      margin-left: 1em;
    }
  }
}

//TotalFilters
.wz-total-filters {
  font-size: 24px;
  color: rgb(var(--primary-color));
  margin-left: 15px;
  margin-top: 10px;
}

.wz-total-filters__count {
  margin-top: 10px;
  font-weight: bold;
  margin-right: 5px;
}

.date-filter-container {
  .wz-date-time-container {
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 10px;
      .wz-date-container {
        margin-right: 20px;
        &.wz-date-container-birthdate {
          margin-right: 0;
          width: 100%;
        }
      }
      .wz-popin__container {
        width: 100%;
        display: block;
        margin-top: 5px;
    }
    .wz-tooltip .wz-popin__container {
      margin-top: 0;
    }
  }
}
.wz-block-scroll.wz-filter-multi, .wz-block-scroll.wz-filter-hierarchy, .wz-block-scroll.wz-filter-simple, .wz-block-scroll.wz-filter-date {
  overflow-x: hidden;
}

.wz-block-scroll.wz-filter-hierarchy .wz-hierarchy__container {
  padding: 0;
}

.wz-block-scroll.wz-filter-date__scroll-block {
  height:232px;
  overflow-y: hidden;
}


.wz-block-scroll.wz-filter-form__scroll-block {
	height:278px;
}

.wz-dropdowncommonitem-title.wz-filter-form__section-title {
  margin-bottom: 5px;
}


//ActiveFilter
.wz-active-filter {
  margin-top: 10px;
}
.wz-label__inverted {
    .wz-pellet {
      border-radius: 4px;
    }
}

/* RESPONSIVENESS FilterSet / TotalFilters */
@media screen and (max-width: $widthTablet) {
  .wz-filter-set-view {
    flex: 1 0 auto;
  }
}

/* RESPONSIVENESS FilterSet / TotalFilters */
@media screen and (max-width: $widthMobile) {
  .wz-filter-set-view__container {
    flex-direction: column;
    align-items: center;
  }

  .wz-filter-set-view {
    width: 100%;
  }

  .wz-total-filters {
    margin-left: 0;
  }

  .wz-filter-set__total_filters {
    order: 3;
    text-align: center;
  }
}
//FilterSetController End

// For SearchFiltersCard
.wz-searchfilterscard-container {
  display: flex;

  .wz-searchfilterscard-container__left-component {
    margin-right: 10px;
  }

  // Label dropdown
  .wz-popin__container .wz-label {
    cursor: pointer;
    margin-right: 10px;
  }
}

// Gather the TotalResul and the Reset action
.wz-searchfilterscard-texts {
  display: inline;
  .wz-total-result,
  .wz-reset {
    display: inline-flex;
    margin-right: 24px;
    margin-top: 10px;
  }
}

// Hide/show the content (active filters)
.wz-searchfilterscard-actives {
  //@include toggle-content();
  margin: 10px 0 0;

  // Separate all the active labels
  .wz-popin__container {
    .wz-label {
      cursor: pointer;
      margin-right: 10px;
    }

    &:last-child .wz-label {
      margin-right: inherit;
    }

    .wz-pellet {
      margin-left: 1em;
    }
  }
}

// Start EmptyState
.wz-empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  padding: 15px;
}
.wz-empty-state__icon {
  font-size: 1.8em;
  color: $primaryColor;
  opacity: 0.5;
  margin-bottom: 12px;

}
.wz-empty-state__title {
	font-weight: 500;
  font-size: 1.15em;
  margin-bottom: 12px;
  max-width: 450px;
  text-align: center;
}
.wz-empty-state__subtitle {
  color: $inputDisabledGrey;
  margin-bottom: 12px;
  max-width: 450px;
  text-align: center;
}
// End EmptyState

// PreviewCard//
.wz-preview-card {
  .wz-sub-card__header {
    i.wz-font {
      margin-right: 0;
      &:before {
        font-size: 0.7em;
      }
    }
  }
  .wz-preview-card__content {
    padding: 0;
  }
}
//PreviewCard

//SubCard//
.wz-sub-card {
  border-radius: 5px;
  border: solid 1px #ced0da;
  background-color: white;
  overflow: hidden;
}

.wz-sub-card__box-shadow {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
}

.wz-sub-card__header {
  display: flex;
  padding: 12px 15px;
  font-weight: 500;
  user-select: none;
  .wz-sub-card__title {
    display: inline-flex;
    &.wz-sub-card__title_ellipsis {
      width: 100%;
      align-items: center;
    }
    &.wz-sub-card__title_clickable:hover {
      color: rgb(var(--primary-color));
      cursor: pointer;
    }
    .wz-sub-card__title-text-ellipsis {
      display: block;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
  .wz-sub-card__title-text-ellipsis {
    display: block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}

.wz-sub-card__icon-container {
  margin-right: 10px;
}

.wz-sub-card__content {
  padding: 12px 15px;
}

// CardRow.IconButton

.wz-icon-button.wz-button {
	margin-right: 10px;
	padding: 11px 16px 9px;
	color: black !important;
  width: 45px;
  .wz-font.wz-font-regular {
    font-size: 1.5em;
  }
  .wz-framebutton__label {
    display: none !important;
  }
}

// End CardRow.IconButton

// CardRow

.wz-card-row {
  display: flex;
  justify-content: space-between;
	border-radius: 5px;
	border: solid 1px #ced0da;
	padding: 14px 20px 14px 14px;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
	background-color: #fff;
}
.wz-card-row-right {
  flex-grow: 1;
}
.wz-card-row-left-image {
    margin-right: 20px;
    border-radius: 5px;
    width: 100px;
    height: 100px;
    overflow: hidden;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
@media screen and (max-width: 600px) {
	.wz-card-row {
		display: block;
		text-align: center;
	}
	.wz-card-row-left {
		display: flex;
		justify-content: center;
	}
	.wz-card-row-right {
    display: flex;
    align-items: center;
		flex-direction: column;
		justify-content: center;
  }
  .wz-card-row-left-image {
    display: none;
  }
}

// End CardRow

// EmptyCard

.wz-empty-card__container {
  background-color: rgb(var(--lighter-color));
  border: 1px solid #7C95F9;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  overflow-y: auto;
  min-height: 220px;
  .wz-empty-card__content {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .wz-empty-card__image {
      max-width: 400px;
    }
    .wz-empty-card__icon {
      font-size: 1.4em;
      color: rgb(var(--primary-color));
    }
    .wz-empty-card__title {
      font-size: 20px;
      font-weight: 500;
      margin-top: 10px;
      text-align: center;
    }

    .wz-empty-card__subtitle {
      margin-top: 15px;
      text-align: center;
    }

    .wz-empty-card__cta {
      margin-top: 15px;
      .wz-framebutton:not(:first-child) {
        margin-left: 5px;
      }
    }
  }
}


@media screen and (max-width: 600px) {
  .wz-empty-card__container {
    .wz-empty-card__content {
      width: 100%;
      .wz-empty-card__title {
        font-size: 20px;
      }
      .wz-empty-card__image {
        width: 100%;
      }
      .wz-empty-card__cta {
        display: flex;
        flex-direction: column;
        width: 100%;
        .wz-framebutton:not(:first-child) {
          margin-top: 5px;
          margin-left: 0;
        }
      }
    }
  }
}

// End EmptyCard

// ErrorCard

.wz-error-card__container {
  height: calc(100vh - 70px);/* 70px = 50px(Topbar) + 20px (margin-bottom) */
  margin-bottom: -15px;
  padding:20px 0 0;
  .wz-error-card__subcontainer {
    background-color: rgb(var(--lighter-color));
    border: 1px solid #7C95F9;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 3px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #ced0da;
    }
    &::-webkit-scrollbar-thumb:hover {
      background-color: #878c9b;
    }
    &::-webkit-scrollbar-track {
      background-color: transparent;
    }
    .wz-error-card__content {
      padding: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .wz-error-card__image {
        max-width: 400px;
      }
      .wz-error-card__title {
        font-size: 22px;
        font-weight: 500;
        margin-top: 15px;
        text-align: center;
      }

      .wz-error-card__subtitle {
        margin-top: 15px;
        text-align: center;
      }

      .wz-error-card__button-container {
        margin-top: 15px;
        .wz-error-card__button-label .wz-font.wz-font-regular {
          margin: 0 0 0 7px;
        }
      }
    }
  }
}


@media screen and (max-width: 600px) {
  .wz-error-card__container {
    .wz-error-card__subcontainer {
      .wz-error-card__content {
        .wz-error-card__title {
          font-size: 20px;
        }
        .wz-error-card__image {
          width: 100%;
        }
        .wz-error-card__button-container {
          display: flex;
          flex-direction: column;
          width: 100%;
          .wz-error-card__button {
            width: 100%;
            margin-right: 0;
            margin-bottom: 15px;
            &:last-child {
              margin-bottom: 0;

            }
          }
        }
      }
    }
  }
}

@media screen and (max-height: 600px) {
  .wz-error-card__container {
    .wz-error-card__content {
      position: relative;
      top: 20px;
    }
  }
}

@media screen and (max-height: 400px) {
  .wz-error-card__container {
    .wz-error-card__content {
      position: relative;
      top: 60px;
    }
  }
}

/* ErrorCard.Contact */
.wz-error-card-contact__container {
  margin-top: 15px;
  .wz-error-card-contact__mail {
    margin-left: 7px;
    color: rgb(var(--primary-color));
    &:hover {
      color: rgb(var(--primary-color));
      text-decoration: underline ;
    }
  }
}

// End ErrorCard

/**
 * @see https://www.xfive.co/blog/itcss-scalable-maintainable-css-architecture/
 * Styles from Nacre
 */

// Generic
$white: #fff;
$black: #000;
$lightGrey: #f5f5f5;
$darkGrey: #aaa;
$borderGreyColor: #f0f5f9;

// Customs
$mediumGrey: #404041;
$darkerLightGrey: #ced0da;
$inputDisabledGrey: #878c9b;

// Template
// Colors
$backgroundColor: $lightGrey !default;
$fontMenuColor: $white !default;
$fontColor: #0f0f14 !default;

$primaryColor: #0032fa !default;
$darkerColor: #003cd3 !default;
$mediumColor: #0033b2 !default;
$lighterColor: #F5F7FF !default;
$alphaColor: #D9E1F3 !default;
$gradientColor: #5198ff !default;

$compatColors: (
	"primary": $primaryColor,
	"darker": $darkerColor,
	"medium": $mediumColor,
	"alpha": $alphaColor,
	"lighter": $lighterColor,
	"gradient": $gradientColor,
);
:root {
	@each $name, $color in $compatColors {
		--#{$name}-color: #{red($color), green($color), blue($color)};
	}
}

$ticketColors: (
	"primary": #0032fa,
	"darker": #003cd3,
	"medium": #0033b2,
	"alpha": #D9E1F3,
	"lighter": #F5F7FF,
	"gradient": #5198ff,
);
.weezticket {
	@each $name, $color in $ticketColors {
		--#{$name}-color: #{red($color), green($color), blue($color)};
	}
}

$accessColors: (
	"primary": #2dd2be,
	"darker": #29b7a2,
	"medium": #2ec6ac,
	"alpha": #e4efe8,
	"lighter": #F4FDFC,
	"gradient": #51FFD9,
);
.weezaccess {
	@each $name, $color in $accessColors {
		--#{$name}-color: #{red($color), green($color), blue($color)};
	}
}

$payColors: (
	"primary": #FFCC33,
	"darker": #E8B212,
	"medium": #FFE083,
	"alpha": #FFF8E4,
	"lighter": #FFFEF5,
	"gradient": #FFE551,
);
.weezpay {
	@each $name, $color in $payColors {
		--#{$name}-color: #{red($color), green($color), blue($color)};
	}
}

$targetColors: (
	"primary": #fa3e4e,
	"darker": #cc3648,
	"medium": #e53c51,
	"alpha": #f7ebed,
	"lighter": #FFF5F6,
	"gradient": #FF5151,
);
.weeztarget {
	@each $name, $color in $targetColors {
		--#{$name}-color: #{red($color), green($color), blue($color)};
	}
}

$greenStatusColor: #28CA8B;
$greenDarkerStatusColor: #1fa26f;
$orangeStatusColor: #F18F20;
$orangeDarkerStatusColor: #d37d1c;
$greyStatusColor: #c8cddc;
$redStatusColor: #eb1427;
$redDarkerStatusColor: #dd1224;

// From lighter to darker
$greyOneColor: #dce1f0;
$greyTwoColor: #c8cddc;
// The 2 fonts above are used the most
$greyThreeColor: #a5aab9;
$greyFourColor: #878c9b;
$greyFiveColor: #555a69;
$greySixColor: #1e2332;

// Additional grey
$textDescription: #878C9B;

// Grey for table-cell
$tableGrey: #F0F5F9;
$tableFontColor: $fontColor;

// RGBA
$blackAlpha02: rgba(0,0,0,.2);
$greyAlpha015: rgba(34,36,38,.15);

// Icon in the top right
$iconTopColor: $white !default;
$iconTopHoverColor: $white !default;
$iconTopBorderColor: rgba(15,15,20,0.3) !default;
$iconTopBorderHoverColor: #0f0f14 !default;
$iconActiveTopBackgroundColor: $primaryColor !default;
$iconActiveTopFontColor: $white !default;
$iconActiveHoverTopBackgroundColor: $primaryColor !default;
$iconActiveHoverTopFontColor: $white !default;

// Menu on the left panel
$backgroundMenuColor: $white !default;
$menuChevronColor: $darkGrey !default;

// Menu burger
$burgerColor: $white !default;
$burgerActiveColor: $white !default;

// Stats
$statisticsColor: $primaryColor !default;

// Buttons
$buttonDefaultColor: #ededed;
$buttonHoverColor: #d4d4d4;

// Inputs
$inputErrorColor: #f4080a !default;

// Status
$greenStatusTextColor: #39C433;
$greenStatusBackgroundColor: #CDF0CC;
$orangeStatusTextColor: #FAA43E;
$orangeStatusBackgroundColor: #FEE8CF;
$redStatusTextColor: #F0480A;
$redStatusBackgroundColor: #FCC1C2;
$purpleStatusTextColor: #9933FF;
$purpleStatusBackgroundColor: #E5CCFF;
$blueStatusTextColor: #298EAC;
$blueStatusBackgroundColor: #C9E3EA;
$greyStatusTextColor: #1E2332;
$greyStatusBackgroundColor: #C7C8CC;

//Session
$sessionDisabledColor: rgba(30, 35, 50, 0.15);


.wz-table {
  border-collapse: collapse;
  border-spacing: 0;
  color: $tableFontColor;
  display: table;
  width: 100%;
  background-color: white;
}

.wz-tablerow {
  color: $tableFontColor;
  background-color: white;
}

/*TABLE HEADER*/
.wz-table thead {
  display: table-header-group;

  .wz-tablerow {
    height: 30px;
	  border-bottom-width: 1px!important;
  }

  .wz-tablecell, th {
    font-size: $normalFontSize;
    font-weight: normal;
    color: $greyFourColor;
    padding: 4px 10px 2px;
  }
}


/*TABLE BODY*/
.wz-table tbody {
  display: table-row-group;

  tr {
    height: 45px;
  }

  .wz-tablerow__hover:hover {
    background-color: rgb(var(--alpha-color));
	  color: inherit;

	  .wz-sortable-handle {
		  fill: rgb(var(--primary-color));
	  }

	  &:not(.wz-tablerow__disabled) {
		  .wz-commondropdown:hover {
			  @include background-opacity($white, .5);
		  }
	  	i.wz-font-click:hover {
		    @include background-opacity($white, .5);
	  	}
	  }
  }
}

/*ROW*/
/* Use a classname to allow having styles when drag&dropping  */
.wz-table tr, .wz-tablerow {
  @include transition-element(all $transitionColor ease);
  border-bottom: 1px solid $tableGrey;
  display: table-row;
  outline: none;
  vertical-align: middle;

  &.wz-tablerow__selected {
    background-color: rgb(var(--alpha-color));
	  color: rgb(var(--primary-color));

    // If the row is selected we set the background of the dropdown too
    .wz-commondropdown {
      background-color: rgb(var(--alpha-color));
    }
  }

  &.wz-tablerow__click {
    cursor: pointer;
  }

  &.wz-tableheaderrow:first-child {
    border-bottom: 1px solid $tableGrey;
  }

  &.wz-tablerow__disabled {
    background-color: #f9f9f9;
    color: #CED0DA;
  }
}

.wz-table tbody tr, .wz-tablerow {
  &:last-child {
    border-bottom-width: 0;
  }
}

/*CELLS*/
.wz-table td, .wz-table th, .wz-tablerow td {
  @include transition-element(color $transitionColor ease);
	border-color: transparent;
  border-right: 1px solid transparent;
  display: table-cell;
  padding: 14px 10px 10px;
  text-align: left;
  vertical-align: inherit;

  &.wz-tablecell__click {
    cursor: pointer;
  }

  &:last-child {
    border-right-width: 0;
  }

  // cell Dropdown
  &.wz-tablecelldropdown {
    width: 35px;
    padding: 14px 14px 10px 10px;

    .wz-commondropdownframe {
      margin: 0 auto;
    }
  }

  .wz-tablecellsorting {
    height: 18px;
    display: inline-table;
    cursor: pointer;

    &:hover i {
      color: $greyFourColor;
    }

    i {
      @include transition-element(color $transitionColor ease);
      color: $greyThreeColor;
      font-size: 10px;
      margin-left: 3px;
    }
  }
}

/* SIDE PART FOR CUSTOM CELLS*/
// cell Checkbox
.wz-tablecellcheckbox {
  width: 34px;
  text-align: center;

  .ui.fitted.checkbox {
    margin-top: 5px;
  }
}

.wz-table th.wz-sortable-handle {
  display: none;
}

.wz-table th.drag-handle, .wz-tablerow td.drag-handle {
  width: 15px;
  padding-right: 0px;
  padding-left: 15px;
}

.wz-table th.checkbox, .wz-tablerow td.checkbox {
  width: 20px;
  padding-right: 0px;
  padding-left: 15px;
}

.wz-tablerow__draggable th.checkbox, .wz-tablerow__draggable td.checkbox {
  padding-left: 0px;
}

/* current row drag */
body > tr.wz-tablerow__draggable {
  display: flex;
  box-shadow: 0px 5px 4px rgba(0, 0, 0, 0.25);
  align-items: center;
}

body > tr.wz-tablerow__draggable > td {
  flex-grow: 3;
}

body > tr.wz-tablerow__draggable > td.drag-handle {
  flex: initial;
  flex-basis: 34px;
}

body > tr.wz-tablerow__draggable > td.drag-handle > span {
	.wz-sortable-handle {
		fill: rgb(var(--primary-color));
	}
}

body > tr.wz-tablerow__draggable > td.checkbox {
  flex: initial;
}
/* current row drag */
.wz-sortable-handle {
	fill: $darkerLightGrey;
	cursor: all-scroll;
	margin-right: 6px;
	position: relative;
	user-select: none;
	bottom: 2px;
}

.wz-autotable-centerpaginator {
  display: flex;
  padding-top: 10px;
  justify-content: center;
}

.wz-label {
  @include transition-element(background $transitionColor ease, border $transitionColor ease, color $transitionColor ease);
  @include border-radius(5px);
  background-color: $white;
  border: solid 1px $darkerLightGrey;
  color: $black;
  cursor: default;
  display: inline-block;
  font-size: $mediumFontSize;
  max-height: 44px;
  padding: 12px 16px;
  position: relative;

  &:hover {
    @include background-opacity(var(--primary-color), 0.1);
    color: rgb(var(--primary-color));

    i {
      color: rgb(var(--primary-color));
    }
  }

  // For the icons
  i {
    color: $black;
    margin-right: 8px;
  }

  &:last-child {
    margin-right: 0;
  }

  &:not(.wz-label__disabled).wz-label__link {
    cursor: pointer;
  }

  &__disabled {
    background-color: $greyTwoColor;
    border-color: $greyTwoColor;
  }

  // Loading cursor
  &__loading {
    @include border-radius(8px);
    background-color: $blackAlpha02;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;

    .ui.loader:before {
      border-color: $white;
    }
  }

  // For the active label
  &__active {
    background-color: $white;
    border-color: rgb(var(--primary-color));
    color: rgb(var(--primary-color));

    i {
      color: rgb(var(--primary-color));
    }

    &:hover {
      @include background-opacity(var(--primary-color), 0.1);
      border-color: $darkerLightGrey;
      color: $black;

      i {
        color: $black;
      }
    }

    // For the active and inverted label
    &.wz-label__inverted {
      @include background-opacity(var(--primary-color), 0.1);
      border-color: $darkerLightGrey;

      i {
        color: rgb(var(--primary-color));
      }

      &:hover {
        @include background-opacity(var(--primary-color), 0.1);
        border-color: rgb(var(--primary-color));
        color: rgb(var(--primary-color));
      }
    }
  }

  // For inverted labels
  // We use the "not" selector because, we can't set the other
  // styles about the .active.inverted above
  &:not(.wz-label__active).wz-label__inverted {
    @include background-opacity(var(--primary-color), 0.1);
    color: rgb(var(--primary-color));

    i {
      color: rgb(var(--primary-color));
    }

    &:hover {
      background-color: $white;
      color: $black;

      i {
        color: $black;
      }
    }
  }

  // Stick the current icon (from right) to the left side
  &.wz-ui-label-left .wz-label-icon {
    left: 0;
  }

  // To stick the leftIcon to the left side of the label
  &.wz-ui-label-icon-left {
    .wz-label-icon:first-child {
      left: 0;
    }

    // Padding to let space for the icon
    &.wz-label {
      padding-left: 2.5em;
    }
  }

  // Padding to let space for the icon
  &.wz-ui-label-icon-right.wz-label {
      padding-right: 2.5em;
  }

  /** Possible useless css **/
  // For the icons inside the label
  .wz-label-icon {
    align-items: center;
    color: $darkerLightGrey;
    display: flex;
    // little hack to work with default padding of semantic icons
    height: calc(100% - 7px);
    justify-content: center;
    margin: 0 .1em;
    max-width: 2.4em;
    position: absolute;
    right: 0;
    top: 2px;
    width: 100%;
    cursor: pointer;
    .wz-font.wz-font-cross.wz-font-regular {
      margin-top: 3px;
      font-size: 0.8em;
    }

    // Active the hover only when the icon has the onClick listener
    &__click i:hover {
      @include background-opacity(var(--primary-color), 0.13);
    }
    &__disabled:hover {
      cursor: not-allowed;
    }
    // Reset semantic styles
    // Add custom styles for icons
    i {
      @include border-radius(100%);
      @include transition-element(all $transitionBorder ease);
      margin: inherit;
      padding: 12px;
      position: relative;

      &:before {
        left: 0px;
        padding-top: 5px;
        position: absolute;
        right: 0;
        top: 0px;
      }
    }
  }
  /** Possible useless css **/
}

.wz-status {
  @include transition-element(background $transitionColor ease);
  @include border-radius($borderRadiusStatus);
  background-color: $greyStatusBackgroundColor;
  color: $greyStatusTextColor;
  cursor: default;
  display: inline-block;
	font-weight: bold;
  font-size: $smallFontSize;
  letter-spacing: 1.67px;
  margin-right: 10px;
  padding: 3px 5px;
  position: relative;
  text-transform: uppercase;
  border: 1px solid transparent;
	user-select: none;

  &:last-child {
    margin-right: 0;
  }

  // For Colored labels
  &.wz-status__green {
    background-color: $greenStatusBackgroundColor;
	  border-color: $greenStatusBackgroundColor;
    color: $greenStatusTextColor;
  }
  &.wz-status__orange {
    background-color: $orangeStatusBackgroundColor;
	  border-color: $orangeStatusBackgroundColor;
    color: $orangeStatusTextColor
  }
  &.wz-status__red {
    background-color: $redStatusBackgroundColor;
	  border-color: $redStatusBackgroundColor;
    color: $redStatusTextColor;
  }
  &.wz-status__purple {
    background-color: $purpleStatusBackgroundColor;
	  border-color: $purpleStatusBackgroundColor;
    color: $purpleStatusTextColor;
  }
  &.wz-status__blue {
    background-color: $blueStatusBackgroundColor;
	  border-color: $blueStatusBackgroundColor;
    color: $blueStatusTextColor;
  }

	&.wz-status__with-icon {
		cursor: pointer;
		padding-right: 1px;

		.wz-status__icon {
			margin-left: 2px;
		}
	}
}
.wz-dropdown-status__container {
	display: inline-block;
	margin-right: 10px;

	&:last-child {
		margin-right: 0;
	}
}
.wz-dropdown-status {
	display: inline-flex;
	margin-right: 10px;
	position: relative;

	&:last-child {
		margin-right: 0;
	}

	.wz-status {
		margin: 0;

		&:hover {
			// For Colored labels
			&.wz-status__green {
				border-color: $greenStatusTextColor;
			}
			&.wz-status__orange {
				border-color: $orangeStatusTextColor
			}
			&.wz-status__red {
				border-color: $redStatusTextColor;
			}
			&.wz-status__purple {
				border-color: $purpleStatusTextColor;
			}
			&.wz-status__blue {
				border-color: $blueStatusTextColor;
			}
		}
	}

	&.wz-dropdown-status__active {
		.wz-status {
			margin: 0;

			// For Colored labels
			&.wz-status__green {
				border-color: $greenStatusTextColor;
			}
			&.wz-status__orange {
				border-color: $orangeStatusTextColor
			}
			&.wz-status__red {
				border-color: $redStatusTextColor;
			}
			&.wz-status__purple {
				border-color: $purpleStatusTextColor;
			}
			&.wz-status__blue {
				border-color: $blueStatusTextColor;
			}
		}
	}
}

.wz-status__led {
	margin: 0 7px;
	border: none;
	width: 20px;
	height: 20px;
	background-color: $greyStatusBackgroundColor;
	border-radius: 50%;
	padding: 5px;
	&.wz-status__green {
		background-color: $greenStatusBackgroundColor;
		.wz-status__content {
			background-color: $greenStatusTextColor;
		}
	}
	&.wz-status__orange {
		background-color: $orangeStatusBackgroundColor;
		.wz-status__content {
			background-color: $orangeStatusTextColor;
		}
	}
	&.wz-status__red {
		background-color: $redStatusBackgroundColor;
		.wz-status__content {
			background-color: $redStatusTextColor;
		}
	}
	&.wz-status__purple {
		background-color: $purpleStatusBackgroundColor;
		.wz-status__content {
			background-color: $purpleStatusTextColor;
		}
	}
	&.wz-status__blue {
		background-color: $blueStatusBackgroundColor;
		.wz-status__content {
			background-color: $blueStatusTextColor;
		}
	}
	.wz-status__content {
		border: none;
		width: 10px;
		height: 10px;
		background-color: $greyStatusTextColor;
		border-radius: 50%;
	}
	.wz-status__text {
		display: none;
	}
}


@media screen and (max-width: $widthMobile) {
	.wz-status__responsive {
		margin: 0 7px;
		border: none;
		width: 20px;
		height: 20px;
		background-color: $greyStatusBackgroundColor;
		border-radius: 50%;
		padding: 5px;
		.wz-status__content {
			border: none;
			width: 10px;
			height: 10px;
			background-color: $greyStatusTextColor;
			border-radius: 50%;
		}
		.wz-status__text {
			display: none;
		}
		&.wz-status__green {
			background-color: $greenStatusBackgroundColor;
			.wz-status__content {
				background-color: $greenStatusTextColor;
			}
		}
		&.wz-status__orange {
			background-color: $orangeStatusBackgroundColor;
			.wz-status__content {
				background-color: $orangeStatusTextColor;
			}
		}
		&.wz-status__red {
			background-color: $redStatusBackgroundColor;
			.wz-status__content {
				background-color: $redStatusTextColor;
			}
		}
		&.wz-status__purple {
			background-color: $purpleStatusBackgroundColor;
			.wz-status__content {
				background-color: $purpleStatusTextColor;
			}
		}
		&.wz-status__blue {
			background-color: $blueStatusBackgroundColor;
			.wz-status__content {
				background-color: $blueStatusTextColor;
			}
		}
	}
}

.wz-dropdown-language__container {
	display: inline-block;
	margin: 0 5px;
	align-self: center;

	.small {
		font-size: .8em;
	}

	.wz-dropdown-language {
		cursor: pointer;
		padding: 3px 10px;
		border-radius: 5px;
		display: inline-flex;
		align-items: flex-end;

		.wz-font {
			position: relative;
			top: 2px;
			margin-right: 5px;
		}

		&__active,
		&:hover {
			@include background-opacity($white, .3);
		}

	}

	.wz-commondropdown-list .wz-item {
		display: inline-flex;
		align-items: flex-end;

		.wz-flag {
			position: relative;
			top: -1px;
			margin-right: 5px;
		}
	}
}

.wz-container-modal {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.2);
  justify-content: center;
  align-items: center;
  z-index: 100;

	.wz-modal {
		position: relative;
		width: 500px;
		color: #000000;
		background-color: white;
		text-align: left;
		padding: 15px 20px;
		font-size: 14px;
		border-radius: 5px;
		box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
		margin: 0 20px;
		z-index: 101;
		max-height: calc(100vh - 80px);
		display: flex;
		flex-direction: column;

		&.wz-modal-size-small  {
			width: 300px;
		}

		&.wz-modal-size-medium {
			width: 500px;
		}

		&.wz-modal-size-large {
			width: 700px;
		}

		&.wz-modal-size-extralarge {
			width: 900px;
		}

		&.wz-modal-size-fullscreen {
			width: 100%;
			height: 100%;
			max-height: 100%;
			margin: 0;
			border-radius: 0;
		}

		.wz-title-modal {
			font-size: 16px;
			font-weight: 500;
			text-align: left;
			padding-bottom: 12px;
			line-height: 1.5;
			margin-right: 13px;
		}

		.wz-subtitle-modal {
			margin-bottom: 20px;
			line-height: 20px;
			color: $textDescription;
		}

		.wz-content-modal {
			// scrollbar
			@include scrollbar();
			max-height: 100%;
			overflow-y: auto;
			margin: 0 -15px;
			padding: 0 15px;
		}

		.wz-crossClose-modal {
			background: url('./icons/cross-close.svg');
			position: absolute;
			top: 15px;
			right: 9px;
			width: 24px;
			height: 24px;
			border-radius: 50%;
			padding: 6px;

			&:hover {
				background-color: #d4d4d4;
				cursor: pointer;
			}
		}

		.wz-footer-modal {
			padding-top: 15px;
			display: flex;
			justify-content: flex-end;

			.wz-button-group {
				display: flex;
			}
		}

		.wz-footer-modal-submit {
			margin-left: 15px;
		}

		.wz-welcome-modal {
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: 100%;
		}
	}
}

.wz-modal__open {
	overflow: hidden;
}

@media screen and (max-width: 600px) {
	.wz-container-modal {
		.wz-modal {
			max-height: 100vh;
			&.wz-modal-size-small, &.wz-modal-size-medium, &.wz-modal-size-large, &.wz-modal-size-extralarge {
				width: 100%;
			}
			&.wz-modal-fullscreen-on-mobile {
				position: absolute;
				width: 100%;
				height: 100%;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				margin: 0;
				border-radius: 0;
			}
		}

		.wz-modal {
			.wz-footer-modal {
				flex-direction: column;

				.wz-button-group {
					flex-direction: column-reverse;

					.wz-button {
						width: 100%;
						&:not(:last-of-type) {
							margin-right: 0;
							margin-left: 0;
							margin-top: 10px;
						}
					}
					.wz-button-dropdown {
						&:not(:last-of-type) {
							margin-right: 0;
							margin-left: 0;
							margin-top: 10px;
						}
					}
				}

				.wz-footer-modal-cancel {
					width: 100%;
				}

				.wz-footer-modal-submit {
					margin-left: 0;
				}

				.wz-footer-modal-submit div, .wz-footer-modal-cancel div {
					text-align: center;
					width: 100%;
				}
			}
		}
	}

  .wz-title-modal {
    margin-right: 0;
  }
}

.wz-tab {
	.wz-tab__menuItems {
		display: flex;
		flex-direction: row;

		.wz-tab__item {
			display: flex;
			padding: 10px 0;
			background-color: $buttonDefaultColor;
			font-weight: 500;
			cursor: pointer;
			position: relative;
			align-items: center;
			width: 100%;

			&:first-child {
				border-top-left-radius: 5px;
			}

			&:last-child {
				border-top-right-radius: 5px;
			}

			&.wz-tab__disabled {
				cursor: not-allowed;
				color: rgba($darkGrey, .7);
				background-color: $buttonHoverColor;
			}

			.wz-tab__item-content {
				display: inline-flex;
				padding: 5px 13px;
				align-items: center;
				width: 100%;
			}

			&:not(:first-child) .wz-tab__item-content {
				border-left: 1px solid $greyFourColor;
			}

			&.wz-tab__active {
				border-top-right-radius: 5px;
				border-top-left-radius: 5px;
				background-color: $white;
				color: rgb(var(--primary-color));

				.wz-tab__item-content {
					border-left: 0px solid $greyFourColor;
				}

				&+.wz-tab__item .wz-tab__item-content{
					border-left: 0px solid $greyFourColor;
				}

				&:after {
					display: block;
					content: '';
					position: absolute;
					top: 0; right: 0; bottom: 0; left: 0;
					box-shadow: 3px -1px 5px -3px rgba(0, 0, 0, 0.25);
				}
			}

			&:not(.wz-tab__active):hover {
				background-color: $buttonHoverColor;
			}

			i.wz-font.wz-tab__item-content-icon {
				margin: auto;
			}

			.wz-tab__text {
				display: none;
			}
		}
	}

	.wz-tab__content {
		background-color: $white;
		width: 100%;
	}
}

@media screen and (min-width: $widthMobile) {
	.wz-tab {
		.wz-tab__menuItems {
			.wz-tab__item {
				width: auto;
				.wz-tab__item-content {
					padding: 5px 40px;
				}
			}
		}
	}
}

@media screen and (min-width: $widthTablet) {
	.wz-tab {
		.wz-tab__menuItems {
			.wz-tab__item {
				.wz-tab__item-content {
					padding: 5px 13px;
				}
				i.wz-font.wz-tab__item-content-icon {
					margin-right: 12px;
				}

				.wz-tab__text {
					display: flex;
				}
			}
		}
	}
}

// MINI TAB START

.wz-minitab {
	.wz-minitab__menuItems {
		display: flex;
		flex-direction: row;
		.wz-minitab__item {
			padding: 13px 15px;
			background-color: $buttonDefaultColor;
			font-weight: 500;
			font-size: 12px;
			cursor: pointer;
			white-space: nowrap;
			@include transition-element(ease-in-out .3s);
			&:first-child {
				border-top-left-radius: 5px;
				border-bottom-left-radius: 5px;
			}

			&:last-child {
				border-top-right-radius: 5px;
				border-bottom-right-radius: 5px;
			}

			&.wz-minitab__disabled {
				cursor: not-allowed;
				color: rgba($darkGrey, .7);
				background-color: $buttonHoverColor;
			}

			.wz-minitab__item-content {
				display: flex;
				align-items: center;
				justify-content: center;
				@include transition-element(ease-in-out .3s);

				&.wz-minitab__item-content__active {
					box-shadow: 1px 1px 5px rgba(0, 50, 250, 0.25);
					border-radius: 5px;
					background-color: $white;
					color: rgb(var(--primary-color));
					padding: 10px 12px;
				}
			}
			&.wz-minitab__active {
				padding: 3px;
			}
			i.wz-minitab__item-content-icon {
				margin-right: 5px;
			}
			&:not(.wz-minitab__disabled):hover {
				color: rgb(var(--primary-color));
			}
		}
	}
}

@media screen and (max-width: $widthMobile) {
	.wz-minitab {
		.wz-minitab__menuItems {
			overflow-x: scroll;
			&::-webkit-scrollbar {
				width: 0;
				height: 0;
			}
			.wz-minitab__item {
				flex-grow: 1;
			}
		}
	}
}

// MINI TAB END

.wz-statistics-group {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.wz-ring {
	margin: 0;
	width: 36px;
	height: 36px;

	.circle-label {
		transform: translateY(.25em);
	}

	.circle-percentage {
		font-size: .7em;
		line-height: 1;
		text-anchor: middle;
	}
}

.wz-statistics {
  background-color: #ffffff;
  text-align: left;
  padding: 10px 0 5px 0;
  font-size: 12px;
  margin: 0 25px 15px 0;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 22%;
}

.wz-statistics-withFooter {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 15px;
}

.wz-statistics-size-small  {
  width: 265px;
}

.wz-statistics-size-medium {
  width: 365px;
}

.wz-statistics-size-large {
  width: 465px;
}

.wz-statistics-size-extralarge {
  width: 565px;
}

.wz-statistics-header {
  padding: 0 15px 8px 15px;
  position: relative;
}

.wz-statistics-header-main {
}

.wz-statistics-header-margin-graph {
  margin-right: 40px;
}

.wz-statistics-header-title {
	font-weight: 500;
  font-size: 24px;
}

.wz-statistics-header-title-separator {
  position: relative;
  bottom: 2px;
  font-size: 12px;
}

.wz-statistics-header-title-statmax {
  font-size: 12px;
}

.wz-statistics-header-subtitle {
  display: flex;
  margin-top: 6px;
  font-size: 12px;
  color: #878c9b;
  min-height: 16px;
}

.wz-statistics-header-tooltip {
  margin: 0 7px;
}

.wz-statistics-help-icon {
  background: url('./icons/help.svg');
  display: inline-block;
  width: 13px;
  height: 13px;
}

.wz-statistics-header-graph {
  position: absolute;
  top: 0;
  right: 15px;
  margin-left: 12px;
  max-width: 36px;
  max-height: 36px;
}

.wz-statistics-footer {
  padding: 0 15px 0 15px;
}

.wz-statistics-footer-moreLink {
  color: #000000;
  padding-top: 5px;
  padding-bottom: 5px;
  display: inline-block;
  font-size: 12px;
}

.wz-statistics-footer-moreLink:hover {
  color: rgb(var(--primary-color));
}

.wz-statistics-footer-variation-equal {
  color: #faa43e;
  font-size: 10px;
	font-weight: bold;
  margin-right: 6px;
}

.wz-statistics-footer-variation-increase {
  color: #39c433;
  font-size: 14px;
	font-weight: 500;
}

.wz-statistics-footer-variation-lowering {
  color: #df3838;
  font-size: 14px;
	font-weight: 500;
}

.wz-statistics-footer-variation-icon-increase {
  background: url('./icons/arrow-green.svg');
  display: inline-block;
  width: 9px;
  height: 9px;
}

.wz-statistics-footer-variation-icon-lowering {
  background: url('./icons/arrow-red.svg');
  display: inline-block;
  width: 9px;
  height: 9px;
}

.wz-statistics-footer-variationLabel {
  font-size: 10px;
}

/* CONTENT */
.wz-statistics-content {
  padding: 10px 15px;
  border-top: 1px solid #ced0da;
}

/* TABLE */
.wz-statistics-table {
  width: 100%;
}

.wz-statistics-table-row {
  vertical-align: baseline;
  //padding: 0 0 8px 0;
}

td.wz-statistics-table-first-cell {
  display: flex;
  text-align: left;
  border: 0;
  padding: 0 0 8px 0;
  max-width: 145px;
}

td.wz-statistics-table-last-cell {
  text-align: right;
  border: 0;
  padding: 0 0 8px 10px;
}

td.wz-statistics-table-cell {
  text-align: right;
  border: 0;
  padding: 0 0 8px 10px;
}

.wz-statistics-group .wz-statistics:last-child {
  margin: 0 0 15px 0;
}

@media screen and (max-width: 1260px) {
  .wz-statistics-group .wz-statistics:nth-child(even) {
    margin: 0 0 15px 0;
  }

  .wz-statistics {
      flex-basis: 45%;
  }

  td.wz-statistics-table-first-cell {
    display: table-cell;
  }
}

@media screen and (max-width: 600px) {
  .wz-statistics-group {
    flex-direction: column;
  }

  .wz-statistics-group .wz-statistics {
    margin: 0 0 15px 0;
  }

  .wz-statistics {
    width: 100%;
  }
}

.wz-numbers {
  display: inline;
}

.wz-numbers-monospace-font {
  display: inline;
}

.wz-paginator {
  display: inline-block;
  margin: 0 3px;
}

.wz-paginator .active {
  background-color: #0032FA;
  color: white;
  box-shadow: 0 2px 3px 0 rgba(0,50,200,0.3);
}

.wz-paginator .wz-paginator-button {
  padding: 2px 3px 0 3px;
  user-select: none;
  cursor: pointer;
  display: inline-block;
  min-width: 19px;
  text-align: center;
  border-radius: 3px;
	&:not(.active):not(.disabled):hover {
		color: $primaryColor;
	}
}

.wz-paginator .disabled {
  color: #bcbcbc;
  cursor: default;
}

.wz-paginator .wz-separator {
  color: black;
}

//FormStep
.wz-formstep .wz-split-container__content {
  margin-bottom: 15px;
}

.wz-formstep .wz-split-container__content .wz-formstep__card {
  margin-bottom: 0;
}

//Only formStep open
.wz-formstep__open .wz-split-container__content .wz-formstep__card {
  height: 100%;
}

.wz-formstep__summary {
  color: #8d8d8d;
}

.wz-formstep .wz-split-container__additional .wz-message {
  margin-bottom: 15px;
}

/* RESPONSIVENESS FormStep */
@media screen and (max-width: 800px) {
  .wz-formstep__done, .wz-formstep__closed {
    display: none;
  }
}

//TitleFormStep
.wz-formstep__title {
  display: flex;
	font-weight: bold;
  font-size: 16px;
}

.wz-formstep__title i.wz-font.wz-font-circular.wz-font-inverted.wz-formstep__title-icon {
  background-color: rgb(var(--primary-color));
  margin-left: auto;
  font-size: 8px;
}

//EditButton
.wz-formstep__edit {
  margin-top: 10px;
}

.wz-formstep__edit-button {
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    color: rgb(var(--primary-color));
  }
}

//FormManagerBarMobile
.wz-form-manager__mobile {
  height: 0;
  color: $textDescription;
}

.wz-form-manager__mobile-current {
  display: none;
  text-align: center;
  margin-bottom: 20px;
}

.wz-form-manager__mobile-previous {
  display: none;
  margin: 0 auto 10px 0;
  cursor: pointer;
  padding: 7px 10px 5px 4px;
  position: relative;
  bottom: 43px;
  right: 2px;
  font-size: 12px;
}

i.wz-font.wz-form-manager__mobile-previous-icon {
  margin-right: 10px;
}

i.wz-font.wz-form-manager__mobile-previous-icon:before {
  vertical-align: baseline;
}

.wz-form-manager__mobile-previous:active {
  background-color: rgb(var(--alpha-color));
}

.wz-form-manager__mobile-previous:active > .wz-form-manager__mobile-previous-icon {
  color: rgb(var(--primary-color));
}

/* RESPONSIVENESS FormManagerBarMobile*/
@media screen and (max-width: 800px) {
  .wz-form-manager__mobile {
    height: 33px;
  }

  .wz-form-manager__mobile-current {
    display: block;
  }

  .wz-form-manager__mobile-previous {
    display: inline-block;
  }
}

//Section
.wz-section {
  border-radius: 5px;
  background-color: $lightGrey;
  padding: 15px;
  margin-bottom: 15px;
}

.wz-subsection {
  background-color: white;
  padding: 15px;
  margin-bottom: 0;
}

//OptionalSection
.wz-optional-section {
  overflow: hidden;
  @include transition-element(margin-bottom 0.3s 0.1s);
  margin-bottom: 0;
}

.wz-optional-section_open {
  @include transition-element(margin-bottom 0.1s);
  margin-bottom: 15px;
}

.wz-optional-section__content {
  @include transition-element(max-height 0.3s ease-in-out);
  max-height: 50em;
}

.wz-optional-section__content-close {
  @include transition-element(max-height 0.3s);
  max-height: 0em;
  height: 0;
}

.wz-optional-section__label {
  display: inline-flex;
  align-items: baseline;
  user-select: none;
  margin-bottom: 15px;
  font-size: 14px;
	font-weight: 500;
  span {
    user-select: none;
  }
}

.wz-optional-section__label:hover {
  color: $primaryColor;
  cursor: pointer;
}

i.wz-font.wz-font-mini.wz-optional-section__icon {
  font-size: 0.7em;
  vertical-align: baseline;
  margin-right: 0.7em;
}

.wz-optional-section__optional {
  color: $inputDisabledGrey;
  padding-left: 5px;
  font-size: 10px;
}

//ToggleContainer
.wz-input-switch {
  user-select: none;
}

.wz-toggle-container__label {
  display: flex;
  margin-bottom: 15px;
  align-items: inherit;
}

.wz-toggle-container__label-open {
  margin-bottom: 12px;
}

.wz-toggle-container__mini {
  align-items: baseline;
}

.wz-toggle-container__title-container{
  padding-top: 2px;
  width: 285px;
  margin-right: 15px;
  display: flex;
  flex-direction: column;
}

.wz-toggle-container__subtitle {
  font-size: 0.85em;
  color: $inputDisabledGrey;
}

/* RESPONSIVENESS ToggleContainer*/
@media screen and (max-width: 400px) {
  .wz-toggle-container__label{
    flex-direction: column;
  }
  .wz-toggle-container__title-container{
    width: 100%;
    margin-right: 0;
    margin-bottom: 6px;
  }
}

//OptionalForm
.wz-optional-form .wz-optional-form__card {
  padding: 18px 15px;
  user-select: none;
  &:hover {
	color: rgb(var(--primary-color));
    cursor: pointer;
  }
}

.wz-optional-form .wz-card.wz-optional-form__card-open {
  margin-bottom: 10px;
}

.wz-optional-form__label {
  display: flex;
  align-items: center;
}

i.wz-font.wz-font-mini.wz-optional-form__icon {
  font-size: 0.8em;
  margin-left: 0.7em;
}

.wz-infotext span {
	display: block;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

.wz-infotext.line-break span {
	text-overflow: inherit;
	white-space: break-spaces;
}

.wz-infotext .wz-infotext__subtitle {
	padding-top: 5px;
	color: $greyFourColor;
	font-size: $normalFontSize;
}

/* Popin Start */
.wz-popin__container {
	display: inline-block;
}

.wz-popin__container-inline {
	display: inline;
}

.wz-popin {
	z-index: 12;
}

.wz-modal .wz-popin {
	z-index: 200;
}

.wz-popin__arrow,
.wz-popin__arrow::before {
	position: fixed;
	width: 8px;
	height: 8px;
	background: inherit;
}

.wz-popin__arrow {
	visibility: hidden;
}


.wz-popin__arrow::before {
	animation: fadeIn ease-in-out 0.5s;
	visibility: visible;
	content: '';
	transform: rotate(45deg);
	background-color: $greySixColor;
}

@keyframes fadeIn {
	0% { opacity: 0; }
	100% { opacity: 1; }
}
/* Popin End */

// TODO: remove all the important if we can (edit on 24/02/2020)
.dropZoneStyle {
	@include border-radius($borderRadiusCommonWidth);
	border: 2px dashed !important;
	border-color: lightgrey !important;
	color: grey;
	cursor: pointer;
	font-size: 20px;
	padding: 2em;
	text-align: center;

	&:hover {
		border-color: dimgrey !important;
		color: dimgrey;
	}

	&:focus {
		outline-style: none;
	}
}

.dropZoneStyleRejected {
	border-color: red !important;
	color: red;
}

.typeFile {
	font-size: 15px;
}

/** TopBarModules **/
//Start Switch
.wz-switch__container {
  display: inline-block;
  margin: 0 0.1em;
  align-self: center;
  //Trigger
  .wz-switch {
    &.wz-trigger-switch {
      cursor: pointer;
      user-select: none;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      height: 34px;
      width: 34px;
      &__active,
      &:hover {
        @include background-opacity($white, .3);
      }
      .wz-trigger-switch__input {
        display: none;
      }
      //center icon search
      .wz-trigger-switch__icon {
        position: relative;
        top: 2px;
        left: 2px;
        font-size: 1.2em;
      }

      .wz-search-input {
        .wz-input-text {
          background-color: rgb(var(--medium-color));
          border-color: rgb(var(--primary-color));
          color: rgba($white, .8);
          user-select: none;
        }
        .wz-input-icon.wz-input-icon__right {
          color: #FFFFFF;
        }
      }
    }
  }
  //Dropdown
  .wz-switch__list .wz-item__search .wz-input {
    font-size: 14px;
    padding: 7px;
  }
  .wz-switch__header {
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 5px;

    &.wz-dropdowncommonitem.wz-dropdowncommonitem-title h1 {
      font-weight: 500;
      font-size: 14px;
    }
  }
  .wz-switch__items {
    max-height: 200px;
    overflow: auto;

    .wz-item {
      font-size: 14px;
      .wz-item__subtitle {
        font-size: 12px;
      }
    }

    &::-webkit-scrollbar {
      width: 2px;
      height: 2px;
    }
  }
  .wz-switch__footer {
    .wz-item {
      font-size: 14px;
    }

    &.wz-dropdowncommonitem:not(.wz-dropdowncommonitem__disabled):hover {
      color: rgb(var(--primary-color));
      background-color: transparent;
    }

    .wz-item:hover {
      background-color: transparent;
    }
  }
}

@media screen and (min-width: $widthTablet) {
.wz-switch__container {
  margin: 0 1.3em 0 0.7em;
    .wz-switch {
      &.wz-trigger-switch {
        border-radius: unset;
        display: block;
        width: 300px;
        height: inherit;
        &::after {
          content: '';
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
        &__active,
        &:hover {
          border-radius: 5px;
          .wz-trigger-switch__input {
            .wz-input-text {
              border: 1px solid rgba($white, .5);
            }
          }
        }
        .wz-trigger-switch__input {
          display: block;
          .wz-input-text {
            height: 38px;
          }
        }
        .wz-trigger-switch__icon {
          display: none;
        }
      }
    }
  }
}

@media screen and (min-width: $widthMobile) and (max-width: $widthTablet) {
  .wz-switch__container {
    margin: 0 0.4em;
  }
};

@media screen and (min-width: $widthMobile) {
  .wz-switch__container {
    .wz-switch__list.wz-commondropdown-list {
      width: 450px;
    }
  }
}
// End Switch

// Start DropdownHelp
.wz-help__container {
  display: inline-block;
  margin: 0 0.1em;
  align-self: center;
  .wz-help__list {
    padding: 10px;
    max-height: 440px;
    .wz-dropdowncommonitem-external-link.wz-dropdowncommonitem {
      margin-bottom: 10px;
      .wz-item {
        padding: 0;
        display: flex;
        align-items: center;
        .wz-dropdowncommonitem-icon {
          margin-right: 10px;
          width: 30px;
          height: 30px;
          padding: 8px;
          border-radius: 50%;
          &:hover {
            background-color: rgb(var(--lighter-color));
          }
          .wz-font {
            margin-right: 0;
            font-size: 1.1em;
            position: relative;
            top: -1px;
          }
        }
        .wz-font.wz-font-external-link {
          margin-right: 0;
          font-size: 0.8em;
          position: relative;
          top: 1px;
        }
      }
      &:hover {
        .wz-item {
          .wz-dropdowncommonitem-icon {
            background-color: rgb(var(--lighter-color));
          }
        }
      }
    }
    .wz-help__text {
      background-color: $buttonDefaultColor;
      margin-top: 10px;
      color: $black;
      line-height: 1.2em;
      padding: 10px;

      .wz-optional-section {
        &:not(.wz-optional-section_open)  {
          .wz-optional-section__label {
            @include transition-element(margin-bottom 0.1s 0.25s);
            margin-bottom: 0;
          }
        }
        .wz-optional-section__content > div {
          margin-bottom: 12px;
          &:last-child {
            margin-bottom: 0;
          }
        }
        &.wz-optional-section_open  {
          margin-bottom: 0;
        }
      }
    }
  }
}

.wz-help {
  user-select: none;
  border-radius: 50%;
  color: rgb(var(--primary-color));
  cursor: pointer;
  height: 34px;
  position: relative;
  width: 34px;
  display: flex;
  align-items: center;
  justify-content: center;

  &__active,
  &:hover {
    @include background-opacity($white, .3);
  }

  //hide tooltip mobile and tablet
  .wz-tooltip__content {
    display: none;
  }

  .wz-help-icon {
    display: block;
    text-align: center;

    i.wz-font {
      margin: 0;
      color: #FFF;
      font-size: 1.2em;
      position: relative;
      top: -1px;

      &:before {
        vertical-align: middle;
      }
    }
  }
}

@media screen and (min-width: $widthMobile) {
  .wz-account__container {
    margin: 0 0.4em;
  }
};

//display tooltip only desktop
@media screen and (min-width: $widthTablet) {
  .wz-help {
    .wz-tooltip__content {
      display: block;
    }
  }
};
// End DropdownHelp

// Start Initials
.wz-account {
  background-color: $white;
  border-radius: 50%;
  color: rgb(var(--primary-color));
  cursor: pointer;
  font-stretch: normal;
  font-weight: normal;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  position: relative;

  font-size: 14px;
  height: 46px;
  padding-top: 1.1em;
  width: 46px;

  .wz-account-initials {
    display: block;
    text-align: center;
    text-transform: uppercase;
  }
}

.wz-account.medium {
  font-size: 13px;
  height: 40px;
  width: 40px;
  padding-top: .9em;
}

.wz-account.small {
  font-size: 12px;
  height: 34px;
  width: 34px;
  padding-top: .8em;
}

.wz-account.inline {
  margin-right: 1.1em;
  display: inline-block;
  cursor: auto;
}
// End Initials

// Start DropdownAccount
.wz-account__container {
  display: inline-block;
  margin: 0 0.1em 0 0.4em;
  align-self: center;
  .wz-account__list {
    .wz-account-item__subtitle {
      .wz-item {
        font-weight: 500;
      }
    }
    .wz-dropdownline {
      margin: 13px -8px 5px;
    }
  }
}

.wz-account__trigger-container {
  //hide tooltip mobile and tablet
  .wz-tooltip__content {
    display: none;
  }
}

@media screen and (min-width: $widthMobile) {
  .wz-account__container {
    margin: 0 0.4em 0 1em;
  }
};

//display tooltip only desktop
@media screen and (min-width: $widthTablet) {
  .wz-account__trigger-container {
    //hide tooltip modile and tablet
    .wz-tooltip__content {
      display: block;
    }
  }
};
// End DropdownAccount

// Start DropdownUniverses
.wz-universes__container {
  display: inline-block;
  margin: 0 0.1em;
  align-self: center;
  .wz-universes {
    @include transition-element(background .3s ease-in-out, color .3s ease-in-out, border .3s ease-in-out);
    cursor: pointer;
    border-radius: 50%;
    height: 34px;
    width: 34px;
    padding: 10px;
    position: relative;

    &__active,
    &:hover {
      @include background-opacity($white, .3);
    }

    i.wz-font {
      margin: -2px 0 0;
      position: relative;
      left: 1px;
    }
  }
  //hide tooltip mobile and tablet
  .wz-tooltip__content {
    display: none;
  }

  .wz-universes__list.wz-commondropdown-list {
    width: 265px;
    .wz-universes__header {
      .wz-item {
        padding: 2px 7px 5px;
      }
    }
    .wz-universe-item {
      .wz-item {
        display: flex;
        padding: 10px 7px;
        .wz-universe-item__logo {
          height: 34px;
          margin-right: 10px;
        }
        .wz-universe-item__container-label {
          .wz-universe-item__label {
            font-weight: 500;
          }
          .wz-universe-item__sublabel {
            font-size: 12px;
            color: $textDescription;
            margin-top: 7px;
          }
        }

      }
    }
    .wz-universes__footer-container {
      margin: 0 -8px;
      text-align: center;
      .wz-item {
        padding: 5px 15px;
      }
    }
  }
}

@media screen and (min-width: $widthMobile) {
  .wz-universes__container {
    margin: 0 0.4em;
  }
};

//display tooltip only desktop
@media screen and (min-width: $widthTablet) {
  .wz-universes__container {
    .wz-universes {
      .wz-tooltip__content {
        display: block;
      }
    }
  }
};
//End DropdownUniverses

// Start ModuleOrganization
.wz-module-organization {
  align-self: center;
  margin: 0 0.5em;
  user-select: none;
  min-width: 60px;
  display: flex;
  .wz-module-organization__tooltip-container {
    display: none;
    .wz-popin__container {
      max-width: 170px;
      display: block;
      .wz-module-organization__name {
        font-weight: 500;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
  }
  &:not(.wz-module-organization-switch-orga) {
    .wz-tooltip {
      cursor: default;
    }
  }

  .wz-module-organization__id {
    font-size: 12px;
    opacity: 0.9;
    span:last-child {
      user-select: all;
      letter-spacing: 0.7px;
    }
  }
  &.wz-module-organization-switch-orga {
    cursor: pointer;
    .wz-module-organization__icon {
      display: none;
    }
    .wz-module-organization__id {
      font-size: 12px;
    }
  }
}

@media screen and (min-width: $widthTablet) {
  .wz-module-organization {
    .wz-module-organization__tooltip-container {
      .wz-popin__container {
        max-width: 200px;
      }
    }
  }
};

@media screen and (min-width: $widthMobile) {
  .wz-module-organization {
    margin: 0 1em;
    .wz-module-organization__tooltip-container {
      margin-top: 2px;
      display: block;
    }
    .wz-module-organization__id {
      font-size: 14px;
      margin-top: 2px;
    }
    &.wz-module-organization-switch-orga {
      font-size: 12px;
      padding: 2px 10px;
      cursor: pointer;
      background-color: #0133b2;
      border-radius: 5px;
      border: 1px solid #0133b2;
      .wz-module-organization__icon {
        display: flex;
        align-items: center;
        margin-left: 10px;
      }
      .wz-module-organization__id {
        font-size: 12px;
      }
      &:hover {
        border: 1px solid rgba($white, .5);
      }  
    }
  }
};
// End ModuleOrganization
/** TopBarModules **/

/** TopBar **/
//override new TopBar
.wz-topmenu__override {
  .wz-universe {
    display: flex;
    align-items: center;
    .wz-logo {
      display: inline-block;
      align-self: center;
      background-position: -38px -32px;
      background-repeat: no-repeat;
      background-size: 225px;
      width: 30px;
      margin-left: 0.8em;
    }

    .wz-burger {
      width: 43px;
      .wz-burgerctnt {
        top: 46%;
      }
    }

  }
}

.wz-module-override {
  margin-right: 0.4em;
}

.ticket_universe:hover {
	background-color: rgba($lighterTicketColor, 0.13) !important;
}
.access_universe:hover {
	background-color: rgba($lighterAccessColor, 0.13) !important;
}
.pay_universe:hover {
	background-color: rgba($lighterPayColor, 0.13) !important;
}
.target_universe:hover {
	background-color: rgba($lighterTargetColor, 0.13) !important; 
}


@media screen and (min-width: $widthMobile) {
  .wz-topmenu__override {
    .wz-universe {
      display: flex;
      .wz-logo {
        background-position: -36px -32px;
        background-repeat: no-repeat;
        background-size: 225px;
        width: 180px;
      }
    }
  }

  .wz-module-override {
    margin-right: 0.5em;
  }
}
/** TopBar **/
/* Menu */
.wz-override-menu {
  .wz-nav-menu__active {
    .wz-nav-item-button {
      button {
        color: rgb(var(--primary-color));
        background-color: rgb(var(--alpha-color));
      }
    }
  }

  .wz-nav-item {
    .wz-nav-item-container {
      align-items: center;
      .wz-nav-text {
        i.wz-font {
          margin-right: 10px;
        }
      }
    }

    & > a, & > button {
      border-radius: 5px;
      padding: 8px 10px 8px 7px;
    }
    &:hover > a, :hover> button {
      background-color: rgb(var(--alpha-color));
    }
    &.wz-nav-item__active {
      & > a, & > button {
        color: rgb(var(--primary-color));
        background-color: rgb(var(--alpha-color));
      }
    }
  }

  .wz-nav-text {
    display: flex;
    align-items: center;
    i.wz-font.wz-font-regular {
      font-size: 1.1em!important;
      display: inline-block;
      width: 24px!important;
      min-width: 24px;
    }
  }

  //same rule in Nacre, to replace by that
  .wz-nav-items .wz-nav-items-body .wz-nav-item .wz-nav-text {
    padding-left: 35px;
  }

  .wz-menu-frame {
    padding: 15px 6px 0;
  }

  /* Animation open/close section menu */
  .wz-nav-items > .wz-nav-items-body:not(.wz-nav-items__show) {
    opacity: 0;
    display: block;
    max-height: 0em;
    overflow: hidden;
    @include transition-element(opacity 0.2s ease-in-out, max-height 0.3s ease-in-out 0.1s);
  }

  .wz-nav-items > .wz-nav-items-body.wz-nav-items__show {
    @include transition-element(max-height 0.5s ease-in-out, opacity 0.2s ease-in-out 0.2s);
    max-height: 50em;
    opacity: 1;
  }
}

//container cta
.wz-menu__container-cta {
  background-color: #FFFFFF;
  padding: 10px 6px 0;
  .wz-framebutton {
    padding: 10px 16px 9px;
    text-align: center;
    &.wz-framebutton-with-icon.wz-framebutton-with-only-icon-mobile .wz-framebutton__label {
      display: inline-block;
      width: 100%;
    }

    .wz-framebutton__icon .wz-font.wz-font-regular {
      font-size: 1.3em;
      margin-top: 1px;
    }
  }
}

.menu-separator-container {
  color: #878c9b;
  font-weight: 500;
  margin: 10px 0 4px;
  padding: 1px 8px;
}
/* Menu */

/* Menu with props alternative */
.wz-menu-alternative {
  background-color: #FFFFFF;
  height: 100%;
  overflow-y: auto;
  .wz-nav-text {
    i.wz-font.wz-font-regular {
      font-size: 1.4em!important;
    }
  }

  .wz-nav-menu__active {
    .wz-nav-item-button {
      button {
        background-color: rgb(var(--lighter-color));
      }
    }
  }

  .wz-nav-item {
    &:hover > a, &:hover > button {
      background-color: rgb(var(--lighter-color))!important;
    }
    &.wz-nav-item__active {
      & > a, & > button {
        background-color: rgb(var(--lighter-color))!important;
      }
    }
  }
}
/* Menu with props alternative */

/* MenuContainer with props optionalMenu */
.wz-sidebar-maxi-w-optional-menu {
  .wz-leftmenu {
    .wz-leftmenu-content {
      margin-left: 50px;
    }

    .wz-leftmenu-optional {
      margin-left: 0;
    }
  }
}

@media screen and (min-width: 960px) {
  .wz-leftmenu-optional {
    + .wz-leftmenu-content:not(.wz-leftmenu-optional) {
      opacity: 1;
      @include transition-element(opacity 0.3s);
    }
    &:hover + .wz-leftmenu-content:not(.wz-leftmenu-optional) {
      opacity: 0.5;
      @include transition-element(opacity 0.3s);
    }
  }
}

.wz-leftmenu-optional {
  width: 50px;
  position: fixed;
  z-index: 15;
  @include transition-element(width 0.3s ease-in-out 0.2s);
  .wz-menu__container-cta {
    .wz-framebutton {
      padding: 10px 7px 9px;
      &.wz-framebutton-with-only-icon-mobile .wz-framebutton__label {
        opacity: 0;
        width: 0;
        min-height: 15px;
        max-height: 15px;
      }
    }
  }

  .wz-menu {
    overflow-x: hidden;
  }

  .wz-nav-text {
    i.wz-font.wz-font-regular {
      font-size: 1.4em!important;
    }
  }

  .wz-menu-frame > .wz-nav > .wz-nav-item .wz-nav-item-label {
    @include transition-element(opacity 0.2s ease-in-out, max-height 0.2s ease-in-out);
    display: block;
    opacity: 0;
    max-height: 0;
    overflow: hidden;
  }

  .wz-nav-items > .wz-nav-items-body.wz-nav-items__show {
    max-height: 0;
    overflow: hidden;
    opacity: 0;
    @include transition-element(opacity 0.1s ease-in-out, max-height 0.5s ease-in-out);
  }

  .menu-separator-container {
    max-height: 0;
    border-bottom: 1px solid #ced0da;
    margin: 1px 9px 7px;
    overflow: hidden;
    @include transition-element(border 0s ease-in-out 0.4s, margin 0.2s ease-in-out 0.3s, max-height 0.2s ease-in-out 0.3s);
    .menu-separator {
      opacity: 0;
      @include transition-element(opacity 0.2s ease-in-out);
    }
  }

  &:hover {
    width: 260px;
    transition: width 0.3s ease-in-out;
    .wz-menu__container-cta {
      .wz-framebutton {
        padding: 10px 16px 9px;
        .wz-framebutton__label {
          @include transition-element(opacity 0.2s ease-in-out 0.3s);
          opacity: 1;
          width: 100%;
        }
      }

    }

    .wz-menu-frame > .wz-nav > .wz-nav-item .wz-nav-item-label {
      @include transition-element(opacity 0.2s ease-in-out 0.3s, max-height 0.3s ease-in-out 0.2s);
      opacity: 1;
      max-height: 50em;
    }

    .wz-nav-items > .wz-nav-items-body.wz-nav-items__show {
      max-height: 50em;
      opacity: 1;
      @include transition-element(max-height 0.7s ease-in-out 0.1s, opacity 0.3s ease-in-out 0.3s);
    }

    .menu-separator-container {
      margin: 10px 0 4px;
      max-height: 5em;
      border-bottom: none;
      @include transition-element(border 0s ease-in-out 0.3s, margin 0.2s ease-in-out, max-height 0.3s ease-in-out 0.2s);
      .menu-separator {
        opacity: 1;
        @include transition-element(opacity 0.2s ease-in-out 0.3s);
      }
    }
  }
}
/* MenuContainer with props optionalMenu */



/* Timeline */
.wz-timeline__event {
    margin-left: 13px;
    display: grid;
    margin-bottom: 20px;
    position: relative;

    &:before {
        content: '';
        position: absolute;
        top: 1px;
        height: 99%;
        border-left: 2px solid $darkerLightGrey;
    }

    .wz-timeline-item {
        position: relative;
        padding-left: 20px;
    }

    .wz-timeline-icon i {
        color: #5e636e;
        position: absolute;
        left: -13px;
        font-size: 16px;
        background-color: #f8f9fc;
        top: 1px;
        &.wz-font.wz-font-circular {
            -webkit-box-shadow: 0 0 0 0.1em $darkerLightGrey inset;
            box-shadow: 0 0 0 0.1em $darkerLightGrey inset;
        }
        &:before {
            line-height: 1.1;
        }
    }
    .wz-timeline__date {
        position: relative;
        top: 7px;
    }

    &:first-of-type {
        margin-top: 0;
    }

    &:after {
        content: '';
        position: relative;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: $darkerLightGrey;
        right: 3px;
    }
}
/* Timeline */

/* SubCardTimeline */
.wz-sub-card-timeline {
    margin-bottom: 12px;
    &:first-of-type {
        margin-top: 25px;
    }
    &:last-of-type {
        margin-bottom: 0;
    }
    .wz-tooltip {
        margin-left: 0;
    }
}

.wz-sub-card-title {
    display: inline;
    .wz-infotext__subtitle {
        font-weight: normal;
    }
}

span.wz-sub-card-header__hour {
    display: inline;
    font-weight: normal;
}
/* SubCardTimeline */


// Common css
// These files are created to centralize all css for common components
/* Calendar */
.wz-calendar {
	overflow: hidden;
	width: 276px;
	height: 255px;
}
/* Calendar */

/* Common Date input and date input range */
//Dropdown list container
.wz-date-picker ul.wz-commondropdown-list__date-picker {
	overflow: hidden!important;
	height: 250px!important;
	padding: 0;
	width: 278px;
}

//Input container
.wz-date-picker .wz-popin__container {
	width: 250px;
}


//Buttons change month
.wz-date-picker .DayPickerNavigation {
	top: 12px;
	left: 211px;
}

.wz-date-picker .DayPickerNavigation_button {
	display: inline;
}
.wz-date-picker .DayPickerNavigation_button_1 {
	margin-right: 8px;
}


//Calendar month + days
.wz-date-picker .CalendarMonthGrid {
	left: -12px
}

//Calendar month year
.wz-date-picker .CalendarMonth_caption {
	font-size: 12px;
	text-align: left;
	padding: 12px 0px 30px 8px;
	text-transform: capitalize;
}

.wz-date-picker__select-month-year {
	display: flex;
	justify-content: left;
}

.wz-date-picker__select-month {
	margin-right: 3px;
}

.wz-date-picker__select-month:hover, .wz-date-picker__select-year:hover {
	@include transition-element(.3s);
	cursor: pointer;
	background-color: rgb(var(--alpha-color));
	color: rgb(var(--primary-color));
	outline: none;
}

.wz-date-picker__select-month:focus,
.wz-date-picker__select-month:active,
.wz-date-picker__select-year:focus,
.wz-date-picker__select-year:active {
	outline: none;
}

.wz-date-picker__option-month {
	text-transform: capitalize;
}

//Calendar week
.wz-date-picker .DayPicker_weekHeader {
	top: 39px;
	padding: 0!important;
	left: -8px!important;
}

.wz-date-picker .DayPicker_weekHeader_li {
	text-transform: capitalize;
}


//Hide help
.wz-date-picker button.DayPickerKeyboardShortcuts_show {
	display: none;
}

//Calendar days
.wz-date-picker td {
	font-size: 12px;
	width: 39px!important;
	height: 31px!important;
	display: inline-block;
	padding: 2px 6px;
	text-align: center;
	background-color: #FFFFFF;
	border: 1px solid #FFFFFF;
	&:hover, &.CalendarDay__selected, &.CalendarDay__selected:hover  {
		background-color: #FFFFFF;
		border: 1px solid #FFFFFF!important;

	}
}

.wz-date-picker td > span {
	display: inline-block;
	width: 23px!important;
	height: 23px!important;
	padding-top: 5px;
	font-size: 12px;
	border-radius: 50%;
}

.wz-date-picker__select-month, .wz-date-picker__select-year {
	//border: 1px solid $darkerLightGrey;
	border-radius: 5px;
	font-family: 'Graphik';
	font-weight: normal;
	padding: 3px 5px;
	border: none;
}

.wz-date-picker td.CalendarDay__default {
	&:not(.CalendarDay__blocked_calendar):not(.CalendarDay__blocked_out_of_range):hover > span {
		background-color: rgb(var(--alpha-color));
		color: rgb(var(--primary-color));
	}
	&:not(.CalendarDay__selected).CalendarDay__highlighted_calendar > span {
		color: rgb(var(--primary-color));
	}
}

.wz-date-picker td.CalendarDay__selected > span, .wz-date-picker td.CalendarDay__selected:active > span, .wz-date-picker td.CalendarDay__selected:hover > span {
	background-color: rgb(var(--primary-color));
	color: #FFFFFF;
}

.wz-date-picker td.CalendarDay__blocked_calendar > span {
	color: #cacccd;
}

.wz-date-picker td.CalendarDay__selected_span > span {
	background-color: rgb(var(--alpha-color));
	color: rgb(var(--primary-color));
}

.wz-date-picker td.CalendarDay__selected_span:hover > span {
}

.wz-date-picker td.CalendarDay__hovered_span > span {
	background-color: rgb(var(--alpha-color));
	color: rgb(var(--primary-color));
}

.wz-date-picker .DayPickerNavigation_button__disabled,
.wz-date-picker .DayPickerNavigation_button__disabled:hover,
.wz-date-picker .DayPickerNavigation_button__disabled:focus {
	border-color: #FFFFFF;
}
/* Common Date input and date input range */

/* Specific Date input */
.wz-date-input {
	border: 1px solid $darkerLightGrey;
	border-radius: 5px;

	.wz-font.wz-font-cross::before {
		top: 1px
	}
}

.wz-date-input:not(.disabled):hover, .wz-date-input__active {
	@include transition-element(.3s);
	border: 1px solid rgb(var(--primary-color));

	.wz-date-input__icon {
		color: rgb(var(--primary-color));
	}
}

.wz-date-input__active {
	@include box-shadow(0 2px 5px -2px rgb(var(--primary-color)));
}

.wz-date-input input.wz-date-input__date,
.wz-date-input input.wz-date-input__date:hover,
.wz-date-input input.wz-date-input__date:focus,
.wz-date-input input.wz-date-input__date:active
{
	box-shadow: none!important;
	border: none!important;
}

.wz-date-input__errors, .wz-date-input__errors:hover {
	border: 1px solid $inputErrorColor;
	box-shadow: none;
	.wz-date-input__icon {
		color: $darkerLightGrey;
	}

}
/* Specific Date input */

/* Specific Date range */
.wz-date-range-input {
	display: flex;
	border: 1px solid $darkerLightGrey;
	border-radius: 5px;

	.wz-font.wz-font-cross::before {
		top: 1px
	}
}

.wz-date-range-input input.wz-date-input__start,
.wz-date-range-input input.wz-date-input__start:hover,
.wz-date-range-input input.wz-date-input__start:focus,
.wz-date-range-input input.wz-date-input__start:active
{
	box-shadow: none!important;
	border: none!important;
}

.wz-date-range-input input.wz-date-input__end,
.wz-date-range-input input.wz-date-input__end:hover,
.wz-date-range-input input.wz-date-input__end:focus,
.wz-date-range-input input.wz-date-input__end:active {
	box-shadow: none!important;
	border: none!important;
}

.wz-date-range-input:not(.disabled):hover, .wz-date-range-input__active {
	@include transition-element(.3s);
	border: 1px solid rgb(var(--primary-color));

	.wz-date-input__icon {
		color: rgb(var(--primary-color));
	}
}

.wz-date-range-input__active {
	@include box-shadow(0 2px 5px -2px rgb(var(--primary-color)));
}

.wz-date-input__errors, .wz-date-input__errors:hover {
	border: 1px solid $inputErrorColor;
	box-shadow: none;
	.wz-date-input__icon {
		color: $darkerLightGrey;
	}

}

.wz-date-input__start:hover, .wz-date-input__start:focus, .wz-date-input__end:hover, .wz-date-input__end:focus {
	color: rgb(var(--primary-color));
}

/* Specific Date range */

/*
 * CSS for the dropdown
 */

// This is the frame that gather the selector and items
// inside the Common/Dropdown
.wz-commondropdownframe {
  height: 24px;
  position: relative;
  width: 20px;
}

.wz-commondropdown {
  @include border-radius($borderRadiusCommonWidth);
  @include transition-element(background $transitionColor ease);
  background-color: transparent;
  cursor: pointer;
  height: 25px;
  position: relative;
  width: 25px;

  // disabled the dropdown
  &.wz-commondropdown__disabled {
    cursor: default;
    opacity: $disabledOpacity;

    .wz-commondropdown__selectors,
    .wz-commondropdown__selectors::before,
    .wz-commondropdown__selectors::after,
    &:hover .wz-commondropdown__selectors,
    &:hover .wz-commondropdown__selectors::before,
    &:hover .wz-commondropdown__selectors::after {
      background-color: $greyFourColor;
    }
  }

  &__selectors::before {
    margin-left: -7px;
  }

  &__selectors::after {
  	margin-left: 7px;
  }

  /*Horizontal dropdown for tablecell*/
  .wz-tablecelldropdown &__selectors::before {
    margin-left: -7px;
    margin-top: 0;
  }

  .wz-tablecelldropdown &__selectors::after {
    margin-left: 7px;
    margin-top: 0;
  }
  /**/

  &__selectors::before,
  &__selectors::after {
    content: "";
    position: absolute;
  }

  &__selectors,
  &__selectors::before,
  &__selectors::after {
    @include border-radius($borderRadiusSelectorsWidth);
    background-color: $black;
    height: 4px;
    width: 4px;
  }

  // Set the background-color of the container with selectors
  &:hover,
  &.wz-commondropdown__active {
    background-color: rgb(var(--alpha-color));
  }

  &:hover .wz-commondropdown__selectors,
  &:hover .wz-commondropdown__selectors::before,
  &:hover .wz-commondropdown__selectors::after,
  &.wz-commondropdown__active .wz-commondropdown__selectors,
  &.wz-commondropdown__active .wz-commondropdown__selectors::before,
  &.wz-commondropdown__active .wz-commondropdown__selectors::after {
    background-color: rgb(var(--darker-color));
  }

  &__selectors {
    margin-left: 10px;
    margin-top: 10px;
    position: absolute;
  }
}

.wz-commondropdown-list {
  @include border-radius($borderRadiusCommonWidth);
  @include box-shadow(0px 1px 7px 0 rgba(0, 0, 0, 0.12));
  @include scrollbar();
  background-color: $white;
  border-style: solid;
  border-color: $darkerLightGrey;
  border-width: 1px;
  cursor: default;
  margin: 0;
  max-height: 340px;
  padding: 10px 8px;
  overflow: auto;
  //z-index: 12;

	  &.wz-commondropdown-list__mini {
		width: 150px;
	  }

	  &.wz-commondropdown-list__medium {
		width: 250px;
	  }

      &.wz-commondropdown-list__large {
        width: 290px;
      }

	  &.wz-commondropdown-list__maxi {
        width: 300px;
	  }

      &.wz-commondropdown-list__full {
        width: 100%;
      }

  &.wz-commondropdown-list__hide {
      display: none;
  }

  /*
   * Common styles for the dropdownItem
   */
  .wz-dropdowncommonitem:not(.wz-dropdowncommonitem__disabled) {
    @include transition-element(background $transitionColor ease);
    cursor: pointer;

    &:hover {
      @include background-opacity(var(--medium-color), 0.13);
      color: rgb(var(--medium-color));

		&.wz-dropdowncommonitem__delete-item {
			background-color: unset;
		}
    }
  }

  .wz-dropdowncommonitem.wz-dropdowncommonitem__active {
    @include background-opacity(var(--medium-color), 0.13);
    color: $black;
  }

  li,
  .wz-dropdowncommonitem {
    @include border-radius($borderRadiusCommonWidth);
    list-style: none;
    position: relative;

    // Customize if the item is/contains a button
    button {
      background-color: transparent;
      border: none;
      cursor: pointer;
      text-align: inherit;
      width: 100%;
    }
  }

  .wz-itemicons {
    .wz-itemicons-first,
    .wz-itemicons-last {
      margin: 0;
      text-align: inherit;
    }

	  &.wz-itemicons-checked {
		  .wz-item {
			  color: rgb(var(--primary-color));
		  }
	  }

    .wz-itemicons-html{
        display: inline-block;
    }

    // Set te pellet's styles
    .wz-pellet {
      float: right;
	  margin-top: -2px;
	  padding-top: 3px;
    }

    // Manage the right icon
    .wz-itemicons-last {
      float: right;
      margin-left: .5em;

      // To style the check icon if the prop checked exists
      &__checked {
        color: rgb(var(--primary-color));
        text-align: center;
      }
    }
  }

  // Swipe to main screen
  // display/hide the right icon
  .wz-dropdownitem-swipelink {
    .wz-itemicons-last {
      opacity: 0;
    }

    &:hover .wz-itemicons-last {
      opacity: 1;
    }
  }

  // Set a margin to the BackTo
  .wz-dropdownitem-backto {
    margin-bottom: 5px;

    .wz-item {
      display: flex;
    }
    i.wz-font {
      position: relative;
      right: 5px;
    }
  }

  .wz-item__actions {
    position: absolute;
    width: 100%;
    height: 100%;

    .wz-item__disabled {
      background-color: $lightGrey;
      position: absolute;
      opacity: .5;
      width: 100%;
      height: 100%;
    }
  }

	/*
	* Styles tp customize DropdownLink cmp
	*/
	.wz-dropdowncommonitem.wz-dropdowncommonitem-external-link {
		&:hover {
			background-color: transparent;
		}
		.wz-item {
			padding: .4em .8em;

			&:hover {
				color: $primaryColor;
			}

			.wz-dropdowncommonitem-external-title {
				vertical-align: middle;
			}

			.wz-dropdowncommonitem-icon {
				padding-right: 6px;

				.wz-font {
					margin-right: 0;
				}
			}

			.wz-font.wz-font-external-link {
				font-size: .5em;
				margin-left: .5em;
				margin-bottom: 1px;
				vertical-align: bottom;
			}
		}

	}
  /*
   * Styles to customize the DropdownTitle cmp
   */
  .wz-dropdowncommonitem.wz-dropdowncommonitem-title {
    cursor: default;

    &:hover {
      background-color: inherit;
    }

    // DropdownTitle
    h1 {
      color: $fontColor;
      font-size: $mediumFontSize;
      font-weight: 500;
      padding: 0 .5em;
    }

    // DropdownSubTitle
    h3 {
      color: $greyFourColor;
      text-transform: uppercase;
      font-size: $smallFontSize;
    }

    // DropdownText
    p {
      color: $greyFourColor;
      font-size: $normalFontSize;
      padding: .6em;
    }
  }

  .wz-item {
    @include transition-element(color $transitionColor ease);
    color: $fontColor;
    display: block;
    padding: .5em .8em;
    line-height: unset;
	user-select: none;
    font-size: 14px;
    font-weight: normal;

	  &.wz-item__delete:hover {
		  color: #F4080A;
	  }

	  &:hover, &:hover .wz-item__subtitle {
      color: rgb(var(--darker-color));
    }

	.wz-item__2-lines {
	  @include line-clamp(2);
	  line-height: 14px;
      padding-top: 3px;
    }

	  .wz-item__content {
		  flex-grow: 4;
		  padding-right: 5px;

	  }
    .wz-item__subtitle {
		padding-top: 5px;
		font-size: 10px;
		color: $greyFourColor;
	}
	&.wz-item__with_status {
		display: inline-flex;
		width: 100%;

		.wz-status {
			margin-bottom: 0;
			cursor: pointer;
		}
	}
  }
  .wz-item__search {
	  margin-bottom: 10px;

	  .wz-input {
		  cursor: text;
		  font-size: 10px;
	  }
  }
  .wz-dropdownline {
    background-color: $greyTwoColor;
    height: 1px;
    margin: .5em 0;
  }

  // Set the length of the button inside the dropdown
  .wz-framebutton {
    margin-top: .5em;
    text-align: center;
    width: 100%;
  }

  // Changes the structure of the checkbox inside the wz-commondropdown-list
  .wz-field-block-checkbox {
    @include border-radius($borderRadiusCommonWidth);
    @include transition-element(all $transitionColor ease);
    margin-bottom: 0px;

    &:hover {
      @include background-opacity(var(--medium-color), 0.13);

      // If checkbox is checked we set the new border-color
      .wz-input-checkbox:checked:hover {
        border-color: rgb(var(--primary-color));
      }

      // Otherwise we remove the border-color as default
      .wz-input-checkbox:hover {
        border-color: $darkerLightGrey;
      }
    }

    .wz-ui-input {
      margin-right: 10px;
      padding: 5px 0px 0px 5px;

	  .wz-input-checkbox {
		margin-top: 5px;
	  }
    }

    .wz-input-label {
      padding: 7px 0 5px;
    }
  }
}

.wz-popin__hide {
  display: none;
}

.wz-dropdown-status__disabled {
	filter: grayscale(1);
	.wz-status {
		cursor: not-allowed;
	}
}

.wz-popin.wz-popin__select .wz-commondropdown-list {
  border-color: rgb(var(--primary-color));
  overflow: auto;
  max-height: 250px;
  width: 100%;
  @include box-shadow(none);
}

@keyframes grow {
  0% {
    transform: scaleY(0)
  }
  100% {
    transform: scaleY(1)
  }
}

//.wz-popin[data-popper-placement^='bottom'] > .wz-commondropdown-list {
//  animation: grow 200ms ease-out forwards;
//  transform-origin: top center;
//}
//
//.wz-popin[data-popper-placement^='top'] > .wz-commondropdown-list {
//  animation: grow 200ms ease-out forwards;
//  transform-origin: bottom center;
//}

.wz-popin.wz-popin__select[data-popper-placement^='top'] > .wz-commondropdown-list {
  border-bottom: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.wz-popin.wz-popin__select[data-popper-placement^='bottom'] > .wz-commondropdown-list {
  border-top: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
/* Current Dev Refacto Dropdown */

// Scroll block
.wz-block-scroll {
  @include scrollbar();
  margin-top: .5em;
  overflow: auto;

  // Heights of the block
  &__mini {
    height: 150px;
  }

  &__medium {
    height: 200px;
  }

  &__maxi {
    height: 250px;
  }

  &__auto {
    height: auto;
  }

  // Heights of the block
  &_max-height__mini {
    max-height: 150px;
  }

  &_max-height__medium {
    max-height: 200px;
  }

  &_max-height__maxi {
    max-height: 250px;
  }

  &_max-height__none {
    max-height: none;
  }
}

// Container for the screens
.wz-swipe-container {
  overflow: hidden;
  position: relative;
  visibility: visible;
}

// The wrapper of the screens
.wz-swipe-wrapper {
  overflow: auto;
  position: relative;
}

// This is the screen
.wz-swipe-screen {
  @include scrollbar();
  @include transition-element(all $transitionColor ease);
  float: left;
  left: 0;
  overflow: auto;
  position: relative;
  transform: translateX(0px);
  width: 100%;
}
//Dropdown Sort
.wz-dropdown-sort__container {
	display: inline-block;
	left: -5px;
	position: relative;

	.wz-dropdown-sort {
		display: inline-block;
		@include transition-element(background .2s ease-in-out, color .2s ease-in-out, border .2s ease-in-out);
		border-radius: 5px;
		padding: 5px;
		user-select: none;
		cursor: pointer;

		&:hover, &.wz-dropdown-sort__show {
			background-color: $alphaColor;
			color: $primaryColor;
		}

		i.wz-font {
			margin-left: 3px;
		}
		.wz-item {
			font-weight: normal;
		}

		.wz-dropdown-sort__text {
			display: inline-flex;
			align-items: flex-end;

			&.wz-dropdown-sort__active {
				color: $primaryColor;
			}
		}
	}
}

//* SelectHierarchy *//
.wz-select-hierarchy {
  user-select: none;

  &:hover:not(wz-select-input__active) {
    cursor: pointer;

    .wz-ui-input:not(.wz-ui-input__disabled):not(.wz-ui-input__errors) {
      .wz-input-text{
        border-color: rgb(var(--primary-color));
      }
      .wz-input-icon__right {
        color: rgb(var(--primary-color));
      }
    }

    .wz-ui-input__errors {
      .wz-input-text {
        border-color: rgb(var(--primary-color));
      }
    }
  }

  .wz-ui-input__errors {
    .wz-input-icon__right > i {
      color: #FFFFFF;
    }
  }
}


.wz-popin.wz-popin__select .wz-select-hierarchy__list.wz-commondropdown-list {
  max-height: 500px;
  padding: 0;
  .wz-dropdowncommonitem.wz-hierarchy__item-container {
    padding: 0;
  }

  .wz-itemicons-checked {
    .wz-item {
      display: flex;
      justify-content: space-between;
    }
  }

  .wz-hierarchy__group-custom:hover {
    background-color: #FFFFFF;
    .wz-item:hover {
      color: black;
    }
  }

  .wz-hierarchy__item-ellipsis {
    .wz-itemicons-html {
      width: 100%;
    }
  }

  .wz-hierarchy__item-disabled, .wz-hierarchy__group-disabled {
    color: $inputDisabledGrey;
    .wz-hierarchy__checkbox .wz-input-perf {
      background-color: $lightGrey;
      border-color: #e7e8ec;
    }
  }

  .wz-framebutton.wz-select-hierarchy__footer-cta {
    width: auto;
  }

  .wz-select-hierarchy__select-all.wz-field-block-checkbox:hover {
    background-color: #FFFFFF;
  }
}

@media screen and (max-width: $widthMobile) {
  .wz-popin.wz-popin__select .wz-select-hierarchy__list.wz-commondropdown-list {
    .wz-hierarchy__item-ellipsis.wz-itemicons-checked {
      .wz-itemicons-html {
        width: 94%;
      }
    }
  }
}


.wz-select-hierarchy__footer {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid $darkerLightGrey;
  padding: 0 12px 8px;
}

.wz-select-hierarchy__list .wz-select-hierarchy__footer .wz-field-block-checkbox .wz-ui-input {
  padding-left: 0;
  .wz-input-checkbox{
    -webkit-transition: none;
    -moz-transition: none;
    -ms-transition: none;
    -o-transition: none;
    transition: none;
  }
}
//* End SelectHierarchy *//


//* TranslateDropdown *//
.wz-translate-dropdown {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 6px;
  cursor: pointer;
  i.wz-font{
    display: flex;
    align-items: center;
  }
  .wz-ui-input.wz-ui-input-icon-left .wz-input {
    padding-left: 2.2em;
  }



  .wz-input.wz-input-text{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  &__right{
    display: flex;
    justify-content: center;
    align-items: center;
    &--number-selected{
      margin-right: 0.5rem;
    }
  }
  &__center{
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
    margin: 0 .3rem
  }


}
.wz-translate-dropdown__list .wz-block-scroll {
  height: auto;
}

.wz-translate-dropdown__center, .wz-translate-dropdown__right {
  position: relative;
  top: 2px
}

.wz-translate-dropdown__left {
  position: relative;
  top: .5px
}

.wz-translate-dropdown__right i.wz-font.wz-font-chevron-bottom {
  align-self: baseline
}

.wz-translate-dropdown__item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  .wz-ui-input{
    width: auto;
    .wz-input.wz-input-checkbox{
      border-radius: 50%;
      cursor: default;
      border-color: $darkerLightGrey;
      &:checked{
        //background-color: #ffff;
        border-color: $darkerLightGrey;
        background-color: #f2f2f2;
      }
      &::after{
        border-color: #000000;
      }
    }
  }

  &-selected{
    color: rgb(var(--primary-color));
  }
}
//* End TranslateDropdown *//


// START ValidateIcon
.wz-commonvalidateicon {
  margin-top: 0px;
  padding: 0;
}
// END ValidateIcon


i.wz-font{
	display: inline-block;
	opacity: 1;
	margin: 0;
	text-align: center;
	speak: none;

	&.wz-font-margin {
		margin: 0 .25em 0 0;
	}

	&.wz-font-circular {
		border-radius: 500em;
		padding: .45em 0;
		-webkit-box-shadow: 0 0 0 0.1em rgb(0 0 0 / 10%) inset;
		box-shadow: 0 0 0 0.1em rgb(0 0 0 / 10%) inset;
		width: 2em;
		height: 2em;
	}

	&.wz-font-inverted {
		color: #fff;
	}

	&.wz-font-disabled {
		-webkit-filter: grayscale(1);
		filter: grayscale(1);
		opacity: .5;
		cursor: not-allowed !important;
	}

	&.wz-font-click {
		cursor: pointer;
		@include transition-element(all $transitionColor ease);
		-webkit-box-shadow: 0 0 0 0.1em transparent inset;
		box-shadow: 0 0 0 0.1em transparent inset;

		&:hover {
			color: $primaryColor;
			background-color: $alphaColor;

		}

		&.wz-font-target:hover {
			color: $primaryTargetColor;
			background-color: $alphaTargetColor;
		}
		&.wz-font-ticket:hover {
			color: $primaryTicketColor;
			background-color: $alphaTicketColor;
		}
		&.wz-font-pay:hover {
			color: $primaryPayColor;
			background-color: $alphaPayColor;
		}
		&.wz-font-access:hover {
			color: $primaryAccessColor;
			background-color: $alphaAccessColor;
		}
	}

	&.wz-font-circular.wz-font-inverted {
		background-color: #1b1c1d;
		color: #fff;
		border: none;
		-webkit-box-shadow: none;
		box-shadow: none;

		&.wz-font-target {
			background-color: $primaryTargetColor;
		}
		&.wz-font-pay {
			background-color: $primaryPayColor;
		}
		&.wz-font-ticket {
			background-color: $primaryTicketColor;
		}
		&.wz-font-access {
			background-color: $primaryAccessColor;
		}
	}
	&.wz-font-regular {
		font-size: 1em;
		vertical-align: middle;
	}
	&.wz-font-small {
		font-size: .75em;
		vertical-align: middle;
	}
	&.wz-font-mini {
		font-size: .5em;
		vertical-align: middle;
	}
	&.wz-font-huge {
		font-size: 2em;
		vertical-align: middle;
	}
	&.wz-font-target {
		color: $primaryTargetColor;
	}
	&.wz-font-pay {
		color: $primaryPayColor;
	}
	&.wz-font-ticket {
		color: $primaryTicketColor;
	}
	&.wz-font-access {
		color: $primaryAccessColor;
	}

	// Overide some icon - Visual purpose

	&.wz-font-image-upload:before {
		padding-left: 2px;

		&.wz-font-small {
			padding-left: 1px;
		}
	}

	&.wz-font-caret-left:before, &.wz-font-chevron-left:before {
		margin-left: -2px;
	}

	&.wz-font-caret-right:before, &.wz-font-chevron-right:before {
		margin-left: 2px;
	}
}

i.wz-flag{
	display: inline-block;
	opacity: 1;
	margin: 0 .25em 0 0;
	text-align: center;
	speak: none;
	height: 1em;
	width: undefined;
	aspect-ratio: 717 / 511;
	background-size: contain;
	background-repeat: no-repeat;
	border-radius: 2px;

	&.wz-flag-click {
		cursor: pointer;
		@include transition-element(all $transitionColor ease);
		-webkit-box-shadow: 0 0 0 0.1em transparent inset;
		box-shadow: 0 0 0 0.1em transparent inset;
	}

	&.wz-flag-regular {
		font-size: 1em;
		vertical-align: top;
	}
	&.wz-flag-small {
		font-size: .75em;
		vertical-align: top;
	}
	&.wz-flag-mini {
		font-size: .5em;
		vertical-align: top;
	}
	&.wz-flag-huge {
		font-size: 2em;
		vertical-align: top;
	}
	// All flag available
	&.wz-flag-fr {
		background-image: url('./icons/flag-FR.svg');
	}
	&.wz-flag-es {
		background-image: url('./icons/flag-ES.svg');
	}
	&.wz-flag-ch {
		background-image: url('./icons/flag-CH.svg');
	}
	&.wz-flag-gb {
		background-image: url('./icons/flag-GB.svg');
	}
	&.wz-flag-ca {
		background-image: url('./icons/flag-CA.svg');
	}
	&.wz-flag-it {
		background-image: url('./icons/flag-IT.svg');
	}
	&.wz-flag-us {
		background-image: url('./icons/flag-US.svg');
	}
	&.wz-flag-de {
		background-image: url('./icons/flag-DE.svg');
	}
}

.wz-graph-line {
	.wz-graph-line__counter {
		margin-bottom: 5px;
	}
	.wz-infotext span {
		display: inline;
	}
	.wz-infotext > span {
		display: block;
	}
	.wz-tooltip, .wz-tooltip-without-margin {
		width: 100%;

		.wz-popin__container {
			width: 100%;
		}
	}
	.wz-graph-line__bar {
		width: 100%;
		height: 6px;
		border-radius: 50px;
		overflow: hidden;
		background-color: #ced0da;
		position: relative;

		.wz-graph-line__value {
			position: absolute;
			height: 6px;
			border-radius: 50px;
			//background-color: rgb(var(--primary-color));
			background-image: linear-gradient(to right, rgb(var(--gradient-color)) 18%, rgb(var(--primary-color)) 78%);
		}
	}
}

/*
 * Generic component to manage button
 * Like FrameButton
 */
// Html anchor element
a.wz-framebutton {
	text-decoration: none;

	// For regular button
	&.wz-button {
		color: $white;

		&:hover {
			color: $white;
		}

		&.wz-button__inverted {
			background-color: $white;
			color: $darkGrey;
		}
	}

	// For primary
	&.wz-button-primary.wz-button__inverted {
		color: rgb(var(--primary-color));

		&:hover {
			background-color: $white;
			border-color: rgb(var(--darker-color));
			color: rgb(var(--darker-color));
		}
	}

	&.wz-button-secondary,
	&:hover.wz-button-secondary,
	&.wz-button-secondary.wz-button__inverted,
	&:hover.wz-button-secondary.wz-button__inverted {
		color: $black;
	}
}

.wz-framebutton {
	cursor: pointer;
	position: relative;
	justify-content: center;
	text-align: center;
	user-select: none;

	&.wz-framebutton__disabled {
		cursor: not-allowed;

		// Avoid to let the border-color with same color without opacity
		// on disabled/loading
		&.wz-button__inverted {
			border-color: rgba($darkGrey, .7);

			&.wz-button-primary {
				border-color: rgba(var(--primary-color), .3);
			}
		}
	}

	&__inactive {
		background-color: $lightGrey;
		height: 100%;
		left: 0;
		opacity: .7;
		position: absolute;
		top: 0;
		width: 100%;
	}

	/* make width take all container CTA */
	&__fluid {
		width: 100%;
		justify-content: center;

		&:not(:last-child){
			margin-bottom: 10px;
		}
	}

	/* align text in button */
	&__left {
		text-align: left;

		&.wz-framebutton__fluid {
			justify-content: flex-start;
		}
	}

	&__right {
		text-align: right;

		&.wz-framebutton__fluid {
			justify-content: flex-end;
		}
	}

	// with icon
	&.wz-framebutton-with-icon{
		display: inline-flex;
		align-items: center;

		.wz-framebutton__icon {
			font-size: .8em;
			display: inline-block;

			> i {
				margin: 0;
			}
		}

		&.wz-framebutton-with-only-icon-mobile{
			display: inline-flex;
			align-items: center;

			.wz-framebutton__label {
				display: none;
			}
		}

		.wz-framebutton__label {
			margin-right: 5px;
		}

		&.wz-framebutton__align-icon-right {
			justify-content: space-between;
		}
	}

}

// Styles for the wz-button-group (.wz-popin__container for buttonDropdown)\\
.wz-button-group {
	.wz-button:not(:last-of-type), .wz-button-dropdown:not(:last-of-type), .wz-popin__container:not(:last-of-type) {
		margin-right: 15px;
	}
}

// Styles for the wz-button \\
.wz-button {
	@include border-radius($borderRadiusButtonWidth);
	@include transition-element(all $transitionColor ease);
	background-color: $buttonDefaultColor;
	border-width: 0;
	color: $black;
	display: inline-block;
	font-weight: normal;
	font-size: $mediumFontSize;
	line-height: inherit;
	padding: 12px 16px;

	&:hover {
		background-color: $buttonHoverColor;
	}

	// Inverted color
	&.wz-button__inverted {
		background-color: $white;
		border: solid 1px $darkGrey;
		border-color: $buttonHoverColor;
		color: $black;

		&:hover {
			background-color: $buttonDefaultColor;
			border-color: $buttonDefaultColor;
		}
	}

	/* Reduce size of the original CTA */
	&.wz-button__mini {
		padding: 5px 10px;
	}

	/* Show grey color on the CTA when it's disabled */
	&__disabled {
		background-color: $greyThreeColor;

		&:hover {
			background-color: $mediumGrey;
		}
	}

	// Remove focus on keyboard
	&:focus {
		outline-style: none;
	}
}
/* Styles for basic button that herits of wz-button */

.wz-button-basic {
	font-weight: normal;
	background-color: transparent;

	&.wz-button-primary {
		font-weight: normal;
		background-color: transparent;
		color: #000;

		&:hover {
			background-color: rgb(var(--alpha-color));
			color: rgb(var(--primary-color));
		}
	}
}

/* Styles for primary button that herits of wz-button */
.wz-button-primary {
	color: $white;
	font-weight: 500;
	background-color: rgb(var(--primary-color));

	&:hover {
		background-color: rgb(var(--darker-color));
	}

	/* Styles for inverted primary */
	&.wz-button__inverted {
		background-color: $white;
		border-style: solid;
		border-color: rgb(var(--primary-color));
		border-width: 1px;
		color: rgb(var(--primary-color));

		&:hover {
			background-color: $white;
			border-color: rgb(var(--darker-color));
			color: rgb(var(--darker-color));
		}
	}
}

.wz-button-dropdown {
	display: inline-flex;
	align-self: center;
	position: relative;
}

.wz-selectable-button.wz-button__inverted {
	color: black;
	border-color: $darkerLightGrey;
	height: 43px;
	&:hover {
		border-color: $darkerLightGrey;
		@include background-opacity(var(--primary-color), 0.1);
	}

	&.wz-selectable-button__active {
		color: rgb(var(--primary-color));
		border-color: rgb(var(--primary-color));
		&:hover {
			border-color: $darkerLightGrey;
		}
	}

	.wz-selectable-button__label {
		display: flex;
	}

	i.wz-selectable-button__icon {
		font-size: 1.1em;
	}

	.wz-selectable-button__text {
		display: none;
	}
}


.wz-sort-button {
	div.wz-dropdownButton-input {
		margin-right: 0;
	}
	&.wz-dropdown-input__active .wz-framebutton {
		color: rgb(var(--primary-color));
		border-color: rgb(var(--primary-color));
	}
	.wz-button__inverted {
		padding-left: 10px;
		height: 43px;
		.wz-sort-button__label {
			display: flex;
			align-items: center;
		}
		i.wz-sort-button__icon {
			font-size: 1.1em;
			margin-right: 10px;
		}
		&:hover {
			color: rgb(var(--primary-color));
			border-color: rgb(var(--primary-color));
			@include background-opacity(var(--primary-color), 0.1);
		}
	}
	.wz-framebutton__icon {
		display: none !important;
	}
	&:hover {
		background-color: transparent;
	}
}




// This mixin generates styles for custom button for BO
@each $BOName, $BOPrimaryColor, $BODarkerColor in
	(ticket, $primaryTicketColor, $darkerTicketColor),
	(target, $primaryTargetColor, $darkerTargetColor),
	(access, $primaryAccessColor, $darkerAccessColor),
	(pay, $primaryPayColor, $darkerPayColor) {
		// Fix color on inverted with "A"
		a.wz-framebutton.wz-button__#{$BOName}.wz-button__inverted {
			color: $BOPrimaryColor;

			&:hover {
				background-color: $white;
				border-color: $BODarkerColor;
				color: $BODarkerColor;
			}
		}

		// Add opacity on the border-color on disabled/loading
		.wz-framebutton.wz-framebutton__disabled.wz-button__#{$BOName}.wz-button__inverted {
			border-color: rgba($BOPrimaryColor, .3);
		}

		.wz-button__#{$BOName} {
			background-color: $BOPrimaryColor;

			&:hover {
				background-color: $BODarkerColor;
			}

			&.wz-button__inverted {
				@include transition-element(all $transitionColor ease);
				border-color: $BOPrimaryColor;
				color: $BOPrimaryColor;

				&:hover {
					background-color: $white;
					border-color: $BODarkerColor;
					color: $BODarkerColor;
				}
			}
		}
	}

@media screen and (min-width: $widthTablet) {
	.wz-framebutton.wz-framebutton-with-icon.wz-framebutton-with-only-icon-mobile {
		.wz-framebutton__label {
			display: inline-block;
		}
	}

	.wz-selectable-button.wz-button__inverted .wz-selectable-button__text {
		display: inline-block;
		margin-left: 12px;
	}
	.wz-selectable-button.wz-button__inverted {
		padding-left: 10px;
	}
}

/**
 * @see https://www.xfive.co/blog/itcss-scalable-maintainable-css-architecture/
 * Styles from Nacre
 */

// Component to manage the result total with label
.wz-total-result {
  font-size: $normalFontSize;
  margin: inherit;
  width: fit-content;

  span {
	  font-weight: bold;
    margin-left: .35em;
  }
}

// Component to manage reset text
.wz-reset {
  font-size: $normalFontSize;
  margin: inherit;
  width: fit-content;
  line-height: 15px;;

  &__click {
    cursor: pointer;
  }

  &__disabled {
    color: $blackAlpha02;
    cursor: not-allowed;
  }
}

// Component to manage uppercase cardLabel format
.wz-cardLabel__uppercase {
    text-transform: uppercase;
    letter-spacing: 1.68px;
}

// Component to manage cardLabel title format
.wz-title {
  color: $fontColor;
	font-weight: bold;
  font-size: $largeFontSize;
  line-height: 1.1;
  margin: 0 0 10px 0;
}

// Component to manage cardLabel subtitle format
.wz-subtitle {
  color: $greyFourColor;
  font-size: $smallFontSize;
  margin: 0 0 10px 0;
}

/**
 * @see https://www.xfive.co/blog/itcss-scalable-maintainable-css-architecture/
 * Styles from Nacre
 */
.wz-pellet {
  @include transition-element(all $transitionDefault ease);
  background-color: rgb(var(--primary-color));
  border-radius: 50%;
  color: $white;
  display: inline-block;
  font-size: $standardFontSize;
  font-weight: bold;
  height: 19px;
  padding: 3px 0;
  text-align: center;
  width: 19px;

  // Inverted pellet
  &__inverted {
    background-color: $white;
    color: rgb(var(--primary-color));

    // Inverted hover when is clickable
    &.wz-pellet__click:hover {
      background-color: rgb(var(--primary-color));
      color: $white;
    }
  }

  // Clickable pellet
  &__click {
    cursor: pointer;

    &:hover {
      background-color: $white;
      color: rgb(var(--primary-color));
    }
  }

  // Insert the content in the conten
  &:before {
    content: attr(content);
  }
}

// STYLES FOR COPY TO CLIPBOARD LINK
.wz-copytoclipboard-link {
	cursor: pointer;
	font-size: $mediumFontSize;
	display: flex;
	align-items: center;

	.icon-container {
		@include border-radius(100%);
		@include transition-element(all $transitionBorder ease);
		padding: 4px 6px;

		i {
			margin: 0;
		}
	}

	&:hover .icon-container {
		@include background-opacity(var(--primary-color), 0.13);
		color: rgb(var(--primary-color))
	}
}
.wz-copytoclipboard-link-only-icon {
	font-size: $mediumFontSize;
	display: flex;
	align-items: center;

	.icon-container {
		@include border-radius(100%);
		@include transition-element(all $transitionBorder ease);
		padding: 4px 6px;
		cursor: pointer;

		i {
			margin: 0;
		}
		&:hover {
			@include background-opacity(var(--primary-color), 0.13);
			color: rgb(var(--primary-color))
		}
	}
}

// STYLES FOR LINK WITH ACTIONS COMPONENT
.wz-link-with-actions {
	width: 100%;
	margin: auto;
	display: flex;
	font-size: $normalFontSize;
	align-items: center;

	.wz-tooltip {
		max-width: fit-content;
		margin: 0;

		.wz-tooltip__top {
			left: 0%;
			transform: none;
			max-width: unset;
		}

		.wz-tooltip__children {
			width: 100%;
			max-width: fit-content;
		}
	}

	.wz-link {
		display: flex;
		white-space: nowrap;
		overflow: hidden;
		align-items: center;
		padding: 3px 6px;
		color: $black;

		&:hover {
			@include transition-element(background $transitionBorder ease);
			@include background-opacity(var(--primary-color), 0.1);
			color: rgb(var(--primary-color));
			border-radius: 5px;
		}

		&.wz-link-disabled {
			cursor: not-allowed;
		}

		&.wz-without-external-link {
			cursor: default;
			&:hover {
				background-color: transparent;
				color: #000;
			}
		}
		.text {
			flex: 1;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		.external-icon {
			text-align: center;
			margin-left: 5px;

			> i {
				font-size: 10px;
				margin: 0;
				flex: 0 0 auto;
			}
		}
	}

	// IN THIS CASE, DOWNSIZE FONT OF THE COPY TO CLIPBOARD
	.wz-copytoclipboard-link i {
		font-size: $normalFontSize;
	}

	.wz-link-with-actions__edit-icon.wz-font {
		font-size: 0.95em;
	}
}

// STYLES FOR SLIDER COMPONENT
.wz-slider {
	.rc-slider-mark-text:not(.rc-slider-mark-text-active) {
		color: $darkGrey;
	}

	.rc-slider-mark-text.rc-slider-mark-text-active {
		color: rgb(var(--primary-color));
		font-weight: bold;
	}

	.rc-slider-rail, .rc-slider-dot {
		background-color: $darkerLightGrey;
	}

	.rc-slider-dot {
		height: 12px;
		width: 12px;
		bottom: -4px;
		margin-left: -6px;

		&:not(.rc-slider-dot-active) {
			border: solid 2px $darkerLightGrey;
		}
	}

	.rc-slider-handle {
		width: 17px;
		height: 17px;
		margin-top: -7px;
	}

	.rc-slider-handle, .rc-slider-handle:hover, .rc-slider-handle:focus, .rc-slider-dot.rc-slider-dot-active {
		border: solid 1px rgb(var(--primary-color));
	}

	.rc-slider-track, .rc-slider-dot-active, .rc-slider-handle, .rc-slider-handle:active, .rc-slider-handle:hover {
		background-color: rgb(var(--primary-color));
	}

	.rc-slider-handle:active, .rc-slider-handle:hover {
		box-shadow: 0px 0px 5px rgb(var(--primary-color));
		border: solid 1px rgb(var(--primary-color));
	}

	.rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
		box-shadow: 0px 0px 0px 3px rgb(var(--primary-color));
		border: solid 1px rgb(var(--primary-color));
	}
}

.wz-select-button-tooltip {
    margin: 0;
}

.wz-select-button-action-dropdown__container {
    display: inline-block;
}

.wz-select-button-tooltip .wz-select-button-no-action, .wz-select-button-action-dropdown__container {
    margin-bottom: 14px;
}

.wz-select-button-tooltip .wz-select-button-no-action, .wz-select-button-action-dropdown>div {
    font-size: 14px;
}

.wz-select-button-tooltip .wz-select-button-no-action {
    background-color: transparent;
}

.wz-select-button-tooltip .wz-select-button-no-action .wz-framebutton__inactive {
    background-color: transparent;
}
.wz-select-button-tooltip .wz-select-button-no-action .wz-framebutton__label {
    color: #bcbcbc;
}
.wz-select-button-action-dropdown>div {
    margin-right: 35px;
}

.wz-select-button-action-dropdown span.wz-dropdown-arrow:after {
    top: 4px;
}
//* Hierarchy *//
.wz-hierarchy__container {
  padding: 0 12px;

  .wz-block-scroll {
    margin-top: 0;
  }

  .wz-hierarchy__checkbox.wz-ui-input .wz-input-checkbox, .wz-hierarchy__select-all .wz-ui-input .wz-input-checkbox{
    -webkit-transition: none;
    -moz-transition: none;
    -ms-transition: none;
    -o-transition: none;
    transition: none;
  }
}

.wz-hierarchy__search {
  height: 30px;
}

.wz-popin.wz-popin__select[data-popper-placement^='top'] > .wz-commondropdown-list{
  .wz-search-input {
    margin-top: 10px;
  }
}

.wz-hierarchy__select-all {
  margin-bottom: 0;
  margin-right: 10px;
  border-bottom: 1px solid $darkerLightGrey;
}

.wz-hierarchy__list-container {
  margin: 0 10px 15px 0;
}

.wz-hierarchy__item-container {
  padding: 10px 0 0 0;
}

.wz-hierarchy__item {
  margin-left: calc(var(--indent) * var(--width-indent));
  display: flex;
}

.wz-hierarchy__group {
  margin-left: calc(var(--indent) * var(--width-indent));
  display: flex;
  font-weight: 500;
}

.wz-hierarchy__label-container {
  display: flex;
}

i.wz-font.wz-font-margin.wz-hierarchy__chevron {
  margin-right: 10px;
  padding-top: 2px;
}

.wz-hierarchy__checkbox {
  width: auto;
  margin-right: 10px;
}

.wz-hierarchy__text-ellipsis {
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding-right: 5px;
}

.wz-hierarchy__component {
  padding: 0 0 0 10px;
  margin-left: auto;
  font-weight: 500;
}
//* Hierarchy *//

.multiselect-filter-container {
	min-width: 250px;
	width: max-content;
}

.multiselect-filter-select {
	display: inline-block;
	min-width: 150px;
	width: calc(100% - 100px);
	margin-right: 5px;
}



.wz-responsive {

  &-fallback__mobile,
  &__tablet,
  &__desktop,
  &-fallback__mt,
  &-fallback__mtd,
  &__td {
    display: none;
  }

  &__mt,
  &-fallback__tablet,
  &-fallback__desktop,
  &-fallback__td {
    display: block;
  }

  // Tablet screen
  @media screen and (min-width: $widthMobile) {
    &__mobile,
    &-fallback__tablet,
    &-fallback__td {
      display: none;
    }

    &__tablet,
    &-fallback__mobile,
    &__td {
      display: block;
    }
  }

  // Desktop screen
  @media screen and (min-width: $widthDesktop) {
    &__tablet,
    &-fallback__desktop,
    &__mt {
      display: none;
    }

    &__desktop,
    &-fallback__tablet,
    &__desktop,
    &-fallback__mtd,
    &-fallback__mt {
      display: block;
    }
  }
}


.wz-nacre-content {
	background-color: $backgroundColor !important;
	font-family: Graphik;
	line-height: normal;

	* {
		font-family: Graphik;
	}

	h1, h2, h3, h4, h5 {
		font-family: Graphik;
		font-weight: 500;
	}
}

.wz-leftmenu .ui.vertical.wz-menu .item {
	padding: inherit !important;
}

.wz-overflow-x {
	overflow-x: auto;
	@include scrollbar();
}


//Storybook
/* storybook */
.sb-show-main {
  background-color: $backgroundColor !important;
  font-family: Graphik;
  line-height: normal;

  * {
    font-family: Graphik;
  }

  h1, h2, h3, h4, h5 {
    font-family: Graphik;
    font-weight: 500;
  }
}
/* storybook */


/*
    We import fonts here so that after the magic 
    created by all the mkdir and mkfile and mkmagic
    fonts and CSS are located in the same place, while
    allowing Storybook to find them as well
*/
@font-face {
	font-family: "Graphik";
	font-weight: normal;
	src: url("~@weezevent/nacre/lib/assets/fonts/graphikRegular.svg") format("svg"),
		url("~@weezevent/nacre/lib/assets/fonts/graphikRegular.ttf") format("ttf"),
		url("~@weezevent/nacre/lib/assets/fonts/graphikRegular.woff") format("woff"),
		url("~@weezevent/nacre/lib/assets/fonts/graphikRegular.woff2") format("woff2");
}

@font-face {
	font-family: "Graphik";
	font-weight: 500;
	src: url("~@weezevent/nacre/lib/assets/fonts/graphikMedium.svg") format("svg"),
		url("~@weezevent/nacre/lib/assets/fonts/graphikMedium.ttf") format("ttf"),
		url("~@weezevent/nacre/lib/assets/fonts/graphikMedium.woff") format("woff"),
		url("~@weezevent/nacre/lib/assets/fonts/graphikMedium.woff2") format("woff2");
}

@font-face {
	font-family: "Graphik";
	font-weight: bold;
	src: url("~@weezevent/nacre/lib/assets/fonts/graphikSemibold.svg") format("svg"),
		url("~@weezevent/nacre/lib/assets/fonts/graphikSemibold.ttf") format("ttf"),
		url("~@weezevent/nacre/lib/assets/fonts/graphikSemibold.woff") format("woff"),
		url("~@weezevent/nacre/lib/assets/fonts/graphikSemibold.woff2") format("woff2");
}

