.invitations-list > .invitation-container:last-child {
    margin-bottom: 20px;
}

.invitations-list-optional-form {
    margin-right: 20px;
    font-weight: 500;
}

.invitation-container{
    margin: 0 20px 10px 0;
}

.invitation-form-container{
    display: flex;
    justify-content: space-between;
}

.invitation-card{
    margin-bottom: 10px !important;
}

.invitation-input{
    width: 45%;
}

.invitation-input div:global(.ui.disabled.search){
    background-color: rgba(239, 239, 239, 0.5);
    color: rgb(84, 84, 84);
}

.invitation-input:hover {
    cursor: not-allowed;
}
