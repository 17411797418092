@media screen and (min-width: 1261px) {
    .statistics__container :global(.wz-statistics) {
        flex-basis: inherit;
        flex-grow: 1;
    }
}

@media screen and (max-width: 1260px) {
    .statistics__container :global(.wz-statistics) {
        flex-grow: 1;
    }

    .statistics__container .statistics__next-event {
        order: +1;
    }
}

/* moreLabel */
.statistics-more-label-container {
    display: flex;
    align-items: baseline;
}

.statistics-more-label-container:hover {
    cursor: pointer;
    color: rgb(var(--primary-color));
}

.statistics-more-label {
    margin-right: 5px;
}