/* Generic classes */
.bold {
	font-weight: bold;
}

.regular {
	font-weight: normal;
}

.width-x-mini {
	width: 40px;
}

.width-mini {
	width: 80px;
}

.width-small {
	width: 120px;
}

.width-regular {
	width: 160px;
}

.width-large{
	width: 200px;
}

.width-x-large{
	width: 240px;
}

@media screen and (min-width: 1400px) {
	.width-x-large {
		width: 300px;
	}
}
/* Generic classes */

/* banner waiting_guests */
.waiting_guests__container {
	margin-bottom: 15px;
}

.waiting_guests__content {
	display: flex;
	align-items: center;
}

div.loader-message-add {
	margin-right: 15px!important;
	position: relative!important;
	top: -1px!important;
}

.message-add {
	margin-top: 2px;
	display: inline-block;
}
/* banner waiting_guests */

/* list */
.global-pagesize-container {
	display: flex;
	justify-content: space-between;
}

.global-pagesize-container :global(.wz-select-button-action-dropdown__container),
.global-pagesize-container :global(.wz-select-button-no-action) {
	margin-bottom: 0;
}

.list-container :global(.wz-empty-state) {
	padding: 50px 15px;
}

.list-container :global(.wz-select-button-tooltip .wz-select-button-no-action) {
	margin-bottom: 0!important;
}

.list-container .tr-header:global(.wz-tablerow) {
	border-color: #CED0DA;
}

.list__table :global(.wz-tablerow) .list__td-info-text {
	max-width: 100px;
}

.tr-body:global(.wz-tablerow) {
	font-size: 14px;
	border-color: #CED0DA;
}

.tr-body td {
	border-right: none;
	padding: 12px 14px;
}

.tr-body .inline {
	display: inline;
}

.container-icons-action {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-right: 5px;
}

.container-icons-action i:global(.wz-font.wz-font-regular.wz-font-envelope) {
	padding-top: 7px;
}

@media screen and (max-width: 600px) {
	.global-pagesize-container {
		flex-direction: column;
		align-items: center;
	}

	.global-pagesize-container :global(.wz-select-button-action-dropdown__container),
	.global-pagesize-container :global(.wz-select-button-no-action) {
		margin-bottom: 15px;
	}
}

/* seating */
.seating-info {
	display: flex;
	align-items: center;
}
/* seating */
/*list */

.td-scanned :global(.wz-font.wz-font-circular) {
    padding: 0.4em 0;
    width: 15px;
    height: 15px;
    font-size: 0.7em;
	vertical-align: top;
}

.td-scanned i:global(.wz-font.wz-font-circular.wz-font-inverted):hover {
    background-color: #1b1c1d!important;
    cursor: default;
}