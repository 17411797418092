.container {
    background-color: #f5f5f5;
}

.stat-side-bar {
    margin: 0 20px 0 0;
}

.stat-side-bar :global(.wz-overflow-x) {
    overflow: unset;
}

.quotas-available{
    font-weight: bold;
    font-size: 15px;
}

.inventory-row {
    background-color: #f5f5f5!important;
}

.table-stat{
    border-collapse: initial !important;
    max-width: 100%;
}

.row-stat{
    border-right: initial !important;
}

.row-stat-rates div {
    text-align: center;
}

.inventory-name :global(.wz-popin__container) span {
    word-break: normal;
    overflow-wrap: break-word;
    white-space: normal;
}

.clickable-span-show-inventories {
    color:grey;
    font-weight: bold;
    font-size: 13px;
    cursor: pointer;
}
