@keyframes sidebar-slide {
    from {
        transform: translateX(100%);
    }

    to {
        transform: translateX(0%);
    }
}

/* We have to hav another animation because navigator can't re-trigger the same*/
@keyframes sidebar-slide-out {
    from {
        transform: translateX(0%);
    }

    to {
        transform: translateX(100%);
    }
}