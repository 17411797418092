.container {
    background-color: #f5f5f5;
    padding-bottom: 80px;
}

.container .attendee-summary-card {
    background: white;
    text-transform: capitalize;
}

.tooltip-mail-status-container{
    margin-bottom: 10px;
}

.email-status-card{
    box-shadow: 2px 2px 10px lightgrey;
    border-radius: 5px;
}

@media only screen and (max-width:800px) {
    .container {
        width: 100% !important;
    }
}

@media only screen and (max-width:600px) {
    .container {
        padding-bottom: 130px;
    }
}