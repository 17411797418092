.container {
    margin: 0 20px 15px 0;
}

.container .title {
    font-weight: bold;
}

.container .form {
    display: flex;
    flex-direction: column;
}

.container .form .input-field-container {
    display: grid;
    grid-auto-flow: row;
    grid-template-columns: 48% 48%;
    grid-column-gap: 4%;
    flex: 0 0 50%;
}

.container .form .input-mail {
    flex: 0 0 100%;
}

.container .form .buttons-row {
    display: flex;
}

.container .form .buttons-row > button:first-child {
    margin-right: 10px;
}
.attendee-history-container {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
}
.attendee-history {
    display: flex;
}
.attendee-history-last-downloaded {
    display: flex;
    color: #878C9B;
}
.attendee-history-last-downloaded-date {
    margin-left: 5px;
}

@media only screen and (max-width:600px) {
    .container .form .input-field-container {
        display: block;
    }
}

.trash-button{
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}

.footer-button-container{
    z-index: 1;
    width: 100%;
    display: flex;
    flex-grow: 1;
    width: 650px;
    justify-content: left;
    margin-left: -20px;
    position: -webkit-sticky;
    position: fixed;
    bottom: 20px;
    margin-bottom: -20px !important;
    padding: 15px 20px 15px 18px;
    box-shadow: 6px 0 6px 0 rgb(0 0 0 / 20%);
    background-color: white;
}

@media only screen and (max-width:600px) {
    /* Footer button on mobile screen to disable on button and make the others full width */
    .footer-button-container{
        display: block;
        width: 100%;
    }

    .footer-button-group {
        display: flex !important;
        flex-direction: column;
    }

    .footer-button-group :global(.wz-framebutton.wz-button) {
        margin-right: 0!important;
    }
    .add-and_continue-button{
        display: None !important;
    }

    .footer-button-group :global(.wz-button:not(:last-of-type)) {
        margin-bottom: 15px;
    }
}